import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RMUser } from '@roofmath/models/rm-auth.model';
import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMResponsiveService } from '@roofmath/shared/responsive-service';

@Component({
  selector: 'rm-reset-password',
  templateUrl: './rm-reset-password.component.html',
  styleUrls: ['./rm-reset-password.component.scss']
})
export class RMResetPasswordComponent implements OnInit {
  public confirmPassword: string = '';
  public password: string = '';
  public oldPassword: string = '';
  public username: string = '';
  public errorMessage: string;
  
  isMobileDevice:boolean = false;
  resetToken:string;

  constructor(
    private authService:RMAuthenticationService,
    private responsiveService:RMResponsiveService,
    private router: Router) {
      
  }

  ngOnInit() {
    this.username = this.authService.getSignedInUserName();
    this.isMobileDevice = this.responsiveService.isSmallScreen();
    this.responsiveService.getLayoutChangesObserver()
    .subscribe(layoutChangeBreakPoint => {
      if(layoutChangeBreakPoint == Breakpoints.Small || layoutChangeBreakPoint == Breakpoints.XSmall) {
        this.isMobileDevice = true;
      }
      else {
        this.isMobileDevice = false;
      }
    })
  }
  
  resetPassword() {
    this.authService.changePassword(this.resetToken,this.password,this.confirmPassword)
    .then((message) => {
      alert(message);
      this.router.navigate(['/login']);
    })
    .catch(err => {
      this.errorMessage = err;
    })
  }

  onRetriesExceeded() {
    this.errorMessage = "Retries Exceeded. Please try again in 5 Minutes."
  }
}
