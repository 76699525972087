import { Injectable } from '@angular/core';

import { RMAbstractStorageService } from '@roofmath/core/services/storage/rm-abstract-storage.service';

@Injectable({
    providedIn: 'root'
})
export class RMSessionStorageService extends RMAbstractStorageService {
    constructor() {
        super(sessionStorage);
    }
}
