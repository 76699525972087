import { Component, OnInit, OnDestroy } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './session.service';
import { RMResponsiveService } from './shared/responsive-service';

@Component({
  selector: 'rm-root',
  templateUrl: './rm-roofmath.component.html',
  styleUrls: ['./rm-roofmath.component.scss']
})
export class RMRoofMathComponent implements OnInit, OnDestroy {

  isMobileDevice:boolean = false;

  constructor(
    // private translateService: TranslateService,
    private responsiveService:RMResponsiveService,
    private sessionService:SessionService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    // translateService.setDefaultLang('en');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
