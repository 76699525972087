import { RMEdge, RMPlate, RMPlatePoint, RMProduct, RMWorkOrder } from "./workorders/rm-workorder.model";
import * as turf from '@turf/turf';
import { RMUser } from "./rm-auth.model";

export const RM_RESTAPI_HREF = 'restapi';
export const RM_AUTHAPI_HREF = 'auth';
export const RM_REPORTS_HREF = 'reports';

// Linkrel constants - all in one place
export const RM_REL_SELF  = 'self';
export const RM_REL_PREV  = 'prev';
export const RM_REL_NEXT  = 'next';
export const RM_REL_FIRST = 'first';
export const RM_REL_LAST  = 'last';
export const RM_REL_ADD   = 'add';
export const RM_REL_COPY  = 'copy';
export const RM_REL_WORKORDERS = 'workOrders';
export const RM_REL_WORKORDER = 'workOrder';
export const RM_REL_PLATES = 'plates';
export const RM_REL_PLATE = 'plate';
export const RM_REL_POINTS = 'points';
export const RM_REL_POINT = 'point';
export const RM_REL_CORRELATIONS = 'correlations';
export const RM_REL_CORRELATION = 'correlation';
export const RM_REL_POINT_ONE = 'pointOne';
export const RM_REL_POINT_TWO = 'pointTwo';
export const RM_REL_GET_ALL_WORKORDERS = 'getAllWorkOrders';
export const RM_REL_GET_ALL_FLAGGED_WORKORDERS = 'getAllFlaggedWorkOrders';
export const RM_REL_GET_ALL_ERRORED_WORKORDERS = 'getAllErroredWorkOrders';
export const RM_REL_GET_ALL_PRODUCTS = 'getAllProducts';
export const RM_REL_GET_ALL_ADHOC_PRODUCTS = 'getAllAdhocProducts';
export const RM_REL_UPDATE_PRODUCT = 'updateProduct';
export const RM_REL_ADD_DUMP_CONFIGURATION = 'configuration/addDumpConfiguration';
export const RM_REL_GET_ALL_DUMP_CONFIGURATIONS = 'configuration/getAllDumpConfigurations';
export const RM_REL_ADD_SUBSCRIPTION_DURATION_TYPE = 'configuration/addSubscriptionDurationType';
export const RM_REL_GET_ALL_SUBSCRIPTION_DURATION_TYPES = 'configuration/getAllSubscriptionDurationTypes';
export const RM_REL_GET_SUBSCRIPTION_DURATION_TIME_UNITS = 'configuration/getSubscriptionDurationTimeUnits';
export const RM_REL_GET_ENTIRE_WORKORDER = 'getEntireWorkOrder';
export const RM_REL_GET_WORKORDER_METADATA = 'getWorkOrderMetadata';
export const RM_REL_GET_ALL_WORKORDER_METADATA_CSV = 'getWorkOrdersMetadataCSV';
export const RM_REL_GET_ALL_WORKORDERS_CSV = 'getAllWorkOrdersCSV';
export const RM_REL_GET_ALL_WORKORDERS_BY_ROOFER = 'getAllWorkOrdersByRoofer';
export const RM_REL_GET_ALL_WORKORDERS_BY_OPERATOR = 'getAllWorkOrdersByOperator';
export const RM_REL_GET_ALL_WORKORDERS_BY_SALES_USER = 'getAllWorkOrdersBySalesUser';
export const RM_REL_GET_ALL_WORKORDER_FLAG_TYPES = 'getAllWorkorderFlagTypes';
export const RM_REL_CREATE_WORKORDER_FLAG = 'createWorkOrderFlag';
export const RM_REL_GET_WORKORDER_FLAGS = 'getWorkOrderFlags';
export const RM_REL_GET_FLAG_TYPES = 'getAllWorkorderFlagTypes';
export const RM_REL_UPDATE_FLAG_REWORK_COMMENT = 'updateWorkOrderFlagReWorkComment';
export const RM_REL_UPDATE_FLAG_FIX_COMMENT = 'updateWorkOrderFlagFixComment';
export const RM_REL_GET_PERMITTED_ACTIONS_WORKORDER = 'getPermittedActionsForWorkOrder';
export const RM_REL_UPDATE_WORKORDER = 'updateWorkOrder';
export const RM_REL_UPDATE_WORKORDER_ROOFER = 'updateWorkOrderRoofer';
export const RM_REL_UPDATE_WORKORDER_DISCOUNT_PERCENTAGE = 'updateWorkOrderDiscountPercentage';
export const RM_REL_UPDATE_WORKORDER_OPERATOR = 'updateWorkOrderOperator';
export const RM_REL_UPDATE_WORKORDERS_OPERATOR = 'updateWorkOrderOperators';
export const RM_REL_GET_EDGES_METADATA = 'getEdgesMetadata';
export const RM_REL_GET_EDGES_METADATA_SCHEMATIC = 'getEdgesTypeSchematic';
export const RM_REL_GET_ANGLE_METADATA = 'getAngleMetadata';
export const RM_REL_GET_ANGLE_METADATA_SCHEMATIC = 'getAngleSchematic';
export const RM_REL_SAVE_WORKORDER = 'saveWorkOrder';
export const RM_REL_REOPEN_WORKORDER = 'reopenWorkOrder';
export const RM_REL_POST_SAVE_WORKORDER_GROUP = 'workOrderGroup';
export const RM_REL_ADD_PLATE_TO_WORKORDER_GROUP = 'addPlateToWorkOrderGroup';
export const RM_REL_ADD_WORKORDERS_TO_WORKORDER_GROUP = 'workOrderGroup/addWorkOrdersToWorkOrderGroup';
export const RM_REL_GET_WORKORDER_GROUP = 'workOrderGroup';
export const RM_REL_GET_ALL_WORKORDER_GROUP = 'workOrderGroup/getAllWorkOrderGroups';
export const RM_REL_UPDATE_WORKORDER_GROUP = 'workOrderGroup/updateWorkOrderGroup';
export const RM_REL_CREATE_ADHOC_USER_WORKORDER = 'createAdhocUserWorkOrder';
export const RM_REL_ADD_WORKORDER_PURCHASE = 'addNewWorkOrderProductPurchase';
export const RM_REL_GET_WORKORDER_PLATE_IMAGE = 'getWorkOrderPlateImage';
export const RM_REL_GET_WORKORDERGROUP_PLATE_IMAGE = 'getWorkOrderGroupImageUrl';
export const RM_REL_DELETE_WORKORDER_BY_ID = 'deleteWorkOrderById';
export const RM_REL_REJECT_WORKORDER = 'rejectWorkOrder';
export const RM_REL_DELETE_PLATE_BY_ID = 'deletePlateById';
export const RM_REL_DELETE_POINT_BY_ID = 'deletePointById';
export const RM_REL_DELETE_CORRELATION_BY_ID = 'deleteCorrelationById';
export const RM_REL_DELETE_WORKORDER_CORRELATIONS = 'deleteWorkOrderCorrelations';
export const RM_REL_GET_COUNT_BY_WORKORDER_STATUS = 'getCountByWorkOrderStatus';
export const RM_REL_SERVE_3D_MODEL = 'serve3DModel';
export const RM_REL_APPROVE_3D_MODEL = 'approve3DModel';
export const RM_REL_REJECT_3D_MODEL = 'reject3DModel';
export const RM_REL_APPROVE_BOM = 'approveBOM';
export const RM_REL_REJECT_BOM = 'rejectBOM';
export const RM_REL_STREET_VIEW_ADDRESS_API_LINK = 'getStreetViewImage';
export const RM_REL_LABEL_MAP_IMAGE_API_LINK = 'getLabelMapImage';

export const RM_REL_SIGNUP = 'signUp';
export const RM_REL_SIGNIN = 'signIn';
export const RM_REL_SIGNIN_ADHOC_USER = 'signInAdhocUser';
export const RM_REL_SIGNOUT = 'signOut';
export const RM_REL_LINK_ROOFER_TO_SALES = 'linkRooferToSalesUser';
export const RM_REL_CREATE_USER = 'admin/createUser';
export const RM_REL_GENERATE_OTP = 'generateOtp';
export const RM_REL_VALIDATE_OTP = 'validateOtp';
export const RM_REL_RESEND_OTP = 'resendOtp';
export const RM_REL_FORGOT_PASSWORD = 'forgotPassword';
export const RM_REL_CHANGE_PASSWORD = 'changePassword';
export const RM_REL_GET_PERMITTED_ACTIONS = 'getPermittedActions';
export const RM_REL_GET_ALL_USERS = 'getAllUsers';
export const RM_REL_GET_ALL_FILTERED_USERS = 'getAllFilteredUsers';
export const RM_REL_GET_ALL_OPERATORS = 'getAllOperators';
export const RM_REL_GET_USER_DETAILS = 'getUserDetails';
export const RM_REL_GET_ALL_NON_ADMINS = 'getAllNonAdmins';
export const RM_REL_UPDATE_USER = 'updateUser';
export const RM_REL_UPDATE_USER_PASSWORD = 'updateUserPassword';
export const RM_REL_SUBMIT_CONTACT_US = 'submitContactUs';
export const RM_REL_GET_SAMPLE_REPORT_PATH = 'getSampleReportPath';
export const RM_REL_SAVE_USER_SCHEDULE = 'saveUserSchedule';
export const RM_REL_GET_CURRENT_USER_SETTINGS = 'getCurrentUserSettings';
export const RM_REL_GET_USER_SETTINGS = 'getUserSettings';
export const RM_REL_DELETE_USER_SCHEDULE = 'deleteUserSchedule';
export const RM_REL_GET_USER_SCHEDULE = 'getUserSchedule';
export const RM_REL_GET_ALL_OPERATOR_SCHEDULES = 'getAllOperatorSchedules';
export const RM_REL_CHECK_FOR_OVERLAPPING_SCHEDULES = 'checkOverlappingSchedules';
export const RM_REL_UPLOAD_ADDRESS_POOL = 'uploadAddressPool';
export const RM_REL_GET_ADDRESS_POOL_COUNT = 'getAddressPoolCount';
export const RM_REL_POST_VALIDATE_ADDRESS_AMOUNT = 'validateAndCalculateAddressAmount';
export const RM_REL_POST_VALIDATE_ADDRESS_AMOUNT_ADHOC = 'validateAndCalculateAddressAmountAdhoc';
export const RM_REL_GET_ALL_VALID_BOM_ROOF_TYPES = 'getAllValidBOMRooftypes';
export const RM_REL_GET_ALL_VALID_WORKORDER_ROOF_TYPES = 'getAllValidWorkOrderRoofTypes';
export const RM_REL_GET_UNPROCESSED_WORKORDER_GROUP_COUNT = 'getUnprocessedWorkOrderGroupCount';
export const RM_REL_POST_ADD_WORKORDER_ADDITIONAL_INFO = 'addWorkOrderAdditionalInfo';
export const RM_REL_GET_WORKORDER_ADDITIONAL_INFO = 'getWorkOrderAdditionalInfo';
export const RM_REL_POST_ADD_WORKORDER_ANGLE_INFO = 'addWorkOrderAngleInfo';
export const RM_REL_GET_WORKORDER_ANGLE_INFO = 'getWorkOrderAngleInfo';
export const RM_REL_POST_SAVE_WORKORDER_LABEL_INFO = 'saveWorkOrderLabelInfo';
export const RM_REL_GET_WORKORDER_LABEL_INFO = 'getWorkOrderLabelInfo';
export const RM_REL_SUBSCRIPTION_BASE = 'subscription';
export const RM_REL_INVOICE_BASE = 'invoice';

export const GENERATE_DUMP = 'generateDump';
export const GENERATE_DUMP_FILE = 'generateDumpFile';
export const NEW_STASH_NAME = "Layer1";

// Define a union type to constrain possible linkrel names.
// There does not seem to be away to avoid duplication of values.
// This should be kept in sync with the above array.
export type RM_REL =
| 'self'
| 'prev'
| 'next'
| 'first'
| 'last'
| 'add'
| 'copy'
| 'workOrders'
| 'workOrder'
| 'plates'
| 'plate'
| 'points'
| 'point'
| 'correlations'
| 'correlation'
| 'pointOne'
| 'pointTwo'
| 'getEntireWorkOrder'
| 'updateWorkOrder'
| 'saveWorkOrder'
| 'deleteWorkOrderById'
| 'deletePlateById'
| 'deletePointById'
| 'deleteCorrelationById'
| 'deleteWorkOrderCorrelations'
| 'generateDump'
;

export const BING_MAP_KEY = 'AsXqiDMuoOOPyr7FdKKLelwhc52Ln8DzwEkEPZBiZcNutpTq2lUN5Q2KwQsyC2jE';

// Definition on types and interfaces related to Links
export type RMHref = {
    [key in 'href']: string;
};

export type RMDeprecation = {
    [key in 'deprecation']?: string;
};

export type RMTemplated = {
    [key in 'templated']?: boolean;
};

export type RMLink = RMHref & RMDeprecation & RMTemplated;

export type RMLinks = {
    [key in RM_REL | string]?: RMLink;
};

// Definition on types and interfaces related to Pagination
// tslint:disable-next-line:interface-over-type-literal
export type RMPage = {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
};

// Minxin interfaces for different types of resources
// Resource that has links
export interface RMLinked {
    links?: RMLinks;
}

// Resource that has id
export interface RMIDed {
    readonly id?: string;
}

// Resource that has name
export interface RMNamed {
    address: string;
}

// Resource that has verion
export interface RMVersioned {
    readonly version?: string;
}

// Resource that has creation and modification
export interface RMCreatedModified {
    readonly createdBy?: string;
    readonly createdDate?: string;
    readonly lastModifiedBy?: string;
    readonly lastModifiedDate?: string;
}

// Abstract resource
export class RMResource implements RMLinked {
    links?: RMLinks;
    [key: string]: any;
}

export function getLinkRelHref(resource: RMResource, rel: RM_REL): string {
    if (!resource) {
      return null;
    }
    const linkRel = resource.links != undefined ? resource.links[rel] : resource._links[rel];
    if (!linkRel) {
      return null;
    }
    if (linkRel.templated) {
      return linkRel.href.replace(/{.+}$/, '');
    }
    return linkRel.href;
}

export function hasLinkRel(linked: RMLinked, rel: RM_REL): boolean {
    return !!(linked && linked.links[rel]);
}

// Abstract resource collection
export class RMResourceCollection<T extends RMResource> extends RMResource {
    resources: T[];
    page: RMPage;
}

export function syncPayloadWithServerObject(payload: RMResource, serverObject: RMResource) {
    for (const prop in serverObject) {
        if (payload[prop] === undefined) {
            payload[prop] = serverObject[prop];
        }
    }
    return payload;
}

export let IDCounter = 0;
export let prevRandomNumber = 0;

function getNewRandomNumber():number {
    let newRandomNumber = Math.floor(100*Math.random());
    while(newRandomNumber == prevRandomNumber) {
        newRandomNumber = Math.floor(100*Math.random());
    }
    prevRandomNumber = newRandomNumber;
    return newRandomNumber;
}

function getRandomComponent():string {
    if(IDCounter == 9) {
        IDCounter = -1;
    }
    IDCounter++;
    return IDCounter.toString() + getNewRandomNumber().toString()
}

export function getIDComponent() {
 // var re = new RegExp("-", 'g');
 // let uuid = uuidv4();
 // uuid = uuid.replace(re,"");
 // return uuid;
//  if(IDCounter == 999)
//   IDCounter = 99

//  IDCounter++;

//  return Date.now().toString() + IDCounter.toString() + Math.floor(100*Math.random()).toString();
    let id = Date.now().toString() + getRandomComponent();
    return id;
}

export function getIDSeperatorChar() {
    return "_";
}

export function getWorkOrderId() {
    return getIDComponent();
}

export function getPlateId() {
    // return plate.workOrderId + getIDSeperatorChar() + getMapProviderDetails(MAP_PROVIDER.GOOGLE).shortCode + getIDSeperatorChar() + getPlateAngleDetails(plate.plateAngle).shortCode;
    return getIDComponent();
}

export function getPlatePointId() {
    // return getPlateId(point.plate) + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getCorrelationId() {
    // return workOrder.workOrderId + getIDSeperatorChar() + "correlationGroup" + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getEdgeId() {
    // return workOrder.workOrderId + getIDSeperatorChar() + "correlationGroup" + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getBoundaryId() {
    // return workOrder.workOrderId + getIDSeperatorChar() + "correlationGroup" + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getObstructionId() {
    // return workOrder.workOrderId + getIDSeperatorChar() + "correlationGroup" + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getStashId() {
    // return workOrder.workOrderId + getIDSeperatorChar() + "correlationGroup" + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getPurchaseId() {
    // return workOrder.workOrderId + getIDSeperatorChar() + "correlationGroup" + getIDSeperatorChar() + getIDComponent();
    return getIDComponent();
}

export function getProductId() {
    return getIDComponent();
}

export function getDumpConfigurationId() {
    return getIDComponent();
}

export function getSubscriptionDurationTypeId() {
    return getIDComponent();
}


export type RMSubscribeEvents = WORKORDER_EVENTS | PLATE_POINT_EVENTS | PLATE_EVENTS;

export class RMSubscribeEvent {
    constructor(eventName,eventData) {
        this.eventName = eventName;
        this.eventData = eventData;
    }
    eventName:RMSubscribeEvents;
    eventData?:any;
}

export enum WORKORDER_STATUS {
    CREATED = "CREATED",
    EDITING = "EDITING",
    READY = "READY",
    STAGED = "STAGED",
    VERIFIED = "VERIFIED",
    REJECTED = "REJECTED",
    DTM_VERIFIED = "DTM_VERIFIED",
    MODEL_VERIFYING = "MODEL_VERIFYING",
    MODEL_GENERATED = "MODEL_GENERATED",
    MODEL_APPROVED = "MODEL_APPROVED",
    MODEL_REJECTED = "MODEL_REJECTED",
    BOM_APPROVED = "BOM_APPROVED",
    BOM_REJECTED = "BOM_REJECTED",
    AWAITING_PAYMENT = "AWAITING_PAYMENT",
    PAYMENT_PROCESSED = "PAYMENT_PROCESSED",
    AVAILABLE = "AVAILABLE",
    DELIVERED = "DELIVERED",
    READY_TO_VIEW = "READY_TO_VIEW",

    PROCESSING = "PROCESSING",
    UNABLE_TO_PROCESS = "UNABLE_TO_PROCESS",
    FLAGGED = "FLAGGED",
    ERRORED = "ERRORED"
}

export enum POINT_TYPE {
    OPERATOR = "OPERATOR",
    IMPUTED = "IMPUTED",
    IMPUTING = "IMPUTING",
    BOUNDARY = "BOUNDARY",
    OBSTRUCTION = "OBSTRUCTION",
    PARALLEL = "PARALLEL",
    HINTING = "HINTING"
}

export enum CORRELATION_TYPE {
    OPERATOR = "OPERATOR",
    SYSTEM = "SYSTEM"
}

export enum WORKORDER_EVENTS {
    DELETEPOINT = "DELETE_POINT"
}

export enum PLATE_POINT_EVENTS {
    PLATEPOINTHOVER = "platePointHover",
    PLATEPOINTHOVEROUT = "platePointHoverOut",
    PLATEPOINTCLICKED = "platePointClicked",
    PLATEPOINTDECLICKED = "platePointDeclicked",
    PLATEPOINTDISABLE = "platePointDisable",
    PLATEPOINTENABLE = "platePointEnable",
    RESET = "reset"
}

export enum PLATE_EVENTS {
    HIGHLIGHT_PLATE = "highlightPlate",
    DEHIGHLIGHT_PLATE = "deHighlightPlate",
    ADD_MARKER = "addMarker",
    REMOVE_MARKER = "removeMarker",
    HIDE_MARKER = "hideMarker",
    SHOW_MARKER = "showMarker",
    SELECT_MARKER = "selectMarker",
    DESELECT_MARKER = "deselectMarker",
    HIDE_ALL_MARKERS = "hideAllMarkers",
    SHOW_ALL_MARKERS = "showAllMarkers",
    PLOT_ALL_MARKERS = "plotAllMarkers",
    HIDE_OBSTRUCTION_MARKERS = "hideObstructionMarkers",
    PLOT_OBSTRUCTION_MARKERS = "plotObstructionMarkers",
    HIGHLIGHT_OBSTRUCTION = "highlightObstruction",
    DEHIGHLIGHT_OBSTRUCTION = "dehighlightObstruction",
    ADD_EDGE = "addEdge",
    REMOVE_EDGE = "removeEdge",
    REMOVE_ALL_EDGES = "removeAllEdges",
    HIDE_ALL_EDGES = "hideAllEdges",
    SHOW_ALL_EDGES = "showAllEdges",
    PLOT_ALL_EDGES = "plotAllEdges",
    DRAW_BOUNDARY = "drawBoundary",
    REMOVE_BOUNDARY = "removeBoundary",
    RESET = "reset",
    CLOSE_INFO_WINDOW = "closeInfoWindow",
    RESCALE = "rescale",
    DESTROY = "destroy",
    MANAGE_STASHING = "manageStashPoints",
    RESIZE = "resize",
    NORMALIZE = "normalize",

    ADD_WORKORDER_BOUNDARY_POLYGON = "addWorkOrderBoundaryPolygon",
    REMOVE_WORKORDER_BOUNDARY_POLYGON = "removeWorkOrderBoundaryPolygon",
    ADD_LABEL_MARKER = "addLabelMarker"
}

class MapProvider {
    readonly provider:MAP_PROVIDER;
    readonly name:string;
    readonly shortCode:string;
    readonly longCode:string;
    readonly numCode:string;

    constructor(provider, name, shortCode, longCode, numCode) {
        this.provider = provider;
        this.name = name;
        this.shortCode = shortCode;
        this.longCode = longCode;
        this.numCode = numCode;
    }
}

class PlateAngle {
    readonly plateAngle:PLATE_ANGLE;
    readonly name:string;
    readonly shortCode:string;
    readonly longCode:string;
    readonly numCode:number;

    constructor(plateAngle, name, shortCode, longCode, numCode) {
        this.plateAngle = plateAngle;
        this.name = name;
        this.shortCode = shortCode;
        this.longCode = longCode;
        this.numCode = numCode;
    }
}

export enum MAP_PROVIDER {
    GOOGLE = "GOOGLE",
    BING = "BING",
}

export function getMapProviderDetails(provider:string):MapProvider {
    switch (provider) {
        case MAP_PROVIDER.GOOGLE:
            return new MapProvider(MAP_PROVIDER.GOOGLE,MAP_PROVIDER.GOOGLE,"GG",MAP_PROVIDER.GOOGLE,0);

        case MAP_PROVIDER.BING:
            return new MapProvider(MAP_PROVIDER.BING,MAP_PROVIDER.BING,"BG",MAP_PROVIDER.BING,1);

        default:
            return new MapProvider(MAP_PROVIDER.GOOGLE,MAP_PROVIDER.GOOGLE,"GG",MAP_PROVIDER.GOOGLE,0);
    }
}

export enum PLATE_ANGLE {
    ORTHOGONAL = "ORTHOGONAL",
    NORTH = "NORTH",
    EAST = "EAST",
    WEST = "WEST",
    SOUTH = "SOUTH",
    STREET = "STREET"
}

export function getPlateAngleDetails(plateAngle:string):PlateAngle {
    switch (plateAngle) {
        case PLATE_ANGLE.ORTHOGONAL:
            return new PlateAngle(PLATE_ANGLE.ORTHOGONAL,PLATE_ANGLE.ORTHOGONAL,"O",PLATE_ANGLE.ORTHOGONAL,0);

        case PLATE_ANGLE.NORTH:
            return new PlateAngle(PLATE_ANGLE.NORTH,PLATE_ANGLE.NORTH,"N",PLATE_ANGLE.NORTH,1);

        case PLATE_ANGLE.EAST:
            return new PlateAngle(PLATE_ANGLE.EAST,PLATE_ANGLE.EAST,"E",PLATE_ANGLE.EAST,2);

        case PLATE_ANGLE.WEST:
            return new PlateAngle(PLATE_ANGLE.WEST,PLATE_ANGLE.WEST,"W",PLATE_ANGLE.WEST,3);

        case PLATE_ANGLE.SOUTH:
            return new PlateAngle(PLATE_ANGLE.SOUTH,PLATE_ANGLE.SOUTH,"S",PLATE_ANGLE.SOUTH,4);

        case PLATE_ANGLE.STREET:
            return new PlateAngle(PLATE_ANGLE.STREET,PLATE_ANGLE.STREET,"ST",PLATE_ANGLE.STREET,5);
    }
}

export enum EDGE_TYPE {
    OPERATOR = "OPERATOR",
    IMPUTING = "IMPUTING",
    SYSTEM = "SYSTEM",
    BOUNDARY = "BOUNDARY",
    OVERHANG = "OVERHANG",
    PARALLEL = "PARALLEL",
    OBSTRUCTION = "OBSTRUCTION",
    CROSSHAIR = "CROSSHAIR"
}

export function getNegativeInfinity() {
    return -99999;
}

export enum BING_MAP_TYPE {
    BIRDS_EYE = "birdseye",
    AERIAL = "aerial"
}

export class RMLatLngDistance {
    private distance:number;
    private heading:number;

    constructor(distance:number,heading:number) {
        this.distance = distance;
        this.heading = heading;
    }

    getDistance() {
        return this.distance;
    }

    getHeading() {
        return this.heading;
    }
}

export class RMLatLng {
    private latitude:number;
    private longitude:number;

    constructor(latitude:number,longitude:number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    getLatitude() {
        return this.latitude;
    }

    getLongitude() {
        return this.longitude;
    }
}

export class RMLatLngBound {
    private northEast:RMLatLng;
    private southWest:RMLatLng;

    constructor(northEast:RMLatLng,southWest:RMLatLng) {
        this.northEast = northEast;
        this.southWest = southWest;
    }

    getNorthEast() {
        return this.northEast;
    }

    getSouthWest() {
        return this.southWest;
    }

    contains(bound:RMLatLng) {
        let latLngBound = new google.maps.LatLngBounds(new google.maps.LatLng(this.southWest.getLatitude(),this.southWest.getLongitude())
            ,new google.maps.LatLng(this.northEast.getLatitude(),this.northEast.getLongitude()));
        return latLngBound.contains(new google.maps.LatLng(bound.getLatitude(),bound.getLongitude()));
    }
}

export class RMException {
    readonly exceptionCode:string;
    readonly defaultExceptionMessage:string;

    constructor(exceptionCode:string,defaultExceptionMessage:string) {
        this.exceptionCode = exceptionCode;
        this.defaultExceptionMessage = defaultExceptionMessage;
    }

    getExceptionCode() {
        return this.exceptionCode;
    }

    getDefaultExceptionMessage() {
        return this.defaultExceptionMessage;
    }
}

export enum ROOFMATH_EXCEPTION_CODES {
    MAP_CLICK_OUT_OF_BOUNDS = "mapClickOutOfBounds"
}

export function getRoofmathException(exceptionCode:ROOFMATH_EXCEPTION_CODES) {
    switch(exceptionCode) {
        case ROOFMATH_EXCEPTION_CODES.MAP_CLICK_OUT_OF_BOUNDS:
            return new RMException(ROOFMATH_EXCEPTION_CODES.MAP_CLICK_OUT_OF_BOUNDS,"Map click out of bounds");
    }
}

export enum RMUSEROPERATIONS {
    DRAG_POINT
}

export class RMUIOperation {
    userOperation:RMUSEROPERATIONS;
    preData:any;
    postData:any;
}

interface IStack<T> {
    push(item: T): void;
    pop(): T | undefined;
    peek(): T | undefined;
    size(): number;
}

export class RMStack<T> implements IStack<T> {
    private storage: T[] = [];

    constructor(private capacity: number = Infinity) {}

    push(item: T): void {
      if (this.size() === this.capacity) {
        throw Error("Stack has reached max capacity, you cannot add more items");
      }
      if(this.storage == undefined) {
          this.storage = [];
      }
      this.storage.push(item);
    }

    pop(): T | undefined {
        if(this.storage == undefined || this.storage.length == 0) {
            return null;
        }
        return this.storage.pop();
    }

    peek(): T | undefined {
      return this.storage[this.size() - 1];
    }

    size(): number {
      return this.storage.length;
    }
}

export function getLatLngDistance(latLng1:RMLatLng,latLng2:RMLatLng):RMLatLngDistance {
    let latLngDistance:RMLatLngDistance = undefined;
    let googleLatlng1 = new google.maps.LatLng(latLng1.getLatitude(),latLng1.getLongitude());
    let googleLatlng2 = new google.maps.LatLng(latLng2.getLatitude(),latLng2.getLongitude());
    let distance = google.maps.geometry.spherical.computeDistanceBetween(googleLatlng1,googleLatlng2);
    let heading = google.maps.geometry.spherical.computeHeading(googleLatlng1,googleLatlng2);
    latLngDistance = new RMLatLngDistance(distance,heading);
    return latLngDistance;
}

export function getLatLngAtDistanceFromLatLng(from:RMLatLng,distanceObj:RMLatLngDistance):RMLatLng {
    let googleFrom = new google.maps.LatLng(from.getLatitude(),from.getLongitude());
    let googleTo:google.maps.LatLng = google.maps.geometry.spherical.computeOffset(googleFrom,distanceObj.getDistance(),distanceObj.getHeading());
    let to:RMLatLng = new RMLatLng(googleTo.lat(),googleTo.lng());
    return to;
}

// export function getTransformedLatLng(latitude:number,longitude:number,scale:number) {
//     let geoJsonPoint1 = turf.point([longitude, latitude]);
//     let geoJsonPoint2 = turf.point([longitude, latitude]);
//     let line = turf.lineString([geoJsonPoint1.geometry.coordinates, geoJsonPoint2.geometry.coordinates]);
//     let newPoint = turf.transformScale(line,scale);
//     console.log("newPoint::",newPoint);
// }

export function getScaledLatLngLine(pointOne:RMPlatePoint,pointTwo:RMPlatePoint) {
    let scaleValue = 20;
    let geoJsonPoint1 = turf.point([parseFloat(pointOne.longitude.toString()), parseFloat(pointOne.latitude.toString())]);
    let geoJsonPoint2 = turf.point([parseFloat(pointTwo.longitude.toString()), parseFloat(pointTwo.latitude.toString())]);

    let line = turf.lineString([geoJsonPoint1.geometry.coordinates, geoJsonPoint2.geometry.coordinates]);
    let scaledLine = turf.transformScale(line, scaleValue);
    return scaledLine;
}

export function getIntersectionPoint(point1: RMPlatePoint, point2: RMPlatePoint, point3: RMPlatePoint, point4: RMPlatePoint) {
    // let geoJsonPoint1 = turf.point([parseFloat(point1.longitude.toFixed(6)), parseFloat(point1.latitude.toFixed(6))]);
    // let geoJsonPoint2 = turf.point([parseFloat(point2.longitude.toFixed(6)), parseFloat(point2.latitude.toFixed(6))]);
    // let geoJsonPoint3 = turf.point([parseFloat(point3.longitude.toFixed(6)), parseFloat(point3.latitude.toFixed(6))]);
    // let geoJsonPoint4 = turf.point([parseFloat(point4.longitude.toFixed(6)), parseFloat(point4.latitude.toFixed(6))]);

    // let line1 = turf.lineString([geoJsonPoint1.geometry.coordinates, geoJsonPoint2.geometry.coordinates]);
    // let line2 = turf.lineString([geoJsonPoint3.geometry.coordinates, geoJsonPoint4.geometry.coordinates]);
    let imputedLine1 = getScaledLatLngLine(point1,point2);//turf.transformScale(line1, scaleValue);
    let imputedLine2 = getScaledLatLngLine(point3,point4);//turf.transformScale(line2, scaleValue);
    let intersectPointLatLng = turf.lineIntersect(imputedLine1, imputedLine2);

    let slope1 = (point2.y - point1.y)/(point2.x - point1.x);
    let slope2 = (point4.y - point3.y)/(point4.x - point3.x);
    let differential1 = point1.y - slope1 * point1.x;
    let differential2 = point3.y - slope2 * point3.x;

    let xIntersected = getNegativeInfinity();
    xIntersected = (differential1 - differential2) / (slope2 - slope1);
    let yIntersected = getNegativeInfinity()
    yIntersected = slope1 * xIntersected + differential1;

    let intersectObj = undefined;
    if(xIntersected != getNegativeInfinity() && yIntersected != getNegativeInfinity()){
        intersectObj = {
            x:xIntersected,
            y:yIntersected,
            latitude:intersectPointLatLng.features[0].geometry.coordinates[1],
            longitude:intersectPointLatLng.features[0].geometry.coordinates[0],
            imputedLine1:imputedLine1,
            imputedLine2:imputedLine2
        };
    }
    return intersectObj;
}

export function getOverhangEdge(centerPoint:RMPlatePoint,edge:RMEdge,overhangDistance:number) {
    let point1:RMPlatePoint = edge.pointOne;
    let point2:RMPlatePoint = edge.pointTwo;
    let geoJsonCenterPoint = turf.point([centerPoint.longitude, centerPoint.latitude]);
    let geoJsonPoint1 = turf.point([point1.longitude, point1.latitude]);
    let geoJsonPoint2 = turf.point([point2.longitude, point2.latitude]);
    let line = turf.lineString([geoJsonPoint1.geometry.coordinates, geoJsonPoint2.geometry.coordinates]);
    let offsetLine1 = turf.lineOffset(line, overhangDistance, {units: 'feet'});
    let offsetLine2 = turf.lineOffset(line, - overhangDistance, {units: 'feet'});
    let distanceOption1 = turf.pointToLineDistance(geoJsonCenterPoint,offsetLine1, {units: 'feet'});
    let distanceOption2 = turf.pointToLineDistance(geoJsonCenterPoint,offsetLine2, {units: 'feet'});
    let offsetLine = distanceOption1 > distanceOption2 ? offsetLine2 : offsetLine1;
    let offsetLines = [];
    // offsetLines.push(offsetLine1);
    // offsetLines.push(offsetLine2);
    offsetLines.push(offsetLine);
    return offsetLines;
}

export function getParallelEdge(plate:RMPlate,centerPoint:RMPlatePoint,edge:RMEdge):Promise<any> {
    return new Promise<any>((resolve,reject) => {
        let point1:RMPlatePoint = edge.pointOne;
        let point2:RMPlatePoint = edge.pointTwo;

        let geoJsonCenterPoint = turf.point([centerPoint.longitude, centerPoint.latitude]);
        let geoJsonPoint1 = turf.point([point1.longitude, point1.latitude]);
        let geoJsonPoint2 = turf.point([point2.longitude, point2.latitude]);
        let distancePoint1 = turf.distance(geoJsonCenterPoint,geoJsonPoint1,{units:'feet'});
        let distancePoint2 = turf.distance(geoJsonCenterPoint,geoJsonPoint2,{units:'feet'});
        let distance = undefined;
        let heading = undefined;
        let offset = undefined;
        let latitude = undefined;
        let longitude = undefined;
        let offsetLines = [];
        let geoJsonPointOffset = undefined;

        if(distancePoint1 < distancePoint2) {
            if(plate.provider == MAP_PROVIDER.GOOGLE || plate.provider == MAP_PROVIDER.BING) {
                distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(point1.latitude,point1.longitude), new google.maps.LatLng(centerPoint.latitude,centerPoint.longitude));
                heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point1.latitude,point1.longitude), new google.maps.LatLng(centerPoint.latitude,centerPoint.longitude));
                offset = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(point2.latitude,point2.longitude), distance, heading);
                latitude = offset.lat();
                longitude = offset.lng();
                geoJsonPointOffset = turf.point([longitude, latitude]);
                let scaledLine = turf.transformScale(turf.lineString([geoJsonCenterPoint.geometry.coordinates, geoJsonPointOffset.geometry.coordinates]), 20);
                offsetLines.push(scaledLine);
                resolve({"offsetLines":offsetLines,"edgeHintOffsetPoint":geoJsonPointOffset});
            }
            // else if(plate.provider == MAP_PROVIDER.BING){
            //     Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', function () {
            //         distance = Microsoft.Maps.SpatialMath.getDistanceTo(new Microsoft.Maps.Location(point1.latitude,point1.longitude), new Microsoft.Maps.Location(centerPoint.latitude,centerPoint.longitude));
            //         heading = Microsoft.Maps.SpatialMath.getHeading(new Microsoft.Maps.Location(point1.latitude,point1.longitude), new Microsoft.Maps.Location(centerPoint.latitude,centerPoint.longitude));
            //         offset = Microsoft.Maps.SpatialMath.getDestination(new Microsoft.Maps.Location(point2.latitude,point2.longitude), distance, heading);
            //         latitude = offset.latitude;
            //         longitude = offset.longitude;
            //         geoJsonPointOffset = turf.point([longitude, latitude]);
            //         offsetLines.push(turf.lineString([geoJsonCenterPoint.geometry.coordinates, geoJsonPointOffset.geometry.coordinates]));
            //         resolve(offsetLines);
            //     });
            // }
        }
        else {
            if(plate.provider == MAP_PROVIDER.GOOGLE || plate.provider == MAP_PROVIDER.BING) {
                distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(point2.latitude,point2.longitude), new google.maps.LatLng(centerPoint.latitude,centerPoint.longitude));
                heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point2.latitude,point2.longitude), new google.maps.LatLng(centerPoint.latitude,centerPoint.longitude));
                offset = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(point1.latitude,point1.longitude), distance, heading);
                latitude = offset.lat();
                longitude = offset.lng();
                geoJsonPointOffset = turf.point([longitude, latitude]);
                let scaledLine = turf.transformScale(turf.lineString([geoJsonCenterPoint.geometry.coordinates, geoJsonPointOffset.geometry.coordinates]), 20);
                offsetLines.push(scaledLine);
                resolve({"offsetLines":offsetLines,"edgeHintOffsetPoint":geoJsonPointOffset});
            }
            // else if(plate.provider == MAP_PROVIDER.BING){
            //     Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath', function () {
            //         distance = Microsoft.Maps.SpatialMath.getDistanceTo(new Microsoft.Maps.Location(point2.latitude,point2.longitude), new Microsoft.Maps.Location(centerPoint.latitude,centerPoint.longitude));
            //         heading = Microsoft.Maps.SpatialMath.getHeading(new Microsoft.Maps.Location(point2.latitude,point2.longitude), new Microsoft.Maps.Location(centerPoint.latitude,centerPoint.longitude));
            //         offset = Microsoft.Maps.SpatialMath.getDestination(new Microsoft.Maps.Location(point1.latitude,point1.longitude), distance, heading);
            //         latitude = offset.latitude;
            //         longitude = offset.longitude;
            //         geoJsonPointOffset = turf.point([longitude, latitude]);
            //         offsetLines.push(turf.lineString([geoJsonCenterPoint.geometry.coordinates, geoJsonPointOffset.geometry.coordinates]));
            //         resolve(offsetLines);
            //     });
            // }
        }
    })
}

export function findAngle(p0,p1,c) {
    var p0c = Math.sqrt(Math.pow(c.x-p0.x,2)+
    Math.pow(c.y-p0.y,2));
    var p1c = Math.sqrt(Math.pow(c.x-p1.x,2)+
        Math.pow(c.y-p1.y,2));
    var p0p1 = Math.sqrt(Math.pow(p1.x-p0.x,2)+
        Math.pow(p1.y-p0.y,2));
    var angle = Math.acos((p1c*p1c+p0c*p0c-p0p1*p0p1)/(2*p1c*p0c));
    let tempAngle = angle * (180 / Math.PI);
    return Number.parseFloat(tempAngle.toFixed(4));
  }

export function getPointsDistance(point1:RMPlatePoint, point2:RMPlatePoint) {
    let geoJsonPoint1 = turf.point([point1.longitude, point1.latitude]);
    let geoJsonPoint2 = turf.point([point2.longitude, point2.latitude]);
    let distance = turf.distance(geoJsonPoint1,geoJsonPoint2,{units:'feet'});
    return distance;
}

export function isPointOnEdge(point:RMPlatePoint, edge:RMEdge) {
    let geoJsonPoint = turf.point([point.longitude, point.latitude]);
    let point1:RMPlatePoint = edge.pointOne;
    let point2:RMPlatePoint = edge.pointTwo;
    let geoJsonPoint1 = turf.point([point1.longitude, point1.latitude]);
    let geoJsonPoint2 = turf.point([point2.longitude, point2.latitude]);
    let line = turf.lineString([geoJsonPoint1.geometry.coordinates, geoJsonPoint2.geometry.coordinates]);

    let slope = (point2.y - point1.y)/(point2.x - point1.x);
    let differential = point1.y - slope * point1.x;

    if(point.y == (slope * point1.x + differential)) {
        console.log("Point lies on edge");
        return true;
    }
    else {
        console.log("Point does not lie on edge");
        return false;
    }

    // return turf.booleanPointOnLine(geoJsonPoint, line);
}

export function getBingMapStaticUrl(plate,width,height) {
    let mapType = BING_MAP_TYPE.BIRDS_EYE;
    let baseUrl = 'https://dev.virtualearth.net/REST/v1/Imagery/Map/';
    let bingMapKey = BING_MAP_KEY;
    let url = baseUrl + mapType + '/' + plate.latitude + ',' + plate.longitude + '/' + 22 + '?dir=' + plate.heading + '&key=' + bingMapKey + '&mapSize=' + width + ',' + height;
    if(plate.plateAngle == PLATE_ANGLE.ORTHOGONAL) {
        mapType = BING_MAP_TYPE.AERIAL;
        url =  baseUrl + mapType + '/' + plate.latitude + ',' + plate.longitude + '/' + 20 + '?key=' + bingMapKey + '&mapSize=' + width + ',' + height;
    }
    console.log("getBingMapStaticUrl url::",url);
    return url;
}

export class ObstructionTypeObject {
    readonly code:OBSTRUCTION_TYPE;
    readonly label:string;
    readonly value:OBSTRUCTION_TYPE;
    readonly numPoints:string;

    constructor(code,label, value, numPoints) {
        this.code = code;
        this.label = label;
        this.value = value;
        this.numPoints = numPoints;
    }
}

export function getObstructionTypeObjectFromCode(obstructionCode:string) {
    if(obstructionCode == "") {
        return new ObstructionTypeObject(OBSTRUCTION_TYPE.SOLAR_PANEL,"Solar Panel",OBSTRUCTION_TYPE.SOLAR_PANEL,"infinite");
    }
    switch (obstructionCode) {
        case OBSTRUCTION_TYPE.SOLAR_PANEL:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.SOLAR_PANEL,"Solar Panel",OBSTRUCTION_TYPE.SOLAR_PANEL,"infinite");

        case OBSTRUCTION_TYPE.CHIMNEY:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.CHIMNEY,"Chimney",OBSTRUCTION_TYPE.CHIMNEY,"infinite");

        case OBSTRUCTION_TYPE.VENTS:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.VENTS,"Vents",OBSTRUCTION_TYPE.VENTS,"infinite");

        case OBSTRUCTION_TYPE.SKYLIGHT:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.SKYLIGHT,"Skylight",OBSTRUCTION_TYPE.SKYLIGHT,"infinite");

        case OBSTRUCTION_TYPE.PLUG:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.PLUG,"Plug",OBSTRUCTION_TYPE.PLUG,"1");

        case OBSTRUCTION_TYPE.OTHER:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.OTHER,"Other",OBSTRUCTION_TYPE.OTHER,"infinite");

        default:
            return new ObstructionTypeObject(OBSTRUCTION_TYPE.SOLAR_PANEL,"Solar Panel",OBSTRUCTION_TYPE.SOLAR_PANEL,"infinite");
    }
}

export enum OBSTRUCTION_TYPE {
    SOLAR_PANEL = "SOLAR_PANEL",
    CHIMNEY = "CHIMNEY",
    VENTS = "VENTS",
    SKYLIGHT = "SKYLIGHT",
    PLUG = "PLUG",
    OTHER = "OTHER"
}


export class Overhang {
    readonly code:OVERHANG_DISTANCE;
    readonly label:string;
    readonly value:number;
    readonly unit:string;

    constructor(code,label, value, unit) {
        this.code = code;
        this.label = label;
        this.value = value;
        this.unit = unit;
    }
}

export enum OVERHANG_DISTANCE {
    ONE_FT = "ONE_FT",
    ONE_POINT_FIVE_FT = "ONE_POINT_FIVE_FT",
    TWO_FT = "TWO_FT"
}

export function getOverhangObjectFromCode(overhangDistance:string) {
    let unit:string = "ft";
    if(overhangDistance == "") {
        return new Overhang(OVERHANG_DISTANCE.ONE_POINT_FIVE_FT,"1.5 " + unit,1.5,unit);
    }
    switch (overhangDistance) {
        case OVERHANG_DISTANCE.ONE_FT:
            return new Overhang(OVERHANG_DISTANCE.ONE_FT,"1 " + unit,1,unit);

        case OVERHANG_DISTANCE.ONE_POINT_FIVE_FT:
            return new Overhang(OVERHANG_DISTANCE.ONE_POINT_FIVE_FT,"1.5 " + unit,1.5,unit);

        case OVERHANG_DISTANCE.TWO_FT:
            return new Overhang(OVERHANG_DISTANCE.TWO_FT,"2 " + unit,2,unit);

        default:
            return new Overhang(OVERHANG_DISTANCE.ONE_POINT_FIVE_FT,"1.5 " + unit,1.5,unit);
    }
}

export function calculateWorkOrderAmount(workOrder:RMWorkOrder) {
    let amount = 0;
    if(workOrder.address != undefined && workOrder.address != '' && workOrder.isValidAddress) {
      workOrder.selectedProducts.forEach(product => {
        amount = amount + product.cost;
      })
      if(amount < 0) {
        amount = 0;
      }
    }
    workOrder.amount = amount;
    return amount;
}

export function getAmountAfterDiscountWorkOrder(workOrder:RMWorkOrder,discountPercentage) {
    let amountAfterDiscount = 0;
    this.calculateWorkOrderAmount(workOrder);
    amountAfterDiscount = workOrder.amount - (discountPercentage * workOrder.amount)/100;
    return amountAfterDiscount;
}
