import { Component, OnInit, Output } from '@angular/core';
import { RMBOMRoofType } from '@roofmath/models/workorders/rm-workorder.model';
import { RMWorkOrdersService } from '@roofmath/restapi/rm-workorders.service';
import { EventEmitter } from '@angular/core';
import { RMDataService } from '@roofmath/restapi/rm-data.service';

@Component({
  selector: 'rm-roof-type',
  templateUrl: './rm-roof-type.component.html',
  styleUrls: ['./rm-roof-type.component.scss']
})
export class RMRoofTypeComponent implements OnInit {

  supportedRoofTypes:RMBOMRoofType[] = [];
  roofType:RMBOMRoofType;

  @Output('roofTypeSelected') roofTypeSelected:EventEmitter<any> = new EventEmitter<any>();

  constructor(private workorderService:RMWorkOrdersService, private dataService:RMDataService) { }

  ngOnInit(): void {
    // this.workorderService.getAllValidBOMRooftypes()
    // .then(supportedRoofTypes => {
    //   this.supportedRoofTypes = supportedRoofTypes;
    // })
    this.supportedRoofTypes = this.dataService.getAllValidBOMRoofTypes();
  }

  onRoofTypeChanged() {
    this.roofTypeSelected.emit(this.roofType);
  }

}
