export const RM_DEFAULT_STORAGE_PREFIX = 'roofmath';

export class RMStorageProxy {
    private storage: Storage;
    private prefix: string;

  private static getSettable(value: any): string {
    return typeof value === 'string' ? '\'' + value + '\'' : JSON.stringify(value);
  }

  private static getGettable(value: string): any {
      try {
          return JSON.parse(value);
      } catch (e) {
          if (value && value.length > 2 && value.startsWith('\'') && value.endsWith('\'')) {
              return value.substring(1, value.length - 1);
          }
          return value;
      }
  }

  constructor(storage: Storage, prefix?: string) {
      this.storage = storage;
      this.prefix = prefix || RM_DEFAULT_STORAGE_PREFIX;
  }

  generateStorageKey(key: string): string {
      return `${this.prefix}.${key}`;
  }

  get(key: string): any {
      const storageKey = this.generateStorageKey(key);
      const value = this.storage.getItem(storageKey);
      return RMStorageProxy.getGettable(value);
  }

  set(key: string, value: any): void {
      const storageKey = this.generateStorageKey(key);
      this.storage.setItem(storageKey, RMStorageProxy.getSettable(value));
  }

  remove(key: string): void {
      const storageKey = this.generateStorageKey(key);
      this.storage.removeItem(storageKey);
  }

  clear(): void {
      this.storage.clear();
  }
}
