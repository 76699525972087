import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RMCONSTANTS {
    // Please maintain these in sorted order
    public readonly ABOUT_COLON_BANK = 'about:blank';

    public readonly MAP_GOOGLE_ANGLE_EAST     = 2;
    public readonly MAP_GOOGLE_ANGLE_ORTHRO   = 0;
    public readonly MAP_GOOGLE_ANGLE_NORTH    = 1;
    public readonly MAP_GOOGLE_ANGLE_WEST    = 3;
    public readonly MAP_GOOGLE_ANGLE_SOUTH     = 4;

    public readonly MAP_BING_ANGLE_EAST     = 12;
    public readonly MAP_BING_ANGLE_ORTHRO   = 10;
    public readonly MAP_BING_ANGLE_NORTH    = 11;
    public readonly MAP_BING_ANGLE_WEST    = 13;
    public readonly MAP_BING_ANGLE_SOUTH     = 14;
}
