import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { RMRestapi } from '@roofmath/models/restapi/rm-restapi.model';

@Injectable({
  providedIn: 'root'
})
export class RMCacheService {
  private restapi: Subject<RMRestapi> = new BehaviorSubject<RMRestapi>(null);

  get restapi$(): Observable<RMRestapi> {
    return this.restapi.asObservable();
  }

  setRestapi(restapi: RMRestapi) {
    this.restapi.next(restapi);
  }

  clearRestapi() {
    this.restapi.next();
  }
}
