import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { RMResource, RMResourceCollection } from '@roofmath/models/rm-resources.model';

@Injectable()
export class RMHalInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('restapi')) {
            return next.handle(request).pipe(map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body._links) {
                        event = event.clone({ body: this.modifyBody(event.body) });
                    }
                }
                return event;
            }));
        }

        return next.handle(request);
    }

    private modifyBody(body: any): any {
        if (body._links) {
            let processedBody: RMResource | RMResourceCollection<RMResource>;
            if (body.page) {
                processedBody = new RMResourceCollection<RMResource>();
                processedBody.links = body._links;
                delete body._links;
                processedBody.page = body.page;
                delete body.page;

                const resources: RMResource[] = [];
                if (body._embedded) {
                    for (const r in body._embedded) {
                        if (body._embedded.hasOwnProperty(r)) {
                            if (Array.isArray(body._embedded[r])) {
                                body._embedded[r].forEach((e) => {
                                    let resource = new RMResource();
                                    resource.links = e._links;
                                    delete e._links;
                                    resource = { ...resource, ...e};
                                    resources.push(resource);
                                });
                            }

                        }
                    }
                }
                processedBody.resources = resources;
            } else {
                // single resource
                processedBody = new RMResource();
                processedBody.links = body._links;
                delete body._links;

                processedBody = { ...processedBody, ...body };
            }
            return processedBody;
        }
        return body;
    }
}
