import { Component, OnInit } from '@angular/core';
import { RMWorkOrdersService } from '@roofmath/restapi/rm-workorders.service';

@Component({
  selector: 'rm-contact-us',
  templateUrl: './rm-contact-us.component.html',
  styleUrls: ['./rm-contact-us.component.scss']
})
export class RMContactUsComponent implements OnInit {

  contactUsText:string = "";

  constructor(private workOrdersService:RMWorkOrdersService) { }

  ngOnInit(): void {
  }

  submitContactUs() {
    console.log("submitContactUs",this.contactUsText);
    this.workOrdersService.submitContactUs(this.contactUsText)
    .then(() => {
      this.contactUsText = "";
      alert("Submitted");
    })
  }

}
