import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMResponsiveService } from '@roofmath/shared/responsive-service';

@Component({
  selector: 'rm-forgot-password',
  templateUrl: './rm-forgot-password.component.html',
  styleUrls: ['./rm-forgot-password.component.scss']
})
export class RMForgotPasswordComponent implements OnInit {
    public confirmPassword: string = '';
    public password: string = '';
    public username: string = '';
    public errorMessage: string;
    public otpErrorMessage: string;
    
    isMobileDevice:boolean = false;
    showOtpPopup:boolean = false;
    otpToken:string;
  
    isGeneratingOtp = false;
    isValidatingOtp = false;
    isOTPValidated = false;
  
    constructor(
      private authService:RMAuthenticationService,
      private responsiveService:RMResponsiveService,
      private router: Router) {
        
    }
  
    ngOnInit() {
      this.isMobileDevice = this.responsiveService.isSmallScreen();
      this.responsiveService.getLayoutChangesObserver()
      .subscribe(layoutChangeBreakPoint => {
        if(layoutChangeBreakPoint == Breakpoints.Small || layoutChangeBreakPoint == Breakpoints.XSmall) {
          this.isMobileDevice = true;
        }
        else {
          this.isMobileDevice = false;
        }
      })
    }

    generateOTP() {
      this.authService.generateForgotPasswordOTP(this.username)
      .then((token) => {
        this.otpToken = token;
        this.showOtpPopup = true;
      })
    }

    changePassword() {
      this.authService.changePassword(this.otpToken,this.password,this.confirmPassword)
      .then((message) => {
        alert(message);
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.errorMessage = err;
      })
    }
  
    onOtpInputComplete(otpInput) {
      this.isValidatingOtp = true;
      this.authService.validateOtp(otpInput,"FORGOTPASSWORD",this.otpToken)
      .then(isValid => {
        this.isValidatingOtp = false;
        if(isValid) {
          // alert("OTP Successful.. Please sign in again");
          this.showOtpPopup = false;
          // this.router.navigate(['/login']);
          this.isOTPValidated = true;
        }
        else {
          this.otpErrorMessage = "OTP Unsuccessful";
        }
      })
      .catch(err => {
        this.errorMessage = err;
        this.otpErrorMessage = err;
        this.isValidatingOtp = false;
      });
    }
  
    onResendOTP() {
      this.authService.resendOtp("FORGOTPASSWORD",this.otpToken)
      .then(token => {
        this.showOtpPopup = true;
        this.otpToken = token;
        this.isGeneratingOtp = false;
      })
      .catch(err => {
        this.isGeneratingOtp = false;
        this.errorMessage = err.error;
      });
    }
  
    onRetriesExceeded() {
      this.showOtpPopup = false;
      this.errorMessage = "Retries Exceeded. Please try again in 5 Minutes."
    }
}
