import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RMResponsiveService {

  private layoutChangeSubscriber:Observable<any>;
  private layoutChangePublisher:Subject<any> = new Subject();

  constructor(private breakpointObserver: BreakpointObserver) { 
    this.layoutChangeSubscriber = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]);
    this.layoutChangeSubscriber.subscribe((result) => {this.handleLayoutChange(result)});
  }

  private handleLayoutChange(state) {
    console.log("handleLayoutChange::",state);
    let publishedBreakPoint = null;
    if (state.breakpoints[Breakpoints.XSmall]) {
      publishedBreakPoint = Breakpoints.XSmall;
      console.log('Matches XSmall viewport');
    }
    if (state.breakpoints[Breakpoints.Small]) {
      publishedBreakPoint = Breakpoints.Small;
      console.log('Matches Small viewport');
    }
    if (state.breakpoints[Breakpoints.Medium]) {
      publishedBreakPoint = Breakpoints.Medium;
      console.log('Matches Medium  viewport');
    }
    if (state.breakpoints[Breakpoints.Large]) {
      publishedBreakPoint = Breakpoints.Large;
      console.log('Matches Large viewport');
    }
    if (state.breakpoints[Breakpoints.XLarge]) {
      publishedBreakPoint = Breakpoints.XLarge;
      console.log('Matches XLarge viewport');   
    }
    this.layoutChangePublisher.next(publishedBreakPoint);
  }

  public getLayoutChangesObserver() {
    return this.layoutChangePublisher;
  }

  public isSmallScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.XSmall) || this.breakpointObserver.isMatched(Breakpoints.Small);
  }

  public isMediumScreen() {
    return this.breakpointObserver.isMatched(Breakpoints.Medium);
  }
}
