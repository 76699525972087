export const RM_REL_CREATE_PAYMENT_INTENT = 'create-payment-intent';
export const RM_REL_SETUP_CUSTOMER_INTENT = 'setup-customer-intent';
export const RM_REL_GET_WORKORDER_PENDING_AMOUNT = 'getWorkOrderPendingAmount';
export const RM_REL_GET_SUPPORTED_CURRENCIES = 'getSupportedCurrencies';
export const RM_REL_GET_CUSTOMER_PAYMENT_METHODS = 'getCustomerPaymentMethods';
export const RM_REL_UPDATE_DEFAULT_PAYMENT_METHOD = 'updateDefaultPaymentMethod';
export const RM_REL_GET_PAYMENT_INTENT_STATUS = 'getPaymentIntentStatus';
export const RM_REL_DETACH_CUSTOMER_PAYMENT_METHOD = 'detachCustomerPaymentMethod';

export class PaymentInfo {
    amount:number;
	totalAmountBeforeDiscount:number;
    currency:string;
    description:string;
    workOrderId:string;
    workOrderGroupId:string;
	paymentMethodId:string;
	workOrderAddress:string;
	pendingPurchases:[];
}

export enum PaymentMethodType {
    CARD = "CARD"
}

export enum PaymentStatus {
    CREATED = "CREATED",
	SUCCESS = "SUCCESS",
	FAILED = "FAILED",
	REQUIRES_ACTION = "REQUIRES_ACTION",
	CANCELLED = "CANCELLED"
}

export class PaymentMethod {
    paymentMethodId:string;
	paymentMethodType:PaymentMethodType;

    last4:string;
	expMonth:number;
	expYear:number;
	brand:string;

	isDefault:boolean;
}

export class CurrencyResponse {
    private code:string;
	private displayValue:string;
	private lowestDenominationFactor:number;
	private symbol:string;
	private selectable:boolean;
	
	public getCode() {
		return this.code;
	}

    public setCode(code) {
		this.code = code;
	}
	
	public getDisplayValue() {
		return this.displayValue;
	}

	public setDisplayValue(displayValue) {
		this.displayValue = displayValue;
	}
	
	public getLowestDenominationFactor() {
		return this.lowestDenominationFactor;
	}
	
	public setLowestDenominationFactor(lowestDenominationFactor) {
		this.lowestDenominationFactor = lowestDenominationFactor;
	}
	
	public getSymbol() {
		return this.symbol;
	}
	
	public setSymbol(symbol) {
		this.symbol = symbol;
	}
	
	public getSelectable() {
		return this.selectable;
	}
	
	public setSelectable(selectable) {
		this.selectable = selectable;
	}
}