import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';

import { RMLogService } from '@roofmath/core/services/log/rm-log.service';
import { RMCacheService } from '@roofmath/restapi/rm-cache.service';

import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMAuthorizationService } from '@roofmath/restapi/rm-authorization.service';

import { RMDataService } from '@roofmath/restapi/rm-data.service';
import { environment } from '../../environments/environment';
import { AUTH_ROLE, RMACTION, RMUser } from '@roofmath/models/rm-auth.model';
import { RMResponsiveService } from '@roofmath/shared/responsive-service';

@Component({
  selector: 'rm-post-sign-in',
  templateUrl: './rm-post-sign-in.component.html',
  styleUrls: ['./rm-post-sign-in.component.scss']
})
export class RMPostSignInComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('sideMenuCanvas') sideMenuCanvas;
  @ViewChild('sideMenuContainer') sideMenuContainer;
  @ViewChild('userPanel') userPanel;
  @ViewChild('routerChildContainer') routerChildContainer;
  isSideMenuExpanded:boolean = false;
  isApplicationSetup:boolean = this.authenticationService.isApplicationSetup;
  username:string;
  name:string;
  user:RMUser;

  appVersion="0.0.0";

  ACTIONS = RMACTION;
  isShowContactUs:boolean = false;

  isMobileDevice:boolean = false;

  constructor(
    private router: Router,
    private authenticationService: RMAuthenticationService,
    private authorizationService: RMAuthorizationService,
    private responsiveService:RMResponsiveService,
    private cacheService: RMCacheService,
    private dataService: RMDataService,
    private log: RMLogService) { }


    ngAfterViewChecked(): void {
      // console.log("ngAfterViewChecked::",this.sideMenuContainer.nativeElement.offsetTop);
      if(this.sideMenuCanvas != undefined) {
        this.sideMenuCanvas.nativeElement.style.top = this.sideMenuContainer.nativeElement.offsetTop + 2 + "px";
      }
      this.dataService.setPostSignInRouterChildHeight(this.routerChildContainer.nativeElement.offsetHeight);
      this.isApplicationSetup = this.authenticationService.isApplicationSetup;
    }
  
    ngAfterViewInit(): void {
      let self = this;
      this.user = this.authenticationService.getSignedInUser();
      this.name = this.user.firstName + ' ' + this.user.lastName;
      this.username = this.authenticationService.getSignedInUserName();
      if(this.isMobileDevice) {
        this.tabMenuSelected('workordersMenu');
      }
      this.sideMenuCanvas.nativeElement.style.transform = "translateX(0%)";
      this.sideMenuCanvas.nativeElement.style.visibility = "visible";
      // if(this.isMobileDevice) {
      //   this.sideMenuCanvas.nativeElement.style.display = "none";
      // }
      this.sideMenuCanvas.nativeElement.addEventListener('show.bs.offcanvas', () => {
        console.log('this.sideMenuCanvas show.bs.offcanvas');
      });
      this.sideMenuCanvas.nativeElement.addEventListener('shown.bs.offcanvas', () => {
        console.log('this.sideMenuCanvas shown.bs.offcanvas');
        self.sideMenuCanvas.nativeElement.style.transform = "none";
        this.isSideMenuExpanded = true;
      });
      this.sideMenuCanvas.nativeElement.addEventListener('hide.bs.offcanvas', () => {
        console.log('this.sideMenuCanvas hide.bs.offcanvas');
      });
      this.sideMenuCanvas.nativeElement.addEventListener('hidden.bs.offcanvas', () => {
        console.log('this.sideMenuCanvas hidden.bs.offcanvas',self.sideMenuCanvas);
        self.sideMenuCanvas.nativeElement.style.visibility = 'visible';
        self.sideMenuCanvas.nativeElement.style.transform = "translateX(0%)";
        // self.sideMenuCanvas.nativeElement.classList sideMenuCollapsed
        this.isSideMenuExpanded = false;
        // this.afterSideMenuCanvasHidden();
      });
    }

  ngOnInit() {
    this.isMobileDevice = this.responsiveService.isSmallScreen();
    this.appVersion = environment.appVersion;
    if(this.authenticationService.getSignedInUserRole() != AUTH_ROLE.ROOFER) {
      this.dataService.getUnprocessedWorkOrderGroupCount()
      .then(count => {
        if(count > 0) {
          document.getElementById('idWorkOrderGroupTab').classList.add("sideMenuOptionDetailIconNotify");
        }
      })
    }
  }

  forgetSignin() {
    this.authenticationService.forgetSignin();
    this.cacheService.clearRestapi();
  }

  signOutSwitch() {
    this.signOut();
  }

  signOut() {
    this.authenticationService.signOut().then(
      () => {
        this.forgetSignin();
        this.router.navigate(['/login']);
      },
      (error: string) => {
        this.log.error({detail: 'Failed to sign out. ' + error});
      });
  }

  navigateToProfile() {
    this.router.navigate(['/app/userDetails']);
  }

  setupPaymentDetails() {
    this.router.navigate(['/app/userDetails/paymentDetails']);
  }

  setupAvailability() {
    this.router.navigate(['/app/userDetails/availability']);
  }

  setupContactUs() {
    this.isShowContactUs = true;
    // this.router.navigate(['/app/contactUs']);
  }

  canDashboardTab() {
    return this.authorizationService.canDashboardTab();
  }

  canWorkOrdersTab() {
    return this.authorizationService.canWorkOrdersTab();
  }

  canAdminTab() {
    return this.authorizationService.canAdminTab();
  }

  ngOnDestroy(): void {
  }

  userButtonClicked(event) {
    this.userPanel.toggle(event);
  }

  tabMenuSelected(id) {
    let elements = document.getElementsByClassName('tabMenuActive');
    for(let i=0;i< elements.length;i++) {
      let element = elements[i];
      element.classList.remove('tabMenuActive');
    }
    document.getElementById(id).classList.add('tabMenuActive');
  }
}
