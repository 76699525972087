import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule as PrimeCalendarModule} from 'primeng/calendar';

import { RMPanZoomRotateComponent } from './panzoomrotate/rm-panzoomrotate.component';
import { RMHeadingDirective } from './map/rm-heading.directive';
import { RMTiltDirective } from './map/rm-tilt.directive';
import { RMMapHolderComponent } from '@roofmath/components/rm-map-holder/rm-map-holder.component';
import { RMMapInfoWindowComponent } from '@roofmath/map-info-window/rm-map-info-window.component';
import { RMPlateStashComponent } from '@roofmath/plate-stash/rm-plate-stash.component';
import { RMBackButtonDirective } from './rm-back-button.directive';
import { RMKeyboardEventsDirective } from './rm-keyboard-events.directive';
import { ToolbarModule } from 'primeng/toolbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChipModule } from 'primeng/chip';
import { PanelModule} from 'primeng/panel';
import { TabViewModule} from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RMCanActions } from '@roofmath/rm-can-actions-directive.directive';
import { RMCanAction } from '@roofmath/rm-can-action-directive.directive';
import { RMPaymentMethodComponent } from '@roofmath/payment-method/rm-payment-method.component';
import { RMWorkordersTableComponent } from '@roofmath/workorders-table/rm-workorders-table.component';
import { RMWorkorderStatusFilterComponent } from '@roofmath/rm-workorder-status-filter/rm-workorder-status-filter.component';
import { RMLandingComponent } from '@roofmath/landing/rm-landing.component';
import { RMUpdateUserComponent } from '@roofmath/update-user/rm-update-user.component';
import { RMResponsiveService } from './responsive-service';
import { RMPaymentProcessingComponent } from '@roofmath/payment-processing/rm-payment-processing.component';
import {ProgressBarModule} from 'primeng/progressbar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { RMPurchaseDetailComponent } from '@roofmath/purchase-detail/rm-purchase-detail.component';
import { RMCheckoutComponent } from '@roofmath/checkout/checkout.component';
import { RMWorkOrderMetadataComponent } from '@roofmath/work-order-metadata/rm-work-order-metadata.component';
import { RMFlagWorkorderComponent } from '@roofmath/flag-workorder/rm-flag-workorder.component';
import { BadgeModule } from 'primeng/badge';
import {ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { RMProductsListComponent } from '@roofmath/products-list/rm-products-list.component';
import { RMReportsComponent } from '@roofmath/reports/rm-reports.component';
import { RMReportService } from '@roofmath/report.service';
import { RMAvailabilityCalendarComponent } from '@roofmath/availability-calendar/rm-availability-calendar.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {ColorPickerModule} from 'ngx-color-picker';
import { FieldsetModule } from 'primeng/fieldset';
import { RMWorkorderFlagsComponent } from '@roofmath/workorder-flags/rm-workorder-flags.component';
import { RMWorkOrderProductComponent } from '@roofmath/work-order-product/rm-work-order-product.component';
import { RMFooterComponent } from '@roofmath/footer/rm-footer.component';
import { RMPrivacyComponent } from '@roofmath/privacy/rm-privacy.component';
import { RMTermsConditionsComponent } from '@roofmath/terms-conditions/rm-terms-conditions.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { RMSignUpOtpComponent } from '@roofmath/sign-up-otp/rm-sign-up-otp.component';
import { RMContactUsComponent } from '@roofmath/contact-us/rm-contact-us.component';
import { RMForgotPasswordComponent } from '@roofmath/forgot-password/rm-forgot-password.component';
import { SplitterModule } from 'primeng/splitter';
import { ListboxModule } from 'primeng/listbox';
import { TimelineModule } from 'primeng/timeline';
import { RMWorkorderErrorsComponent } from '@roofmath/workorder-errors/rm-workorder-errors.component';
import { RMWorkorderErrorComponent } from '@roofmath/workorder-error/rm-workorder-error.component';
import { RMResetPasswordComponent } from '@roofmath/reset-password/rm-reset-password.component';
import { RMWorkorderStatusLogsComponent } from '@roofmath/workorder-status-logs/rm-workorder-status-logs.component';
import { RMEdgesTypeSchematicComponent } from '@roofmath/edges-type-schematic/rm-edges-type-schematic.component';
import { RMPaymentDetailsComponent } from '@roofmath/rm-payment-details/rm-payment-details.component';
import { RoofmathModalComponent } from '@roofmath/roofmath-modal/rm-roofmath-modal.component';
import { RMQualityButtonWorkOrder } from '@roofmath/rm-quality-button.directive';
import { RMWorkorderListDetailComponent } from '@roofmath/workorder-list-detail/rm-workorder-list-detail.component';
import { PaginatorModule } from 'primeng/paginator';
import { RMWorkOrderRejectedPanelComponent } from '@roofmath/work-order-rejected-panel/rm-work-order-rejected-panel.component';
import { RMUsersTableComponent } from '@roofmath/users-table/rm-users-table.component';
import { RMAdhocUserWorkOrderComponent } from '@roofmath/adhoc-user-work-order/rm-adhoc-user-work-order.component';
import { RMRoofTypeComponent } from '@roofmath/roof-type/rm-roof-type.component';
import { RMWorkorderAdditionalInfoComponent } from '@roofmath/workorder-additional-info/rm-workorder-additional-info.component';
import { RMWorkorderGroupComponent } from '@roofmath/workorder-group/rm-workorder-group.component';
import { RMMapScreenshotComponent } from '@roofmath/map-screenshot/rm-map-screenshot.component';
import { RMWorkordersGroupTableComponent } from '@roofmath/workorders-group-table/rm-workorders-group-table.component';
import { RMWorkorderMessageComponent } from '@roofmath/workorder-message/rm-workorder-message.component';
import { RMRooferManagementComponent } from '@roofmath/roofer-management/rm-roofer-management.component';
import { RMOperatePlateHolderComponent } from '@roofmath/operate-plate-holder/rm-operate-plate-holder.component';
import { RMCaptureMetadataComponent } from '@roofmath/capture-metadata/rm-capture-metadata.component';
import { RMWorkorderGroupListDetailComponent } from '@roofmath/workorder-group-list-detail/rm-workorder-group-list-detail.component';
import { RMAngleSchematicComponent } from '@roofmath/angle-schematic/rm-angle-schematic.component';
import { RMMetadataSchematicComponent } from '@roofmath/metadata-schematic/rm-metadata-schematic.component';
import { RMDumpConfigManagementComponent } from '@roofmath/dump-config-management/rm-dump-config-management.component';
import { RMSubscriptionItemComponent } from '@roofmath/subscription-item/rm-subscription-item.component';
import { RMSubscriptionTableComponent } from '@roofmath/subscription-table/rm-subscription-table.component';
import { RMSubscriptionDurationTypeManagementComponent } from '@roofmath/subscription-duration-type-management/rm-subscription-duration-type-management.component';
import { RMInvoiceComponent } from '@roofmath/invoice/rm-invoice.component';
import { RMInvoiceTableComponent } from '@roofmath/invoice-table/rm-invoice-table.component';

@NgModule({
  declarations: [
    RMPanZoomRotateComponent,
    RMMapHolderComponent,
    RMHeadingDirective,
    RMTiltDirective,
    RMMapInfoWindowComponent,
    RMPlateStashComponent,
    RMBackButtonDirective,
    RMKeyboardEventsDirective,
    RMCanActions,
    RMCanAction,
    RMQualityButtonWorkOrder,
    RMPaymentMethodComponent,
    RMWorkorderStatusFilterComponent,
    RMWorkordersTableComponent,
    RMWorkorderGroupComponent,
    RMWorkordersGroupTableComponent,
    RMWorkorderListDetailComponent,
    RMWorkorderGroupListDetailComponent,
    RMLandingComponent,
    RMUpdateUserComponent,
    RMPaymentProcessingComponent,
    RMPurchaseDetailComponent,
    RMCheckoutComponent,
    RMWorkOrderMetadataComponent,
    RMFlagWorkorderComponent,
    RMWorkorderFlagsComponent,
    RMWorkorderErrorsComponent,
    RMWorkorderErrorComponent,
    RMProductsListComponent,
    RMDumpConfigManagementComponent,
    RMSubscriptionDurationTypeManagementComponent,
    RMInvoiceComponent,
    RMReportsComponent,
    RMRooferManagementComponent,
    RMOperatePlateHolderComponent,
    RMAvailabilityCalendarComponent,
    RMWorkOrderProductComponent,
    RMPrivacyComponent,
    RMFooterComponent,
    RMTermsConditionsComponent,
    RMSignUpOtpComponent,
    RMContactUsComponent,
    RMForgotPasswordComponent,
    RMResetPasswordComponent,
    RMWorkorderStatusLogsComponent,
    RMEdgesTypeSchematicComponent,
    RMAngleSchematicComponent,
    RMMetadataSchematicComponent,
    RMPaymentDetailsComponent,
    RoofmathModalComponent,
    RMWorkOrderRejectedPanelComponent,
    RMUsersTableComponent,
    RMAdhocUserWorkOrderComponent,
    RMRoofTypeComponent,
    RMWorkorderAdditionalInfoComponent,
    RMMapScreenshotComponent,
    RMWorkorderMessageComponent,
    RMCaptureMetadataComponent,
    RMSubscriptionItemComponent,
    RMSubscriptionTableComponent,
    RMInvoiceTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    DropdownModule,
    CardModule,
    CheckboxModule,
    SidebarModule,
    AgmCoreModule,
    GalleriaModule,
    CarouselModule,
    ButtonModule,
    MultiSelectModule,
    ToolbarModule,
    OverlayPanelModule,
    ChipModule,
    PanelModule,
    TabViewModule,
    PrimeCalendarModule,
    TableModule,
    RadioButtonModule,
    TagModule,
    ProgressSpinnerModule,
    DialogModule,
    ProgressBarModule,
    MessageModule,
    MessagesModule,
    BadgeModule,
    ToastModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ColorPickerModule,
    FieldsetModule,
    NgOtpInputModule,
    SplitterModule,
    ListboxModule,
    TimelineModule,
    PaginatorModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    ButtonModule,
    DropdownModule,
    CardModule,
    CheckboxModule,
    SidebarModule,
    DialogModule,
    AgmCoreModule,
    GalleriaModule,
    CarouselModule,
    ToolbarModule,
    ChipModule,
    OverlayPanelModule,
    PanelModule,
    TabViewModule,
    PrimeCalendarModule,
    TableModule,
    RadioButtonModule,
    TagModule,
    ProgressSpinnerModule,
    DialogModule,
    MessageModule,
    MessagesModule,
    ListboxModule,
    TimelineModule,
    PaginatorModule,
    RMPaymentMethodComponent,
    RMPaymentProcessingComponent,
    RMPanZoomRotateComponent,
    RMMapHolderComponent,
    RMWorkorderStatusFilterComponent,
    RMWorkordersTableComponent,
    RMWorkorderGroupComponent,
    RMWorkordersGroupTableComponent,
    RMWorkorderListDetailComponent,
    RMWorkorderGroupListDetailComponent,
    RMHeadingDirective,
    RMTiltDirective,
    NgbDropdownModule,
    RMBackButtonDirective,
    RMKeyboardEventsDirective,
    RMCanActions,
    RMCanAction,
    RMQualityButtonWorkOrder,
    RMLandingComponent,
    RMUpdateUserComponent,
    RMPurchaseDetailComponent,
    RMCheckoutComponent,
    RMWorkOrderMetadataComponent,
    RMFlagWorkorderComponent,
    RMWorkorderFlagsComponent,
    RMWorkorderErrorsComponent,
    RMWorkorderErrorComponent,
    RMProductsListComponent,
    RMDumpConfigManagementComponent,
    RMSubscriptionDurationTypeManagementComponent,
    RMInvoiceComponent,
    RMReportsComponent,
    RMRooferManagementComponent,
    RMOperatePlateHolderComponent,
    RMAvailabilityCalendarComponent,
    RMWorkOrderProductComponent,
    RMPrivacyComponent,
    RMFooterComponent,
    RMTermsConditionsComponent,
    RMSignUpOtpComponent,
    RMContactUsComponent,
    RMForgotPasswordComponent,
    RMResetPasswordComponent,
    RMWorkorderStatusLogsComponent,
    RMEdgesTypeSchematicComponent,
    RMAngleSchematicComponent,
    RMMetadataSchematicComponent,
    RoofmathModalComponent,
    RMWorkOrderRejectedPanelComponent,
    RMUsersTableComponent,
    RMAdhocUserWorkOrderComponent,
    RMRoofTypeComponent,
    RMWorkorderAdditionalInfoComponent,
    RMMapScreenshotComponent,
    RMWorkorderMessageComponent,
    RMCaptureMetadataComponent,
    RMSubscriptionItemComponent,
    RMSubscriptionTableComponent,
    RMInvoiceTableComponent
  ],
  providers: [
    RMResponsiveService,
    MessageService,
    RMReportService,
    MatDatepickerModule
  ]
})
export class RMSharedModule { }
