<nav class="navbar navbar-expand-sm sticky-top">
  <a class="navbar-brand" [routerLink]="['/app']" routerLinkActive="router-link-active" >
      <img src="assets/images/roofmath-logo.png" height="70" alt="Roof Math | Best Roofing Software">
      {{appVersion}}
  </a>
  <p-toolbar class="postSignInToolbar" [ngClass]="{'postSignInToolbarMobile':isMobileDevice}">
    <div class="p-toolbar-group-right">
      <button pButton pRipple type="button" icon="pi pi-user" class="p-button-rounded p-button-text navToolbarButton" (click)="userButtonClicked($event)"></button>
      <p-overlayPanel #userPanel>
        <ng-template pTemplate>
          <div class="row userPanelRow">
            <button pButton pRipple disabled type="button" class="p-button-rounded p-button-text hideButtonIcon userNameButton" [label]="username" icon="pi pi-user"></button>
          </div>
          <div class="row userPanelRow" [rmCanActions]="[ACTIONS.PAGES_PROFILE]">
            <button pButton pRipple type="button" class="p-button-rounded p-button-text" (click)="navigateToProfile()" label="My Profile" icon="pi pi-user"></button>
          </div>
          <div class="row userPanelRow" [rmCanActions]="[ACTIONS.PAGES_AVAILABILITY]">
            <button pButton pRipple type="button" icon="pi pi-calendar" class="p-button-rounded p-button-text" (click)="setupAvailability()" label="Availability"></button>
          </div>
          <div class="row userPanelRow" [rmCanActions]="[ACTIONS.PAGES_CONTACTUS]">
            <button pButton pRipple type="button" icon="pi pi-envelope" class="p-button-rounded p-button-text" (click)="setupContactUs()" label="Contact Support"></button>
          </div>
          <div class="row userPanelRow">
            <button pButton pRipple type="button" icon="pi pi-sign-out" class="p-button-rounded p-button-text" (click)="signOut()" label="Sign Out"></button>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>
  </p-toolbar>
</nav>
<div #sideMenuCanvas class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasStart" aria-labelledby="offcanvasStartLabel" [ngClass]="{'sideMenuCollapsed':!isSideMenuExpanded}" *ngIf="!isMobileDevice">
  <div class="offcanvas-header">
    <div class="">
      <div style="display:block;" class="col-12 sideMenuBand">
        <button pButton pRipple *ngIf="isSideMenuExpanded" type="button" icon="pi pi-angle-double-left" class="p-button-rounded p-button-text text-reset sideMenuOptionButton" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
        <button pButton pRipple *ngIf="!isSideMenuExpanded" type="button" icon="pi pi-angle-double-right" data-bs-toggle="offcanvas" data-bs-target="#offcanvasStart" aria-controls="offcanvasStart" class="p-button-rounded p-button-text sideMenuOptionButton"></button>
      </div>
    </div>
  </div>
  <div class="offcanvas-body">
    <div class="col-12 sideMenuBand" [rmCanActions]="[ACTIONS.SHOW_WORKORDER_LIST_TAB]">
      <a class="nav-link" [routerLink]="['/app/workorders']">
        <i class="pi pi-list sideMenuOptionDetailIcon"></i><span class="sideMenuOptionText" *ngIf="isSideMenuExpanded">{{ 'WORKORDERS' | translate | titlecase }}</span>
      </a>
    </div>
    <div class="col-12 sideMenuBand" [rmCanActions]="[ACTIONS.SHOW_WORKORDER_LIST_TAB]">
      <a class="nav-link" [routerLink]="['/app/workorderGroups/']">
        <i class="pi pi-building sideMenuOptionDetailIcon" id="idWorkOrderGroupTab"></i><span class="sideMenuOptionText" *ngIf="isSideMenuExpanded">{{ 'Commercial' | translate | titlecase }}</span>
      </a>
    </div>
    <div class="col-12 sideMenuBand" [rmCanActions]="[ACTIONS.SHOW_ADMINISTRATOR_TAB]">
      <a class="nav-link" [routerLink]="['/app/admin']" >
        <i class="pi pi-box sideMenuOptionDetailIcon"></i><span class="sideMenuOptionText" *ngIf="isSideMenuExpanded">{{ 'Admin' | translate | titlecase }}</span>
      </a>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="container-fluid">
    <div class="row content pageContainer" #routerChildContainer [ngClass]="{'pageContainerMobile':isMobileDevice}">
      <div class="col-1 sideMenu" #sideMenuContainer *ngIf="!isMobileDevice"></div>
      <div class="col-11 routerOutletContainer" [ngClass]="{'routerOutletContainerMobile':isMobileDevice}">
        <div class="mobileTabMenu" *ngIf="isMobileDevice">
            <button pButton type="button" [rmCanActions]="[ACTIONS.SHOW_WORKORDER_LIST_TAB]" class="p-button-raised" id="workordersMenu">
              <a class="nav-link" [routerLink]="['/app/workorders']" (click)="tabMenuSelected('workordersMenu')">
                <i class="pi pi-list sideMenuOptionDetailIcon"></i><span class="sideMenuOptionText">{{ 'WORKORDERS' | translate | titlecase }}</span>
              </a>
            </button>
            <button pButton type="button" [rmCanActions]="[ACTIONS.SHOW_WORKORDER_LIST_TAB]" class="p-button-raised" id="workorderGroupsMenu">
              <a class="nav-link" [routerLink]="['/app/workorderGroups/']" (click)="tabMenuSelected('workorderGroupsMenu')">
                <i class="pi pi-building sideMenuOptionDetailIcon" id="idWorkOrderGroupTab"></i><span class="sideMenuOptionText">{{ 'Commercial' | translate | titlecase }}</span>
              </a>
            </button>
            <button pButton type="button" [rmCanActions]="[ACTIONS.SHOW_ADMINISTRATOR_TAB]" class="p-button-raised" id="adminMenu">
              <a class="nav-link" [routerLink]="['/app/admin']" (click)="tabMenuSelected('adminMenu')">
                <i class="pi pi-box sideMenuOptionDetailIcon"></i><span class="sideMenuOptionText">{{ 'Admin' | translate | titlecase }}</span>
              </a>
            </button>
        </div>
        <router-outlet *ngIf="isApplicationSetup"></router-outlet>
      </div>
    </div>
    <rm-footer></rm-footer>
  </div>
  <p-dialog position="center" [(visible)]="isShowContactUs" [modal]="true" [closeOnEscape]="true" [closable]="true" [resizable]="false" [draggable]="false" header="Contact Support">
    <rm-contact-us></rm-contact-us>
  </p-dialog>
</div>
