import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RMUser } from '@roofmath/models/rm-auth.model';
import { RMCurrency, RMWorkOrder, RMWorkOrderGroup } from '@roofmath/models/workorders/rm-workorder.model';
import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMAuthorizationService } from '@roofmath/restapi/rm-authorization.service';
import { RMDataService } from '@roofmath/restapi/rm-data.service';
import { RMRestapiService } from '@roofmath/restapi/rm-restapi.service';
import { Observable } from 'rxjs';
import { CurrencyResponse, PaymentInfo, PaymentMethod, PaymentStatus, 
  RM_REL_CREATE_PAYMENT_INTENT, RM_REL_DETACH_CUSTOMER_PAYMENT_METHOD, RM_REL_GET_CUSTOMER_PAYMENT_METHODS, RM_REL_GET_PAYMENT_INTENT_STATUS, 
  RM_REL_GET_SUPPORTED_CURRENCIES, RM_REL_GET_WORKORDER_PENDING_AMOUNT, RM_REL_SETUP_CUSTOMER_INTENT, RM_REL_UPDATE_DEFAULT_PAYMENT_METHOD } from './payments.model';

@Injectable({
  providedIn: 'root'
})
export class RMCheckoutService {

  paymentIntentUrl:string = "/create-payment-intent";
  setupCustomerIntentIntentUrl:string = "/setup-customer-intent";
  // checkoutUrl:string = "/checkout";

  private currentUser:RMUser;
  private userAuthenticationSubscription;

  constructor(private restApiService: RMRestapiService, private dataService:RMDataService, private authorizationService:RMAuthorizationService, private authService:RMAuthenticationService) { 
    this.currentUser = this.authService.getSignedInUser();
    this.userAuthenticationSubscription = this.authService.getUserAuthenticationSubscription();
    this.userAuthenticationSubscription.subscribe(user => {
      this.currentUser = this.authService.getSignedInUser();
    })
  }

  createPaymentIntent(paymentInfo:PaymentInfo):Promise<any> {
    return this.restApiService.makeSecuredPostCall(RM_REL_CREATE_PAYMENT_INTENT,paymentInfo as any);
  }

  setupCustomerIntent():Promise<any> {
    return this.restApiService.makeSecuredPostCall(RM_REL_SETUP_CUSTOMER_INTENT,this.currentUser as any);
  }

  getWorkOrderPendingAmount(workOrder:RMWorkOrder):Promise<PaymentInfo> {
    return new Promise<PaymentInfo>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(RM_REL_GET_WORKORDER_PENDING_AMOUNT + `?workOrderId=${workOrder.workOrderId}`)
      .then((paymentInfo: PaymentInfo) => {
        resolve(paymentInfo);
      })
    })
  }

  getWorkOrderGroupPendingAmount(workOrder:RMWorkOrderGroup):Promise<PaymentInfo> {
    return new Promise<PaymentInfo>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(RM_REL_GET_WORKORDER_PENDING_AMOUNT + `?workOrderGroupId=${workOrder.workOrderGroupId}`)
      .then((paymentInfo: PaymentInfo) => {
        resolve(paymentInfo);
      })
    })
  }

  getCustomerPaymentMethods():Promise<PaymentMethod[]> {
    return new Promise<PaymentMethod[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(RM_REL_GET_CUSTOMER_PAYMENT_METHODS + `?userId=${this.currentUser.userId}`)
      .then((paymentMethods: PaymentMethod[]) => {
        console.log("paymentMethods::",paymentMethods);
        resolve(paymentMethods);
      })
    })
  }

  getSupportedCurrencies():Promise<RMCurrency[]> {
    return new Promise<RMCurrency[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(RM_REL_GET_SUPPORTED_CURRENCIES)
      .then((dataSupportedCurrencies) => {
        // let supportedCurrencies:CurrencyResponse[] = [];
        // dataSupportedCurrencies.forEach(currency => {
        //   let supportedCurrency = new CurrencyResponse();
        //   supportedCurrency.setCode(currency.code);
        //   supportedCurrency.setDisplayValue(currency.displayValue);
        //   supportedCurrency.setLowestDenominationFactor(currency.lowestDenominationFactor);
        //   supportedCurrency.setSymbol(currency.symbol);
        //   supportedCurrency.setSelectable(currency.selectable);
        //   supportedCurrencies.push(supportedCurrency);
        // })
        resolve(dataSupportedCurrencies);
      })
    })
  }
  
  public updateDefaultPaymentMethodCurrentUser(paymentMethodId:String):Promise<any> {
    let userId = this.currentUser.userId;
    return this.updateDefaultPaymentMethod(userId,paymentMethodId);
  }

  public updateDefaultPaymentMethod(userId:String,paymentMethodId:String):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredPutCall(`${RM_REL_UPDATE_DEFAULT_PAYMENT_METHOD}?userId=${userId}&paymentMethodId=${paymentMethodId}`,{} as any)
      .then((message) => {
        resolve(message);
      })
    })
  }

  getPaymentIntentStatus(paymentIntentId:string) {
    return new Promise<PaymentStatus>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REL_GET_PAYMENT_INTENT_STATUS}?paymentIntentId=${paymentIntentId}`)
      .then((paymentStatus) => {
        resolve(paymentStatus);
      });
    });
  }

  detachCustomerPaymentMethod(paymentMethodId:string) {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredDeleteCall(`${RM_REL_DETACH_CUSTOMER_PAYMENT_METHOD}?userId=${this.currentUser.userId}&paymentMethodId=${paymentMethodId}`)
      .then((detatchStatus) => {
        resolve(detatchStatus);
      });
    });
  }
}
