import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RMRestapiService } from '@roofmath/restapi/rm-restapi.service';
import { RMCacheService } from '@roofmath/restapi/rm-cache.service';

@Injectable({
  providedIn: 'root'
})
export class RMRestapiResolverService implements Resolve<any> {
  constructor(private restapiService: RMRestapiService,
    private cacheService: RMCacheService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.restapiService.getRestapi().pipe(
      tap(
        (restapi) => this.cacheService.setRestapi(restapi)
      ),
      map((restapi) => {
        return {
          restapi: restapi
        };
      })
    );
  }
}
