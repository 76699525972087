import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMAuthorizationService } from '@roofmath/restapi/rm-authorization.service';

@Injectable({
  providedIn: 'root'
})
export class RMSignedInGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
    private authenticationService: RMAuthenticationService,
    private authorizationService: RMAuthorizationService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.canNavigate(next,state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canNavigate(childRoute,state);
  }

  canNavigate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorizationService.canNavigate(next,state)
    .then(isNavigate => {
      if(isNavigate) {
        return true;
      }
      else {
        this.router.navigate(['/login']);
        return false;
      }
    })
  }
}
