import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';

// Modules
import { RMSharedModule } from '@roofmath/shared/rm-shared.module';
import { RMCoreModule } from '@roofmath/core/rm-core.module';
import { RMPostSignInModule } from '@roofmath/postauthentication/rm-post-sign-in.module';

import { RMRoofMathRoutingModule } from '@roofmath/rm-roofmath-routing.module';
import { RMRoofMathComponent } from '@roofmath/rm-roofmath.component';

export function useFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'languages/', '.json');
}

@NgModule({
  declarations: [
    RMRoofMathComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1TPbKEOah0UjX5A4Q030-Ohr5vxO9uDw'
    }),
    RMRoofMathRoutingModule,
    RMSharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: useFactory,
          deps: [HttpClient]
      }
    }),
    RMCoreModule,
    RMPostSignInModule
  ],
  providers: [],
  bootstrap: [RMRoofMathComponent]
})
export class RMRoofMathModule { }
