<div class="rm-splash">
    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <div class="p-col p-d-flex p-ai-center p-jc-center rm-registration-center-center" [ngClass]="{'rm-registration-center-center-mobile':isMobileDevice}">
        <p-splitter styleClass="p-mb-5">
          <ng-template pTemplate>
            <div class="p-col p-d-flex p-ai-center p-jc-center">
              <p-card>
                <ng-template pTemplate="body">
                  <div class="rm-registration-logo text-center" [ngClass]="{'rm-registration-logo-mobile':isMobileDevice}">
                    <img src="assets/images/roofmath-logo.png" height="64" alt="Roof Math Logo">
                  </div>
                  <form name="rm-form-registration" class="rm-registration-form" autocomplete="off" [ngClass]="{'rm-registration-form-mobile':isMobileDevice}">
                    <h4 class="formHeader">Sign Up</h4>
                    <div class="row usernameContainer">
                      <div class="col-12">
                        <label for="registration-username">Username</label>
                        <input
                          type="text"
                          id="registration-username"
                          name="registration-username"
                          class="form-control w-100 mt-2"
                          required
                          autofocus
                          [(ngModel)]="username"
                          autocomplete="new-password">
                      </div>
                    </div>
                    <button class="btn btn-primary mt-2 signUpButton" (click)="generateOTP()" [disabled]="username == undefined || username == '' || isOTPValidated">{{ 'Generate OTP' | translate }}</button>
                    <div class="row passwordContainer">
                      <div class="col-6">
                        <label for="registration-password">New Password</label>
                        <input
                          type="password"
                          id="registration-password"
                          name="registration-password"
                          class="form-control w-100 mt-2"
                          required
                          [(ngModel)]="password"
                          autocomplete="new-password">
                      </div>
                      <div class="col-6">
                        <label for="password">Confirm New Password</label>
                        <input
                          type="password"
                          id="confirmpassword"
                          name="confirmpassword"
                          class="form-control w-100 mt-2"
                          required
                          [(ngModel)]="confirmPassword"
                          autocomplete="new-password">
                      </div>
                    </div>
                    <button class="btn btn-primary mt-2 signUpButton" (click)="changePassword()" [disabled]="!isOTPValidated || password == '' || confirmPassword == ''">{{ 'Confirm' | translate }}</button>
                    <div class="form-group mt-2" [ngClass]="{ 'invisible': !errorMessage }">
                      <span class="text-danger"><i class="fa fa-exclamation-triangle"></i><span class="ml-2">{{ errorMessage }}</span></span>
                    </div>
                    <div class="row">
                      <div class="col-12 loginSection">
                        <button pButton type="button" label="Login" class="p-button-text" [routerLink]="['/login']"></button>
                      </div>
                    </div>
                </form>
                </ng-template>
              </p-card>
            </div>
          </ng-template>
          <ng-template pTemplate *ngIf="!isMobileDevice">
            <div class="p-col p-d-flex p-ai-center p-jc-center">
              <img src="../../assets/images/sign_in.png" alt="" class="registrationImage">
            </div>
          </ng-template>
        </p-splitter>
      </div>
    </div>
    <p-dialog [draggable]="false" [modal]="true" [resizable]="false" [closeOnEscape]="false" header="Enter the OTP" [closable]="false" [(visible)]="showOtpPopup">
      <p-progressSpinner *ngIf="isGeneratingOtp || isValidatingOtp"></p-progressSpinner>
      <rm-sign-up-otp (otpInputComplete)="onOtpInputComplete($event)"
        (resendOTPClicked)="onResendOTP()" 
        (retriesExceeded)="onRetriesExceeded()" 
        *ngIf="showOtpPopup"></rm-sign-up-otp>
      <div class="form-group mt-2" [ngClass]="{ 'invisible': !otpErrorMessage }">
        <span class="text-danger"><i class="fa fa-exclamation-triangle"></i><span class="ml-2">{{ otpErrorMessage }}</span></span>
      </div>
    </p-dialog>
  </div>