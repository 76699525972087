// Library
// Modules
import { NgModule } from '@angular/core';

// Modules
import { RMSharedModule } from '@roofmath/shared/rm-shared.module';

// Other
import { RMPostSignInComponent } from '@roofmath/postauthentication/rm-post-sign-in.component';

@NgModule({
    declarations: [
       RMPostSignInComponent
    ],
    imports: [
        RMSharedModule
    ],
    exports: [
        RMPostSignInComponent
    ]
})
export class RMPostSignInModule {
    constructor() {
    }
}
