// import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RMACTION, RMUser, RMUserRequest } from '@roofmath/models/rm-auth.model';
import { AUTH_ROLE } from '@roofmath/models/rm-auth.model';
import { RM_AUTHAPI_HREF, RM_REL_GET_PERMITTED_ACTIONS } from '@roofmath/models/rm-resources.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RMAuthenticationService } from './rm-authentication.service';
import { RMRestapiService } from './rm-restapi.service';

@Injectable({
  providedIn: 'root'
})
export class RMAuthorizationService {
  private dashboardTab = false;
  private workOrdersTab = false;
  private adminTab = false;

  // private permittedActions:RMACTION[];
  userAuthenticationSubscription:Observable<RMUser>;

  constructor(private authService:RMAuthenticationService, private restApiService:RMRestapiService) { 
    // this.userAuthenticationSubscription = this.authService.getUserAuthenticationSubscription();
    // this.userAuthenticationSubscription.subscribe(user => {
    //   if(user != null && user != undefined) {
    //     this.handleUser(user);
    //   }
    //   else {
    //     this.permittedActions = [];
    //   }
    // })
  }

  // populatePermittedActions(userName:string,password:string):Promise<RMACTION[]> {
  //   return new Promise<RMACTION[]>((resolve,reject) => {
  //     let userRequest:RMUserRequest = new RMUserRequest();
  //     userRequest.userName = userName;
  //     userRequest.password = password;
  //     let postRequest = this.restApiService.makeSecuredPostCall(`${RM_AUTHAPI_HREF}/${RM_REL_GET_PERMITTED_ACTIONS}`,{})
  //     // .pipe(
  //     //   catchError((err):any => {
  //     //     console.error("Error::",err);
  //     //     reject('Authentication failed.');
  //     //   })
  //     // );
  //     postRequest.then((dataPermittedActions: any) => {
  //       if(dataPermittedActions == undefined || dataPermittedActions == null) {
  //         this.permittedActions = [];
  //       }
  //       else {
  //         this.permittedActions = dataPermittedActions;
  //       }
  //       resolve(this.permittedActions);
  //     });
  //   });
  // }

  // handleUser(user:RMUser) {
  //   this.resetTabs();

  //   this.populatePermittedActions(user.userName,user.password)
  //   .then(actions => {
  //     this.permittedActions = actions;
  //     console.log("this.permittedActions::",this.permittedActions);
  //   });
  // }

  resetTabs() {
    this.dashboardTab = false;
    this.workOrdersTab = false;
    this.adminTab = false;

    // this.permittedActions = [];
  }

  canDashboardTab() {
    return this.dashboardTab;
  }

  canWorkOrdersTab() {
    return this.workOrdersTab;
  }

  canAdminTab() {
    return this.adminTab;
  }

  canPerformAction(action:RMACTION) {
    // console.log("canPerformAction::",this.permittedActions);
    // this.user = this.authService.getSignedInUser();
    return this.authService.getSignedInUserPermittedActions().includes(action);
  }

  canPerformActions(actions:RMACTION[]) {
    let canAction = false;
    for(let action of actions) {
      canAction = this.canPerformAction(action);
      if(canAction) {
        break;
      }
    }
    return canAction;
  }

  canNavigate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):Promise<boolean> {
    return new Promise((resolve,reject) => {
      let user = this.authService.getSignedInUser();
      if (user == null) {
        resolve(false);
        // this.router.navigate(['/login']);
      } 
      else {
        // this.populatePermittedActions(user.userName,user.password)
        // .then(() => {
        //   let components = state.url.split("/");
        //   for(let component of components) {
        //     // if(this.permittedActions.includes(component))
        //     console.log("component::",component);
        //   }
          resolve(true);
        // })
        // .catch(err => {
        //   reject(err);
        // })
      }
    })
  }
}
