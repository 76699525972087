import {
  RMNamed,
  RMResource,
  RMIDed,
  RMVersioned,
  RMResourceCollection,
  RMLinks,
  RMLatLngBound,
  RMUIOperation,
  RMStack,
  OBSTRUCTION_TYPE,
  WORKORDER_STATUS} from '@roofmath/models/rm-resources.model';
import { createNewPlatePoint } from '@roofmath/restapi/rm-workorders.service';
import { Subject } from 'rxjs';
import { RMACTION, RMUser, RMUserSettings } from '../rm-auth.model';
import { RMLocation } from '../rm-location.model';

export class RMPlatePoint extends RMResource implements RMIDed, RMVersioned {
  x:number;
  y:number;
  latitude:number;
  longitude:number;
  label:String;
  plateId:string;
  pointId:string;
  stashId:string;
  stashIds:Array<string>;
  obstruction:RMObstruction;

  pointType:string;
  plate?:RMPlate;
  stash?:RMStash;
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMCorrelation extends RMResource implements RMIDed, RMVersioned {
  workOrderId:string;
  correlationId:string;
  pointOneId:string;
  pointTwoId:string;
  correlationType:string;
  plate1Id:string;
  plate2Id:string;

  pointOne:any;
  pointTwo:any;
  workOrder?:any;
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMPlate extends RMResource implements RMIDed, RMVersioned {
  imageUrl: string;
  metaUrl: string;
  description: string;
  provider: string;
  heading:number;
  tilt:boolean;
  label:string;
  zoom:number;
  latitude:number;
  longitude:number;
  plateId:string;
  plateAngle:string;
  points:Array<RMPlatePoint>;
  obstructions:Array<RMObstruction>;
  edges:Array<RMEdge>;
  stashes:Array<RMStash>;
  selectedStashIds:Array<string>;
  boundary:RMBoundary;
  workOrderId:string;
  boundsNorthEast:RMPlatePoint;
  boundsSouthWest:RMPlatePoint;
  isDeletable:boolean;
  capturedHeight:number;
  capturedWidth:number;

  workOrder:string;
  imputedPoints:Array<RMPlatePoint>;
  imputedEdges:Array<RMEdge>;
  boundaryPoints:Array<RMPlatePoint>;
  boundaryEdges:Array<RMEdge>;
  plateSubscriber:Subject<any>;
  scaleFactor:number;
  showCenterMarker:boolean;
  loadedBounds:RMLatLngBound;
  uiOperationsStack:RMStack<RMUIOperation>;
  pointIds:[];
  edgeIds:[];
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMWorkOrder extends RMResource implements RMNamed, RMIDed, RMVersioned {
  public address: string;
  public normalizedAddress?: string;
  public status: string;
  public plates?: Array<RMPlate>;
  public correlations?: Array<RMCorrelation>;
  public workOrderId:string;
  public latitude:number;
  public longitude:number;
  public boundNorthEastLatitude:number;
  public boundNorthEastLongitude:number;
  public boundSouthWestLatitude:number;
  public boundSouthWestLongitude:number;
  public x3dLink:string;
  public bomLink:string;
  public amount:number;
  public rooferComments:String;
  public workOrderRoofTypeId:number;

  public creator:RMUser | any;
  public roofer:RMUser | any;
  public operator:RMUser | any;

  public platesCount:number;

  public modelGenerated:boolean;
  public modelApproved:boolean;
  public bomApproved:boolean;
  public paymentProcessed:boolean;
  public permittedActions:RMACTION[];
  public purchases:RMPurchase[];
  public selectedProducts:RMProduct[];
  public workOrderMetadata:RMWorkOrderMetadata;
  public workOrderStatusLogs:RMWorkOrderStatusLog[];
  public flags:RMWorkOrderFlag[];
  public errors:RMWorkOrderError[];
  public discountPercentage:number;
  public isTopPriority:boolean;

  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMWorkOrderGroup extends RMResource {
  public address: string;
  public normalizedAddress?: string;
  public status: string;
  public workOrderGroupId:string;
  public latitude:number;
  public longitude:number;
  public zoom:number;
  public boundsNorthEastLatitude:number;
  public boundsNorthEastLongitude:number;
  public boundsSouthWestLatitude:number;
  public boundsSouthWestLongitude:number;
  public x3dLink:string;
  public bomLink:string;
  public amount:number;
  public plate:RMPlate;

  public creator:RMUser | any;
  public roofer:RMUser | any;

  public paymentProcessed:boolean;
  public permittedActions:RMACTION[];
  public purchases:RMPurchase[];
  public selectedProducts:RMProduct[];
  public flags:RMWorkOrderFlag[];
  public errors:RMWorkOrderError[];
  public discountPercentage:number;
  public isTopPriority:boolean;
}

export class RMWorkOrdersResponseUI {
  public workOrders:string;
  public totalCount:number;
}

export class RMLabelInfo {
  label:String;
  fillColor:String;
  fillOpacity:Number;
  strokeColor:String;
  strokeWidth:Number;
  strokeOpacity:Number;

  latitude:Number;
  longitude:Number;
}

export class RMWorkOrderStatusLog {
  public workOrderId:string;
  public createdDate;
  public status:WORKORDER_STATUS;
  public loggerUser:RMUser;
}

export class RMWorkOrderMetadata {
  public workOrderMetadataId:string;
  public workOrderId:string;
  public platesCount:number;
  public pointsCount:number;
  public componentCount:number;
  public facesCount:number;
  public edgesCount:number;
  public obstructionsCount:number;
  public floorsCount:number;
  public modelFit:number;
  public edgeQuality:number;
  public reconFit:number;
  public createdDate;
}

export class RMEdgesMetadata {
  public edges:any;
  public typeMetadata:any;
}

export class RMCreateWorkOrderResponse {
  public lat:any;
  public lng:any;
  public normalizedAddress:any;
  public discountPercentage:number;
  public userSettings:RMUserSettings;
  public isGroupAddress:boolean;
}

export class RMBOMRoofType {
  public bomRoofTypeId:number;
  public description:string;
  public name:string;
  public createdDate:string;
  public isValid:boolean;
}

export class RMWorkOrderRoofType {
  public workOrderRoofTypeId:number;
  public description:string;
  public name:string;
  public createdDate:string;
  public isValid:boolean;
}

export class RMWorkorderAdditionalInfo {
  public workOrderAdditionalInfoId:number;
  public workOrderId:string;
  public bomRoofTypeId:string;
  public bomTakeDownRoof:boolean;
  public createdDate:string;
  public lastModifiedDate:boolean;
}

export class RMWorkorderAngleInfo {
  public workOrderAngleInfoId:number;
  public workOrderId:string;
  public angle:number;
  public createdDate:string;
  public lastModifiedDate:boolean;
}

export enum WORKORDER_FLAGS_TYPE {
  POOR_IMAGERY = "POOR_IMAGERY",
	POOR_VISIBILITY = "POOR_VISIBILITY",
	HEAVY_TREE_COVERAGE = "HEAVY_TREE_COVERAGE",
	OTHER = "OTHER",
  MODEL_REJECTED = "MODEL_REJECTED",
  REPORT_REJECTED = "REPORT_REJECTED",
  BOM_REJECTED = "BOM_REJECTED",
  WORKORDER_REJECTED = "WORKORDER_REJECTED",
}

export class RMWorkOrderFlagType {
  name:String;
	displayName:String;

  constructor(name,displayName) {
    this.name = name;
    this.displayName = displayName;
  }
}

export function getFlagTypeObjectFromCode(flagTypeCode:String) {
  switch (flagTypeCode) {
      case WORKORDER_FLAGS_TYPE.POOR_IMAGERY:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.POOR_IMAGERY,"Poor Imagery");

      case WORKORDER_FLAGS_TYPE.POOR_VISIBILITY:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.POOR_VISIBILITY,"Poor Visibility/Heavy Shadowing");

      case WORKORDER_FLAGS_TYPE.HEAVY_TREE_COVERAGE:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.HEAVY_TREE_COVERAGE,"Heavy Tree Coverge");

      case WORKORDER_FLAGS_TYPE.OTHER:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.OTHER,"Other");

      case WORKORDER_FLAGS_TYPE.MODEL_REJECTED:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.MODEL_REJECTED,"3D Model Rejected");

      case WORKORDER_FLAGS_TYPE.REPORT_REJECTED:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.REPORT_REJECTED,"Report Rejected");

      case WORKORDER_FLAGS_TYPE.BOM_REJECTED:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.BOM_REJECTED,"BOM Rejected");

      case WORKORDER_FLAGS_TYPE.WORKORDER_REJECTED:
          return new RMWorkOrderFlagType(WORKORDER_FLAGS_TYPE.WORKORDER_REJECTED,"WorkOrder Rejected");
  }
}

export class RMWorkOrderFlag {
  public workOrderFlagId:string;
  public workOrderId:string;
  public createdDate:String;
  public creator:RMUser;
  public flagType:WORKORDER_FLAGS_TYPE;
  public comment:String;
}

export class RMWorkOrderError {
  public workOrderErrorId:string;
  public workOrderId:string;
  public createdDate:String;
  public errorCode:number;
  public errorName:string;
  public errorDisplayName:string;
  public comment:string;
  public isCleared:boolean;
}

export class RMWorkOrdersResponse {
  public workOrders:RMWorkOrder[];
  public totalCount:number;
}

export class RMWorkOrdersRequest {
	userId:string;
	maxRowsPerPage:number;
	currPage:number;
	filterStatus:WORKORDER_STATUS[];
	filterText:String;
  sortCol:String;
  sortDir:String;
  filteredRoofers:[];
}

export class RMEdge extends RMResource implements RMIDed, RMVersioned {
  workOrderId:string;
  plateId:string;
  edgeId:string;
  pointOne:any;
  pointTwo:any;
  pointOneId:any;
  pointTwoId:any;
  edgeType:string;
  workOrder?:any;
  stashId:string;
  stashIds:Array<string>;

  plate?:RMPlate;
  stash?:RMStash;
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMBoundary extends RMResource implements RMIDed, RMVersioned {
  plateId:string;
  boundaryId:string;
  pointOne:any;
  pointTwo:any;

  plate?:RMPlate;
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMObstruction extends RMResource implements RMIDed, RMVersioned {
  plateId:string;
  obstructionId:string;
  points:RMPlatePoint[];
  edges:RMEdge[];
  obstructionType:OBSTRUCTION_TYPE;

  plate?:RMPlate;
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMStash extends RMResource implements RMIDed, RMVersioned {
  plateId:string;
  stashId:string;
  label:string;
  value:string;

  points:RMPlatePoint[];
  edges:RMEdge[];
  obstructions:RMObstruction[];

  plate?:RMPlate;
  readonly id?: string;
  readonly version?: string;
  readonly links?: RMLinks;
}

export class RMBounds {
  north:number;
  east:number;
  west:number;
  south:number;
}

export class RMPurchase {
  purchaseId:string;
  purchasedProducts:RMProduct[];
  payment:any;
  workOrder:RMWorkOrder;
  createdDate:any;
}

export class RMProduct {
    productId:string;
    name:string;
    description:string;
    displayValue:string;
    cost:number;
    isMandatory:boolean;
    mandatory:boolean;
    isValid:boolean;
    currency:string;

    purchaseDate:string;
    isPurchased:boolean;
}

export class RMDumpConfiguration {
  dumpConfigurationId:string;
  name:string;
  description:string;
  displayValue:string;
  isValid:boolean;
}

export class RMSubscriptionDurationType {
  id:string;
  name:string;
  description:string;
  duration:number;
  timeUnit:string;
  daysUntilDueInvoice:number;
}

export class RMCurrency {
  code:string;
  displayValue:string;
	lowestDenominationFactor:number;
	symbol:string;
  selectable:boolean;
}

export class RMSubscription {
  subscriptionId:string;
  purchases:RMPurchase[];
  payments:[];
  subscribedUsers:RMUser[];
  createdDate:any;
  startDate:any;
  endDate:any;
  active:boolean;
  autoRenew:boolean;
  creator:RMUser;
  roofer:RMUser;
  subscriptionType:string;
  subscriptionDurationType:RMSubscriptionDurationType;
  invoiceItems:[];
}

export class RMInvoice {
  invoiceId:string;
  invoiceDate:any;
  createdDate:any;
  invoiceUser:RMUser;
  description:string;
  status:string;
  amount;number;
  payments:any[];
}

export enum RMWorkOrderAction {
  VIEW_WORKORDER = "VIEW_WORKORDER",
  OPERATE_WORKORDER = "OPERATE_WORKORDER",
  DELETE_WORKORDER = "DELETE_WORKORDER",
  CANCEL_WORKORDER = "CANCEL_WORKORDER",

  VIEW_REPORT = "VIEW_REPORT",
  VIEW_BOM = "VIEW_BOM",
  VIEW_3DMODEL = "VIEW_3DMODEL",

  CREATE_USER = "CREATE_USER",
  MODIFY_USER = "MODIFY_USER",

  SHOW_WORKORDER_LIST_TAB = "SHOW_WORKORDER_LIST_TAB",
  SHOW_WORKORDER_GROUP_LIST_TAB = "SHOW_WORKORDER_GROUP_LIST_TAB",
  SHOW_ADMINISTRATOR_TAB = "SHOW_ADMINISTRATOR_TAB",
  PAGES_LIST_WORKORDER = "list",
  PAGES_CREATE_WORKORDER = "newWorkOrder",
  PAGES_ADMIN = "admin"
}

export class RMWorkOrders extends RMResourceCollection<RMWorkOrder> {
}

export type RMWorkOrdersArray = RMWorkOrder[];

export function arePlatesEqual(plate1:RMPlate, plate2:RMPlate) {
  if(plate1.plateId == plate2.plateId) {
    return true;
  }
  return false;
}

export function arePointsEqual(point1:RMPlatePoint, point2:RMPlatePoint) {
  let plate1Id = point1.plateId != undefined ? point1.plateId : point1.plate.plateId;
  let plate2Id = point2.plateId != undefined ? point2.plateId : point2.plate.plateId;
  if(plate1Id == plate2Id && point1.pointId == point2.pointId) {
    return true;
  }
  return false;
}

export function areEdgesEqual(edge1:RMEdge,edge2:RMEdge) {
  if((arePointsEqual(edge1.pointOne,edge2.pointOne) && arePointsEqual(edge1.pointTwo,edge2.pointTwo))
    || (arePointsEqual(edge1.pointOne,edge2.pointTwo) && arePointsEqual(edge1.pointTwo,edge2.pointOne))) {
    return true;
  }
  return false;
}

export function areCorrelationsEqual(correlation1:RMCorrelation,correlation2:RMCorrelation) {
  if((arePointsEqual(correlation1.pointOne,correlation2.pointOne) && arePointsEqual(correlation1.pointTwo,correlation2.pointTwo))
    || (arePointsEqual(correlation1.pointOne,correlation2.pointTwo) && arePointsEqual(correlation1.pointTwo,correlation2.pointOne))) {
    return true;
  }
  return false;
}

export function getPlateIdOfPoint(point:RMPlatePoint) {
  return point.plateId == undefined ? point.plate.plateId : point.plateId;
}

export function getPlateIdOfEdge(edge:RMEdge) {
  return edge.plateId == undefined ? edge.plate.plateId : edge.plateId;
}

export function copyPlatePointAttributesTo(srcPoint:RMPlatePoint,destPoint:RMPlatePoint) {
  destPoint.latitude = srcPoint.latitude;
  destPoint.longitude = srcPoint.longitude;
  destPoint.x = srcPoint.x;
  destPoint.y = srcPoint.y;
}

export function clonePlatePoint(srcPoint:RMPlatePoint):RMPlatePoint {
  let destPoint:RMPlatePoint = createNewPlatePoint(srcPoint.latitude,srcPoint.longitude,srcPoint.plate,srcPoint.x,srcPoint.y);
  destPoint.pointId = srcPoint.pointId;
  destPoint.label = srcPoint.label;
  destPoint.stashIds = srcPoint.stashIds;
  return destPoint;
}
