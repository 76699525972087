import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rm-roofmath-modal',
  templateUrl: './rm-roofmath-modal.component.html',
  styleUrls: ['./rm-roofmath-modal.component.scss']
})
export class RoofmathModalComponent implements OnInit, OnDestroy {

  showDialog:boolean = false;

  @Input() modalHeader = '';
  @Input() modalMessage = '';
  @Input() customDialog = false;
  @Input() labelYes = "Yes";
  @Input() labelNo = "No";
  @Input() labelCancel = "Cancel";

  @Output() okButtonClick:EventEmitter<any> = new EventEmitter<any>();
  @Output() yesButtonClick:EventEmitter<any> = new EventEmitter<any>();
  @Output() noButtonClick:EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelButtonClick:EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnDestroy(): void {
    this.showDialog = false;
  }

  ngOnInit(): void {
    this.showDialog = true;
  }

  okButtonClicked() {
    this.okButtonClick.emit();
  }

  yesButtonClicked() {
    this.yesButtonClick.emit();
  }

  noButtonClicked() {
    this.noButtonClick.emit();
  }

  cancelButtonClicked() {
    this.cancelButtonClick.emit();
  }

}
