import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RMWorkorderAdditionalInfo } from '@roofmath/models/workorders/rm-workorder.model';

@Component({
  selector: 'rm-workorder-additional-info',
  templateUrl: './rm-workorder-additional-info.component.html',
  styleUrls: ['./rm-workorder-additional-info.component.scss']
})
export class RMWorkorderAdditionalInfoComponent implements OnInit {

  bomRoofTypeName = null;
  bomTakeDownRoof = null;

  @Input('workOrderId') workOrderId;

  @Output('roofTypeSelected') roofTypeSelected:EventEmitter<any> = new EventEmitter<any>();
  @Output('additionalInfoSelected') additionalInfoSelected:EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onRoofTypeChanged(roofType) {
    this.bomRoofTypeName = roofType;
    this.roofTypeSelected.emit(roofType);
  }

  update() {
    this.additionalInfoSelected.emit({
      "bomRoofTypeName" : this.bomRoofTypeName,
      "bomTakeDownRoof" : this.bomTakeDownRoof
    });
  }
}
