import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RMUser,RMReportDTO } from './models/rm-auth.model';
import { RM_REL_GET_ALL_WORKORDERS_BY_OPERATOR, RM_REL_GET_ALL_WORKORDERS_BY_ROOFER, RM_REL_GET_ALL_WORKORDERS_BY_SALES_USER, RM_REL_GET_ALL_WORKORDERS_CSV, RM_REL_GET_ALL_WORKORDER_METADATA_CSV, RM_REPORTS_HREF } from './models/rm-resources.model';
import { RMRestapiService } from './restapi/rm-restapi.service';

@Injectable({
  providedIn: 'root'
})
export class RMReportService {

  constructor(private restApiService: RMRestapiService) { }

  getWorkOrdersMetadataCSV():Promise<RMReportDTO> {
    return new Promise<RMReportDTO>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REPORTS_HREF}/${RM_REL_GET_ALL_WORKORDER_METADATA_CSV}`)
      .then((reportDTO) => {
        resolve(reportDTO);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  getAllWorkOrdersCSV():Promise<RMReportDTO> {
    return new Promise<RMReportDTO>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REPORTS_HREF}/${RM_REL_GET_ALL_WORKORDERS_CSV}`)
      .then((reportDTO) => {
        resolve(reportDTO);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  getWorkOrdersBySalesUser(salesUser:RMUser, fromDate, toDate):Promise<RMReportDTO> {
    return new Promise<RMReportDTO>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REPORTS_HREF}/${RM_REL_GET_ALL_WORKORDERS_BY_SALES_USER}?userId=${salesUser.userId}&startDate=${fromDate}&endDate=${toDate}`)
      .then((reportDTO) => {
        resolve(reportDTO);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  getAllWorkOrdersByRoofer(user:RMUser):Promise<RMReportDTO> {
    return new Promise<RMReportDTO>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REPORTS_HREF}/${RM_REL_GET_ALL_WORKORDERS_BY_ROOFER}?userId=${user.userId}`)
      .then((reportDTO) => {
        resolve(reportDTO);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  getAllWorkOrdersByOperator(user:RMUser):Promise<RMReportDTO> {
    return new Promise<RMReportDTO>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REPORTS_HREF}/${RM_REL_GET_ALL_WORKORDERS_BY_OPERATOR}?userId=${user.userId}`)
      .then((reportDTO) => {
        resolve(reportDTO);
      })
      ,(error) => {
        reject(error);
      }
    });
  }
}
