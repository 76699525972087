import { Injectable } from '@angular/core';
import { EDGE_TYPE, PLATE_EVENTS, RMSubscribeEvent, RM_REL_GET_UNPROCESSED_WORKORDER_GROUP_COUNT, RM_REL_GET_WORKORDER_GROUP } from '@roofmath/models/rm-resources.model';
import { areEdgesEqual, arePlatesEqual, arePointsEqual, getPlateIdOfPoint, RMBOMRoofType, RMCorrelation, RMEdge, RMPlate, RMPlatePoint, RMProduct, RMWorkOrder, RMWorkOrderFlagType } from '@roofmath/models/workorders/rm-workorder.model';
import { RMCorrelationsService } from './rm-correlations.service';
import { RMEdgesService } from './rm-edges.service';
import { createNewEdge, RMWorkOrdersService } from './rm-workorders.service';
import { RMRestapiService } from './rm-restapi.service';

@Injectable({
  providedIn: 'root'
})
export class RMDataService {

  private workOrder:RMWorkOrder;
  private mapHeight:number;
  private mapWidth:number;
  public static availableProducts:RMProduct[];
  public static validBOMRoofTypes:RMBOMRoofType[];
  public static availableFlagTypes:RMWorkOrderFlagType[];

  private postSignInRouterChildHeight:number;

  private workOrdersTableContext = {};
  
  constructor(private correlationsService:RMCorrelationsService, private edgesService:RMEdgesService, private restApiService:RMRestapiService) { 
    this.captureDocumentSize();
  }

  captureDocumentSize() {
    this.setMapHeight(document.body.clientHeight);
    this.setMapWidth(document.body.clientWidth);
  }

  setMapHeight(clientHeight:number) {
    this.mapHeight = Math.round(0.75 * clientHeight);
    console.log("setMapHeight::",this.mapHeight);
  }

  setMapWidth(clientWidth:number) {
    this.mapWidth = Math.round(0.464 * clientWidth);
    console.log("setMapWidth::",this.mapWidth);
  }

  getMapHeight() {
    return this.mapHeight;
  }

  getMapWidth() {
    return this.mapWidth;
  }

  setPostSignInRouterChildHeight(clientHeight:number) {
    this.postSignInRouterChildHeight = clientHeight;
  }

  getPostSignInRouterChildHeight() {
    return this.postSignInRouterChildHeight;
  }

  public setSelectedWorkOrder(workOrder:RMWorkOrder) {
    // return new Promise((resolve,reject) => {
    //   this.correlationsService.initializeCorrelations(workOrder)
    //   .then((workOrder) => {
    //     this.workOrder = workOrder;
    //     resolve(this.workOrder);
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     reject(err);
    //   })
    // });
    this.workOrder = workOrder;
    this.correlationsService.initializeCorrelations(this.workOrder);
  }

  public getSelectedWorkOrder(workOrderId:string):RMWorkOrder {
    return this.workOrder;
  }

  public initializeData(workOrder:RMWorkOrder) {
    this.edgesService.initializeEdges(workOrder);
    this.correlationsService.initializeCorrelations(workOrder);
  }

  getPlateByPlateId(workOrder:RMWorkOrder,plateId):RMPlate {
    for(let plate of workOrder.plates) {
      if(plate.plateId == plateId) {
        return plate;
      }
    }
  }

  getEdgeBetweenPoints(plate:RMPlate,point1:RMPlatePoint,point2:RMPlatePoint) {
    let tempEdge = createNewEdge(point1,point2);
    for(let edge of plate.edges) {
      if(areEdgesEqual(tempEdge,edge)) {
        return edge;
      }
    }
    return undefined;
  }

  computeCorrelatedEdges(workOrder:RMWorkOrder,pointOne:RMPlatePoint,pointTwo:RMPlatePoint) {
    let correlatedEdges = [];
    let plate1Id = pointOne.plateId == undefined ? pointOne.plate.plateId : pointOne.plateId;
    let plate2Id = pointTwo.plateId == undefined ? pointTwo.plate.plateId : pointTwo.plateId;
    if(plate1Id == plate2Id) {
      let edgePlate:RMPlate = this.getPlateByPlateId(workOrder,plate1Id);
      let correlationsPoint1 = this.getCorrelatedPointsForPoint(workOrder,pointOne);
      let correlationsPoint2 = this.getCorrelatedPointsForPoint(workOrder,pointTwo);
      console.log("Before plates Date::",Date());
      workOrder.plates.forEach(plate => {
        if(!arePlatesEqual(edgePlate,plate)) {
          let correlatedPoint1 = undefined;
          let correlatedPoint2 = undefined;
          correlatedPoint1 = correlationsPoint1.filter(point => {
            let plateId = point.plateId == undefined ? point.plate.plateId : point.plateId;
            return plate.plateId == plateId;
          })[0];
          correlatedPoint2 = correlationsPoint2.filter(point => {
            let plateId = point.plateId == undefined ? point.plate.plateId : point.plateId;
            return plate.plateId == plateId;
          })[0];
          if(correlatedPoint1 != undefined && correlatedPoint2 != undefined) {
            let newEdge = this.getEdgeBetweenPoints(plate,correlatedPoint1,correlatedPoint2);
            if(newEdge == undefined) {
              newEdge = createNewEdge(correlatedPoint1,correlatedPoint2);
              newEdge.edgeType = EDGE_TYPE.SYSTEM;
            }
            correlatedEdges.push(newEdge);
          }
        }
      })
      console.log("After plates Date::",Date());
    }
    else if(plate1Id != plate2Id) {
      let edgesPoint1 = this.edgesService.getAllEdgesOfPoint(workOrder,this.getPlateByPlateId(workOrder,getPlateIdOfPoint(pointOne)), pointOne);//[]
      let edgesPoint2 = this.edgesService.getAllEdgesOfPoint(workOrder,this.getPlateByPlateId(workOrder,getPlateIdOfPoint(pointTwo)), pointTwo);//[{W1,W2}]
      if(edgesPoint1.length > 0 || edgesPoint2.length > 0) {
        edgesPoint1.forEach(edge => {
          let edges = this.computeCorrelatedEdges(workOrder,edge.pointOne,edge.pointTwo);
          edges.forEach(newEdge => {
            correlatedEdges.push(newEdge);
          })
        })
        edgesPoint2.forEach(edge => {
          let edges = this.computeCorrelatedEdges(workOrder,edge.pointOne,edge.pointTwo);
          edges.forEach(newEdge => {
            correlatedEdges.push(newEdge);
          })
        })
      }
    }
    return correlatedEdges;
  }

  getCorrelatedPointsForPoint(workOrder:RMWorkOrder,point:RMPlatePoint) {
    let correlatedPoints = [];
    this.correlationsService.getAllCorrelationsForPoint(workOrder,point).forEach(correlation => {
      if(arePointsEqual(correlation.pointOne,point)) {
        correlatedPoints.push(correlation.pointTwo);
      }
      else {
        correlatedPoints.push(correlation.pointOne);
      }
    });
    return correlatedPoints;
  }

  getPlatePointWithId(workOrder:RMWorkOrder,plateId,platePointId):RMPlatePoint {
    let point = undefined;
    let plate = workOrder.plates.filter(plate => {
      return plate.plateId == plateId;
    })[0];
    if(plate != undefined) {
      point = plate.points.filter(tempPoint => {
        return tempPoint.pointId == platePointId;
      })[0];
    }
    return point;
  }

  public addToWorkOrdersTableContext(contextName,contextObj) {
    if(this.workOrdersTableContext == undefined || this.workOrdersTableContext == null) {
      this.workOrdersTableContext = {};
    }
    if(this.workOrdersTableContext[contextName] == undefined || this.workOrdersTableContext[contextName] == null) {
      this.workOrdersTableContext[contextName] = {};
    }
    if(contextObj.currPage != undefined) {
      this.workOrdersTableContext[contextName]["currPage"] = contextObj.currPage;
    }
    if(contextObj.searchText != undefined) {
      this.workOrdersTableContext[contextName]["searchText"] = contextObj.searchText;
    }
    if(contextObj.appliedStatusFilterIds != undefined) {
      this.workOrdersTableContext[contextName]["appliedStatusFilterIds"] = contextObj.appliedStatusFilterIds;
    }
    if(contextObj.filteredRoofers != undefined) {
      this.workOrdersTableContext[contextName]["filteredRoofers"] = contextObj.filteredRoofers;
    }
    // if(contextObj.selectedStatusFilterOptions != undefined) {
      this.workOrdersTableContext[contextName]["selectedStatusFilterOptions"] = contextObj.selectedStatusFilterOptions;
    // }
    if(contextObj.first != undefined) {
      this.workOrdersTableContext[contextName]["first"] = contextObj.first;
    }
    if(contextObj.totalRecords != undefined) {
      this.workOrdersTableContext[contextName]["totalRecords"] = contextObj.totalRecords;
    }
    if(contextObj.sortCol != undefined) {
      this.workOrdersTableContext[contextName]["sortCol"] = contextObj.sortCol;
    }
    if(contextObj.sortDir != undefined) {
      this.workOrdersTableContext[contextName]["sortDir"] = contextObj.sortDir;
    }
    if(contextObj.height != undefined) {
      this.workOrdersTableContext[contextName]["height"] = contextObj.height;
    }
  }

  public getWorkOrdersTableContext(contextName) {
    return this.workOrdersTableContext[contextName];
  }

  public clearWorkOrdersTableContext(contextName) {
    if(contextName != undefined || contextName != null || contextName != '') {
      this.workOrdersTableContext[contextName] = {};
    }
  }

  public isFlagReWorked(flag) {
    if(flag.reWorkComment != undefined && flag.reWorkComment != '') {
      return true;
    }
    return false;
  }

  public isFlagFixed(flag) {
    if(flag.fixComment != undefined && flag.fixComment != '') {
      return true;
    }
    return false;
  }

  public getAllProducts():RMProduct[] {
    let products = JSON.parse(JSON.stringify(RMDataService.availableProducts));
    return products;
  }

  public getAllValidBOMRoofTypes():RMBOMRoofType[] {
    let bomRoofTypes = JSON.parse(JSON.stringify(RMDataService.validBOMRoofTypes));
    return bomRoofTypes;
  }

  public getAllFlagTyps():RMProduct[] {
    let flagTypes = JSON.parse(JSON.stringify(RMDataService.availableFlagTypes));
    return flagTypes;
  }

  getProductByProductId(productId):RMProduct {
    let product:RMProduct = undefined;
    product = RMDataService.availableProducts.filter(tempProduct => {
      if(tempProduct.productId == productId) {
        return true;
      }
    })[0];
    return product;
  }

  getUnprocessedWorkOrderGroupCount() {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REL_GET_WORKORDER_GROUP}/${RM_REL_GET_UNPROCESSED_WORKORDER_GROUP_COUNT}`)
      .then(data => {
        resolve(data);
      })
    });
  }

  public getWorkOrderProductsFromPurchases(workOrder:RMWorkOrder):RMProduct[] {
    let products:RMProduct[] = [];
    workOrder.purchases.forEach(purchase => {
      purchase.purchasedProducts.forEach(product => {
        let productObj= this.getProductByProductId(product.productId)
        products.push(productObj);
      })
    })
    return products;
  }
}

export function getMapSize() {
  return {
    mapWidth:document.body.clientWidth,
    mapHeight:document.body.clientHeight
  };
}