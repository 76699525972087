import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMRestapiService } from '@roofmath/restapi/rm-restapi.service';
import { RMWorkOrdersService } from '@roofmath/restapi/rm-workorders.service';
import { RMCacheService } from '@roofmath/restapi/rm-cache.service';
import { RMHalInterceptor } from './rm-hal.interceptor';
import { RMCheckoutService } from '@roofmath/payment/checkout.service';
import { RMUserManagementService } from './rm-user-management.service';

@NgModule({
    providers: [
      RMAuthenticationService,
      { provide: HTTP_INTERCEPTORS, useClass: RMHalInterceptor, multi: true},
      RMRestapiService,
      RMWorkOrdersService,
      RMCacheService,
      RMCheckoutService,
      RMUserManagementService
    ]
})
export class RMRESTApiModule {
    constructor() {
    }
}
