<p-dialog [draggable]="false" [modal]="true" [resizable]="false" [closeOnEscape]="false" [header]="modalHeader" [closable]="false" [(visible)]="showDialog" *ngIf="!customDialog">
    <div class="modalMessage">
        {{ modalMessage }}
    </div>
    <div class="modalButtons">
        <button pButton type="button" class="ui-button-raised ui-button-rounded okButton" label="Ok" (click)="okButtonClicked()"></button>
    </div>
</p-dialog>

<p-dialog [draggable]="false" [modal]="true" [resizable]="false" [closeOnEscape]="false" [header]="modalHeader" [closable]="false" [(visible)]="showDialog" *ngIf="customDialog">
    <div class="modalMessage">
        {{ modalMessage }}
    </div>
    <div class="modalButtons">
        <button pButton type="button" class="ui-button-raised ui-button-rounded mr-2" [label]="labelYes" (click)="yesButtonClicked()"></button>
        <button pButton type="button" class="ui-button-raised ui-button-rounded mr-2" [label]="labelNo" (click)="noButtonClicked()"></button>
        <button pButton type="button" class="ui-button-raised ui-button-rounded" [label]="labelCancel" (click)="cancelButtonClicked()"></button>
    </div>
</p-dialog>