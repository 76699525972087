import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpHeaders, HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Subject } from 'rxjs';
import { RMAbstractService } from '@roofmath/restapi/rm-abstract.service';
import { RMSessionStorageService } from '@roofmath/core/services/storage/rm-session-storage.service';
import { RMUser, RMUserRequest } from '@roofmath/models/rm-auth.model';
import { RM_AUTHAPI_HREF, RM_REL_CHANGE_PASSWORD, RM_REL_CREATE_USER, RM_REL_FORGOT_PASSWORD, RM_REL_GENERATE_OTP, RM_REL_GET_ALL_PRODUCTS, RM_REL_GET_ALL_VALID_BOM_ROOF_TYPES, RM_REL_GET_FLAG_TYPES, RM_REL_RESEND_OTP, RM_REL_SIGNIN, RM_REL_SIGNIN_ADHOC_USER, RM_REL_SIGNOUT, RM_REL_SIGNUP, RM_REL_VALIDATE_OTP, RM_RESTAPI_HREF } from '@roofmath/models/rm-resources.model';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RMBOMRoofType, RMProduct } from '@roofmath/models/workorders/rm-workorder.model';
import { RMDataService } from './rm-data.service';

@Injectable({
  providedIn: 'root'
})
export class RMAuthenticationService extends RMAbstractService {

  private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  private SESSION_STORAGE_USER_KEY:string = "user";
  // private userAuthenticationObservable:Observable<RMUser>;
  // private userAuthenticationSubscriber:Subscriber<RMUser>;
  private userAuthenticationSubject:Subject<RMUser> = new Subject<any>();

  private user:RMUser = undefined;

  public isApplicationSetup:boolean = false;

  constructor(private router: Router,
    private httpClient: HttpClient,
    private sessionStorageService: RMSessionStorageService,
    @Inject(DOCUMENT) private document: Document) {
      super();
      this.setupApplication();
  }

  setupApplication() {
    let promises = [];
    this.populateUser();
    if(this.user) {
      let productPromise = this.populateAllProducts();
      promises.push(productPromise);
      let bomRoofTypePromise = this.populateAllBOMRoofTypes();
      promises.push(bomRoofTypePromise);
      let flagTypePromise = this.populateAllFlagTypes();
      promises.push(flagTypePromise);
      Promise.all(promises).then(() => {
        this.isApplicationSetup = true;
      })
    }
    else {
      this.isApplicationSetup = false;
    }
  }

  private populateUser() {
    if (this.isSignedIn()) {
      let token = this.getSignedInUserToken();
      if(token == null || token == undefined) {
        return null;
      }
      let tokenObj = this.parseJwt(token);
      this.user = new RMUser();
      this.user.userId = tokenObj.userId;
      this.user.userName = tokenObj.userName;
      this.user.firstName = tokenObj.firstName;
      this.user.lastName = tokenObj.lastName;
      this.user.role = tokenObj.role;
      this.user.color = tokenObj.color;
      this.user.preferredCurrency = tokenObj.preferredCurrency;
      // this.user.discountPercentage = tokenObj.discountPercentage;
      this.user.permittedActions = tokenObj.permittedActions;
    }
    return this.user;
  }

  getSignedInUser(): RMUser {
      // this.populateUser();
      return this.user;
  }

  getSignedInUserToken(): string {
    let token = this.sessionStorageService.getItem(this.SESSION_STORAGE_USER_KEY);
    return token;
  }

  getSignedInUserId(): string {
    if (this.getSignedInUser()) {
      return this.user.userId;
    }
    return null;
  }

  getSignedInUserName(): string {
    if (this.getSignedInUser()) {
      return this.user.userName;
    }
    return null;
  }

  getSignedInUserRole(): string {
    if (this.getSignedInUser()) {
      return this.user.role;
    }
    return null;
  }

  getSignedInUserColor(): string {
    if (this.getSignedInUser()) {
      return this.user.color;
    }
    return null;
  }

  getSignedInUserDiscountPercentage() {
    let discountPercentage = 0;
    // if (this.getSignedInUser()) {
    //   if(this.user.discountPercentage != null || this.user.discountPercentage != undefined) {
    //     return this.user.discountPercentage;
    //   }
    // }
    return discountPercentage;
  }

  getSignedInUserPermittedActions() {
    if (this.getSignedInUser()) {
      return this.user.permittedActions;
    }
    return [];
  }

  isSignedIn(): boolean {
    let user = this.sessionStorageService.getItem(this.SESSION_STORAGE_USER_KEY);
    // if(user != undefined && user != null && (this.user == undefined || this.user == null)) {
    //   this.populateUser();
    // }
    return  !!user;
  }

  getUserAuthenticationSubscription() {
    return this.userAuthenticationSubject;
  }

  signIn(userName: string,password:string,transactionId:string): Promise<string> {
    let user:any = undefined;
    let userRequest:RMUserRequest = new RMUserRequest();
    userRequest.userName = userName;
    userRequest.password = password;
    return new Promise<string>((resolve,reject) => {
      let signInUrl = RM_REL_SIGNIN;
      if(transactionId != '') {
        signInUrl = RM_REL_SIGNIN_ADHOC_USER;
      }
      let postRequest = this.httpClient.post<RMUserRequest>(`${RM_AUTHAPI_HREF}/${signInUrl}`, userRequest as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject('Authentication failed.');
        })
      );
      postRequest.subscribe((dataUser: any) => {
        if(dataUser == undefined || dataUser == null) {
          user = undefined;
          this.setupApplication();
          this.publishUserAuthenticationEvent(user);
          resolve(dataUser);
        }
        else {
          user = dataUser;
          this.sessionStorageService.setItem(this.SESSION_STORAGE_USER_KEY, user.token);
          this.setupApplication();
          // this.publishUserAuthenticationEvent(user);
          this.publishUserAuthenticationEvent(user);
          resolve(dataUser);
        }
      });
    });
  }

  publishUserAuthenticationEvent(user:RMUser) {
    this.userAuthenticationSubject.next(user);
  }

  forgetSignin() {
    this.sessionStorageService.removeItem(this.SESSION_STORAGE_USER_KEY);
    this.publishUserAuthenticationEvent(null);
    this.router.navigate(['/login']);
    window.location.reload();
  }

  generateForgotPasswordOTP(username) {
    return new Promise<any>((resolve,reject) => {
      let postRequest = this.httpClient.post<any>(`${RM_AUTHAPI_HREF}/${RM_REL_FORGOT_PASSWORD}`,{ 
        userName : username 
      } as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err.error);
        })
      );
      postRequest.subscribe((token: any) => {
        resolve(token);
      });
    });
  }

  changePassword(token,password,confirmPassword) {
    return new Promise<any>((resolve,reject) => {
      let postRequest = this.httpClient.post<any>(`${RM_AUTHAPI_HREF}/${RM_REL_CHANGE_PASSWORD}?requestId=${token}`, {
        password:password,
        confirmPassword:confirmPassword
      } as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err.error);
        })
      );
      postRequest.subscribe((message: any) => {
        resolve(message);
      });
    });
  }

  signOut(): Promise<object> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      this.httpClient.get<any>(`${RM_AUTHAPI_HREF}/${RM_REL_SIGNOUT}`,httpOptions as any)
      .subscribe(() => {
        this.forgetSignin();
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.forgetSignin();
        }
      })
    })
  }

  signUp(user:RMUser, httpParams?: HttpParams): Promise<string> {
    return new Promise<string>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      let postRequest = this.httpClient.post<string>(`${RM_AUTHAPI_HREF}/${RM_REL_SIGNUP}`, user as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      postRequest.subscribe((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  createUser(user:RMUser, httpParams?: HttpParams): Promise<string> {
    return new Promise<string>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      let httpOptions = this.getHttpOptions();
      let postRequest = this.httpClient.post<string>(`${RM_AUTHAPI_HREF}/${RM_REL_CREATE_USER}`, user as any,httpOptions as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      postRequest.subscribe((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  getUserDiscountPercentage() {
    let signedInUser = this.getSignedInUser();
    let discountPercentage = 0;
    // if(signedInUser.discountPercentage != undefined || signedInUser.discountPercentage != null) {
    //   discountPercentage = signedInUser.discountPercentage;
    // }
    return discountPercentage;
  }

  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  getHttpOptions() {
    let token = this.getSignedInUserToken();
    let httpOptions = {};
    if(token != null && token != undefined) {
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token 
        })
      };
    }
    return httpOptions;
  }

  generateOtp(user):Promise<string> {
    return new Promise<string>((resolve,reject) => {
      let postRequest = this.httpClient.post<string>(`${RM_AUTHAPI_HREF}/${RM_REL_GENERATE_OTP}`,user as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      postRequest.subscribe((token: any) => {
        resolve(token);
      });
    })
  }

  validateOtp(otp,otpType,token):Promise<boolean> {
    return new Promise<boolean>((resolve,reject) => {
      let postRequest = this.httpClient.post<boolean>(`${RM_AUTHAPI_HREF}/${RM_REL_VALIDATE_OTP}?requestId=${token}`,{
        otpType:otpType,
        otp:otp
      } as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      postRequest.subscribe((isValid: any) => {
        resolve(isValid);
      });
    })
  }

  resendOtp(otpType,token):Promise<string> {
    return new Promise<string>((resolve,reject) => {
      let postRequest = this.httpClient.post<string>(`${RM_AUTHAPI_HREF}/${RM_REL_RESEND_OTP}`,{
        requestId:token,
        otpType:otpType
      } as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      postRequest.subscribe((token: any) => {
        resolve(token);
      });
    })
  }

  populateAllProducts():Promise<RMProduct[]> {
    return new Promise<RMProduct[]>((resolve,reject) => {
      let httpOptions = this.getHttpOptions();
      let getRequest = this.httpClient.get<RMProduct[]>(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_PRODUCTS}`,httpOptions as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      getRequest.subscribe((dataProducts: any) => {
        RMDataService.availableProducts = dataProducts;
        resolve(dataProducts);
      });
    })
  }

  populateAllBOMRoofTypes():Promise<RMBOMRoofType[]> {
    return new Promise<RMBOMRoofType[]>((resolve,reject) => {
      let httpOptions = this.getHttpOptions();
      let getRequest = this.httpClient.get<RMBOMRoofType[]>(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_VALID_BOM_ROOF_TYPES}`,httpOptions as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      getRequest.subscribe((bomRoofTypes: any) => {
        RMDataService.validBOMRoofTypes = bomRoofTypes;
        resolve(bomRoofTypes);
      });
    })
  }

  populateAllFlagTypes():Promise<RMProduct[]> {
    return new Promise<RMProduct[]>((resolve,reject) => {
      let httpOptions = this.getHttpOptions();
      let getRequest = this.httpClient.get<RMProduct[]>(`${RM_RESTAPI_HREF}/${RM_REL_GET_FLAG_TYPES}`,httpOptions as any)
      .pipe(
        catchError((err):any => {
          console.error("Error::",err);
          reject(err);
        })
      );
      getRequest.subscribe((dataFlagTypes: any) => {
        RMDataService.availableFlagTypes = dataFlagTypes;
        resolve(dataFlagTypes);
      });
    })
  }
}