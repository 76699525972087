import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RMRestapiResolverService } from '@roofmath/core/resolvers/rm-restapi-resolver.service';
import { RMPostSignInComponent } from '@roofmath/postauthentication/rm-post-sign-in.component';
import { RMSignedInGuard } from '@roofmath/rm-signed-in.guard';
import { RMAdhocUserWorkOrderComponent } from './adhoc-user-work-order/rm-adhoc-user-work-order.component';
import { RMContactUsComponent } from './contact-us/rm-contact-us.component';
import { RMForgotPasswordComponent } from './forgot-password/rm-forgot-password.component';
import { RMLandingComponent } from './landing/rm-landing.component';
import { RMResetPasswordComponent } from './reset-password/rm-reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: RMLandingComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/rm-authentication.module').then(m => m.RMAuthenticationModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./registration/rm-registration.module').then(m => m.RMRegistrationModule)
  },
  {
    path: 'adhocWorkOrder',
    component: RMAdhocUserWorkOrderComponent
  },
  {
    path: 'forgotPassword',
    component: RMForgotPasswordComponent
  },
  {
    path: 'resetPassword',
    component: RMResetPasswordComponent
  },
  {
    path: 'app',
    component: RMPostSignInComponent,
    canActivate: [RMSignedInGuard],
    canActivateChild: [RMSignedInGuard],
    // resolve: {
    //   restapi: RMRestapiResolverService
    // },
    data: {
    },
    children: [
      {
        path: '',
        redirectTo: 'workorders',
        pathMatch: 'prefix'
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: './dashboard/rm-dashboard.module#RMDashboardModule'
      // },
      {
        path: 'workorders',
        loadChildren: () => import('./workorders/rm-workorders.module').then(m => m.RMWorkordersModule)
      },
      {
        path: 'workorderGroups',
        loadChildren: () => import('./workorder-group/rm-workorder-group.module').then(m => m.RMAWorkOrderGroupModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/rm-admin.module').then(m => m.RMAdminModule)
      },
      {
        path: 'contactUs',
        component: RMContactUsComponent
      },
      // {
      //   path: 'map',
      //   loadChildren: () => import('./map/rm-map.module').then(m => m.RMMapModule)
      // },
      {
        path: 'userDetails',
        loadChildren: () => import('./rm-user-details/rm-user-details.module').then(m => m.RMUserDetailsModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'corrected' })],
  exports: [RouterModule]
})
export class RMRoofMathRoutingModule { }
