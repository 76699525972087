import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { throwIfAlreadyLoaded } from './rm-module-import.guard';

import { RMCONSTANTS } from '@roofmath/core/constants/rm-constants';
import { RMLogService } from '@roofmath/core/services/log/rm-log.service';
import { RMRESTApiModule } from '@roofmath/restapi/rm-restapi.module';
import { RMRestapiResolverService } from '@roofmath/core/resolvers/rm-restapi-resolver.service';
import { RMDataService } from '@roofmath/restapi/rm-data.service';
import { RMCorrelationsService } from '@roofmath/restapi/rm-correlations.service';

@NgModule({
  declarations: [
  ],
  imports: [

  ],
  exports: [
    RouterModule,
    RMRESTApiModule
  ],
  providers: [
    RMCONSTANTS,
    RMLogService,
    RMRestapiResolverService,
    RMDataService,
    RMCorrelationsService
  ]
})
export class RMCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: RMCoreModule) {
    throwIfAlreadyLoaded(parentModule, 'RMCoreModule');
  }
}
