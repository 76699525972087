<div class="navbar navbar-expand-sm sticky-top toolBarHolder">
    <a class="navbar-brand" [routerLink]="['/']" routerLinkActive="router-link-active" [ngClass]="{'mobileNavBar': isMobileDevice}">
        <img src="assets/images/roofmath-logo.png" height="40" alt="Roof Math | Best Roofing Software" class="roofmathLogoImage">
    </a>
    <p-toolbar class="postSignInToolbar" [ngClass]="{'mobileToolbar': isMobileDevice}">
        <div class="p-toolbar-group-right">
            <button pButton type="button" label="Why Roofmath?" class="p-button-rounded p-button-text navToolbarButton" (click)="onNavigateToSection('whyRoofmath')"></button>
            <button pButton type="button" label="Pay As You Go" class="p-button-rounded p-button-text navToolbarButton payAsYouGoButton" [routerLink]="['/adhocWorkOrder']"></button>
            <button pButton type="button" label="Sign Up/Login" class="p-button-rounded p-button-text navToolbarButton" [routerLink]="['/login']"></button>
            <!-- <button pButton type="button" label="Sign Up" class="p-button-rounded p-button-text navToolbarButton" [routerLink]="['/signup']"></button> -->
        </div>
    </p-toolbar>
</div>
<div class="wrapper">
    <div class="container-fluid">
        <div class="row bannerImageHolder" #routerChildContainer>
            <div class="col-12 leftPanel imagePanel" [ngClass]="{'mobileImagePanel': isMobileDevice}">
                <div class="imageOverlayText"  [ngClass]="{'imageOverlayTextMobile':isMobileDevice}">
                    <p class="paraLine firstLine">Welcome to <span class="highlightRoofmath boldText">Roofmath!</span></p>
                    <p class="paraLine secondLine">A Technology driven smart roof report generator</p>
                    <p class="paraLine secondLine">that saves your time, efforts and money.</p>
                    
                    <div class="descriptionHolder">
                        <p class="paraLine descriptionLine">Roofmath digitally computes Roof measurements and provides accurate</p>
                        <p class="paraLine descriptionLine">reports for your business in shortest span of time.</p>
                    </div>
                </div>
            </div>
        </div>
            <div class="roofmathContentRow">
                <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        Hello<br>Roofers!
                    </div>
                    <div class="roofmathContentPara">
                        Worried about spending too much time, effort, and money to have a team member drive miles to a possible customer's place to measure the roof? Worried about the safety of your team member climbing on the roof? Have a ton of roofs to measure but don't have enough staff to do it all?
                    </div>
                    <div class="roofmathContentPara">
                        Roofmath is here to help! Our imagery experts use our cutting-edge technology to make 3D models of most roofs in minutes. Using these 3D models, we generate very accurate roof reports that include everything that you need to prepare a quote for your customers - reports that show individual faces, face areas, face pitches, edge lengths, edge types (rake, gutter, ridge, valley, hips), and obstructions if you need them. Our Bill of Materials report (currently Beta) will also give you an estimate of the material needed and the wastage. With our reasonably priced reports and the fastest 3D modeling in the business, get a first-mover advantage and save time, effort and money! So what are you waiting for? Sign-up now and get started!
                    </div>
                </div>
            </div>
            <div class="roofmathContentRow removeTopPadding" id="whyRoofmath">
                <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        Why<br><span class="highlightRoofmath">Roofmath?</span>
                    </div>
                    <div class="roofmathContentPara">
                        Measuring a roof is a tedious, time consuming and sometimes dangerous endeavor. A typical roofer spends 30 to 40 minutes walking a roof (tracing the perimeter), drawing a roof schematic, measuring the pitch, and calculating the area. Adjusters are sometimes presented with an even more demanding environment, measuring hundreds of roofs after a hailstorm, hurricane or other CAT scenario.
                    </div>
                    <div class="roofmathContentPara">
                        This is where Roofmath comes in. With the fastest turnaround time for a roof schematic in the industry (most roof schematics returned in minutes), you will be able to significantly speed up the estimating process. Our measurements of lengths, areas, and pitch are precise. In addition, Roofmath's cutting edge technology is also able to deliver full 3D models as part of the deliverable. We are also able to deliver a Bill of Materials (currently Beta) that you can use as a cross-check for your calculations.
                    </div>
                </div>
                <div class="row col-12 roofmathContent uspRow">
                    <div class="uspContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="uspTitle">Faster & <br>Accurate</div>
                        <div class="roofmathContentPara">
                            Our cutting-edge technology returns most roof reports within minutes with 3D models, that is the fastest turnaround in the industry.
                        </div>
                    </div>
                    <div class="uspContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="uspTitle">Precise <br>Reports</div>
                        <div class="roofmathContentPara">
                            Roofmath reports are high-quality reports that are very precise because they are based on precise measurements. Unlike most other companies, we actually construct 3D models using our software. These 3D models have fitting surfaces like in an actual roof. You can have access to these reports and view and play with them in a webpage! How cool is that!
                        </div>
                    </div>
                    <div class="uspContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="uspTitle">Reasonably <br>Priced</div>
                        <div class="roofmathContentPara">
                            Our technology allows us to keep a lid on prices! You will find that our prices are at least 20% lower than our competitors. Combine that with the fastest turnaround time. yes, that technology again! plus highly accurate reports and you are looking at a winning combination!
                            <div class="pricingHyperlink" (click)="onNavigateToSection('pricing')">View Pricing</div>
                        </div>
                    </div>
                    <div class="uspContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="uspTitle">From a Veteran in the Aerial Report Industry</div>
                        <div class="roofmathContentPara">
                            Roofmath was founded by Prashant Devdhar, who was the co-founder of 'Precigeo', the first roof schematics company in the world. Precigeo was featured in the Business section of Wall Street Journal in 2007.
                        </div>
                    </div>
                </div>
            </div>
            <div class="roofmathContentRow alternateBackgroundRow weDeliverRow">
                <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        We<br>Deliver
                    </div>
                </div>
                <div class="row col-12 roofmathContent weDeliverContentRow">
                    <div class="weDeliverContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="roofmathContentPara">
                            <img src="../../assets/images/report.svg" alt="" class="weDeliverImage">
                        </div>
                        <div class="weDeliverTitle">Roof <br>Reports</div>
                        <div class="roofmathContentPara">
                            Our base Roof Report contains several pages of detailed schematics that identify the various faces, their pitches, their areas, all edges, edge-lengths, and edge-types.
                        </div>
                    </div>
                    <div class="weDeliverContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="roofmathContentPara">
                            <img src="../../assets/images/obstruction.svg" alt="" class="weDeliverImage">
                        </div>
                        <div class="weDeliverTitle">Obstruction <br>Reports</div>
                        <div class="roofmathContentPara">
                            Our Obstructions Report contains a detailed drawing of the roof with all obstructions like vents, skylights, solar panels, chimneys etc marked on it.
                        </div>
                    </div>
                    <div class="weDeliverContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="roofmathContentPara">
                            <img src="../../assets/images/3DModel.svg" alt="" class="weDeliverImage">
                        </div>
                        <div class="weDeliverTitle">3D <br>Models</div>
                        <div class="roofmathContentPara">
                            The 3D model is shared on a webpage and you can interact with it using your mouse - turn it around and inspect the roof as if you are on it!
                        </div>
                    </div>
                    <div class="weDeliverContainer" [ngClass]="{'col-6': isMobileDevice,'col-3': !isMobileDevice}">
                        <div class="roofmathContentPara">
                            <img src="../../assets/images/bill.svg" alt="" class="weDeliverImage">
                        </div>
                        <div class="weDeliverTitle">Bill Of <br>Material</div>
                        <div class="roofmathContentPara">
                            Bill of Materials Report (currently in Beta) almost constructs the roof in software and compiles a list of all the major materials needed, the quantities, their current costs and the wastage ratios. This will help further reduce the time you spend.
                        </div>
                    </div>
                </div>
            </div>
            <div class="roofmathContentRow removeTopPadding removeBottomPadding" id="pricing">
                <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        Pricing
                        <div class="pricingDescription">
                            Roofmath offers most competetive prices for fastest and accurate roof reports. Look at the reasonably priced services for all your roofing solutions.
                        </div>
                    </div>
                </div>
                <div class="row col-12 roofmathContent">
                    <div class="subTitle highlightRoofmath">
                        Prices for Residential Roof Reports:
                    </div>
                    <div class="row pricesRow">
                        <div class="priceCol" [ngClass]="{'col-12 priceColMobile': isMobileDevice,'col-3': !isMobileDevice}">
                            <div class="productHolder">
                                <div class="productName">Basic Report</div>
                                <div class="productPrice">$15</div>
                            </div>
                        </div>
                        <div class="priceCol" [ngClass]="{'col-12 priceColMobile': isMobileDevice,'col-3': !isMobileDevice}">
                            <div class="productHolder">
                                <div class="productName">3D Model</div>
                                <div class="productPrice">$5</div>
                            </div>
                        </div>
                        <div class="priceCol" [ngClass]="{'col-12 priceColMobile': isMobileDevice,'col-3': !isMobileDevice}">
                            <div class="productHolder">
                                <div class="productName">Obstructions</div>
                                <div class="productPrice">$5</div>
                            </div>
                        </div>
                        <div class="priceCol" [ngClass]="{'col-12 priceColMobile': isMobileDevice,'col-3': !isMobileDevice}">
                            <div class="productHolder">
                                <div class="productName">Bill Of Materials</div>
                                <div class="productPrice">$5</div>
                            </div>
                            <div class="betaTag"><sup>*</sup>Beta Stage</div>
                        </div>
                    </div>
                    <div class="subTitle highlightRoofmath">
                        Commercial Roof Reports - Launching Soon!
                    </div>
                    <div class="pricingDescription commercialDescription">
                        Roofmath is currently beta-testing our Commercial Roof Services (Apartment Complexes, Industrial Roofs). If you are interested in participating, please contact us at <a class="mailLink" href="mailto:cs@roofmath.com">cs@roofmath.com</a>
                    </div>
                </div>
            </div>
            <div class="roofmathContentRow haveALookRow">
                <!-- <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        Have a<br>Look
                        <div [ngClass]="{'haveALookDescriptionMobile':isMobileDevice,'haveALookDescription':!isMobileDevice}">
                            Witness how simply Roofmath works and makes your life easy
                        </div>
                    </div>
                </div> -->
                <div class="row col-12 roofmathContent weDeliverContentRow">
                    <div class="col-4">
                        <div class="roofmathContentTitle">
                            Sample<br>Report
                            <div>
                                <button pButton (click)="onSampleReport()" class="sampleReportButton">Download</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-8">
                        <iframe width="420" height="315" src="https://www.youtube.com/watch?v=0HWckWpe2FA"></iframe>
                    </div> -->
                </div>
            </div>
            <div class="roofmathContentRow alternateBackgroundRow">
                <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        About<br>Us
                    </div>
                </div>
                <div class="row col-12 roofmathContent aboutUsRow">
                    <div class="aboutUsCol" [ngClass]="{'col-10': isMobileDevice,'col-2': !isMobileDevice}">
                        <div class="profileImage">
                            <img src="../../assets/images/PPD_Picture.jpeg" alt="RoofMath">
                        </div>
                        <div class="profileName">
                            Prashant Devdhar
                        </div>
                        <div class="designation">
                            President, RoofMath
                        </div>
                    </div>
                    <div class="aboutUsCol" [ngClass]="{'col-12': isMobileDevice,'col-10': !isMobileDevice}">
                        <div class="roofmathContentPara aboutUsContent">
                            Roofmath, Inc. was founded in 2018 to address the needs of roofers and installers for high-quality roof schematics delivered within a very short time (few minutes in most cases) with reasonable prices. Roofmath's technology delivers the fastest turnaround time for a roof report in the industry. We also deliver the 3D models on which the roof reports are based. 
                        </div>
                        <div class="roofmathContentPara aboutUsContent">
                            While Roofmath, Inc. was founded in 2018, the founder of the company, Prashant Devdhar, has been working in this field since 2002. Prashant is a co-inventor of one of the earliest geocoding patents. Prashant was also a co-founder of precigeo. Precigeo was the first company to commercially market roof schematics. Precigeo was featured on front page of the Business Section of Wall Street Journal in 2007. Precigeo.com ™ is a sister property of Roofmath, Inc.
                        </div>
                        <div class="roofmathContentPara aboutUsContent">
                            Prashant Devdhar is an accomplished Software Engineer with decades of experience in developing highly complex software. Prashant was a co-founder and VP, Engineering at precigeo - a pioneering company in aerial/satellite-based feature extraction, and databases. Prashant is a co-inventor of one of the earliest geocoding patents. Prashant founded Roofmath, Inc. with a vision to deliver highly accurate and reasonably priced roof reports to our customers with the fastest turnaround possible. Roofmath's cutting-edge technology now can generate most reports in minutes.
                        </div>
                    </div>
                </div>
            </div>
            <div class="roofmathContentRow">
                <div class="roofmathContent">
                    <div class="roofmathContentTitle">
                        We are looking for Talent!
                    </div>
                </div>
                <div class="roofmathContent hiringRow">
                    <div class="roofmathContentPara hiringDescription">
                        Want to work with the fast moving roof schematic company? Then join us as we aim to make our team stronger. 
                        <br>Drop in your resume at <a class="mailLink" href="mailto:cs@roofmath.com">cs@roofmath.com</a>
                    </div>
                    <div class="roofmathContentPara hiringOpening">
                        Senior Software Engineer (Machine Learning)
                    </div>
                    <div class="roofmathContentPara hiringOpening">
                        Dev/Ops Engineer
                    </div>
                    <div class="roofmathContentPara hiringOpening">
                        SDETs and QA Leads
                    </div>
                    <div class="roofmathContentPara hiringOpening">
                        GIS Analysts
                    </div>
                </div>
            </div>
            <div class="roofmathContentRow alternateBackgroundRow">
                <div class="col-12 roofmathContent">
                    <div class="roofmathContentTitle">
                        Let's<br><span class="highlightRoofmath">Connect</span>
                    </div>
                    <div class="roofmathContentPara">
                        Email us with any questions or inquiries and we would be happy to answer your questions or set up a business meeting with you.
                    </div>
                </div>
                <div class="roofmathContent contactRow">
                    <div class="roofmathContentPara">
                        +1 800 413 5555
                    </div>
                    <div class="roofmathContentPara">
                        <a class="mailLink" href="mailto:cs@roofmath.com">cs@roofmath.com</a>
                    </div>
                    <div class="roofmathContentPara">
                        Cupertino, CA 95014, USA
                    </div>
                    <!-- <div class="roofmathContentPara">
                        <a href="www.precigeo.com" target="_blank">www.precigeo.com</a>
                    </div> -->
                </div>
            </div>
        <!-- </div> -->
    </div>
</div>