import { Injectable } from '@angular/core';
import { CORRELATION_TYPE, getCorrelationId } from '@roofmath/models/rm-resources.model';
import { RMWorkOrder, RMPlate, RMPlatePoint, RMCorrelation, areCorrelationsEqual } from '@roofmath/models/workorders/rm-workorder.model';
import { Graph, GraphUtil, MutableUnweightedGraph } from 'graphs-for-js'
import { createNewCorrelation } from './rm-workorders.service';

@Injectable({
  providedIn: 'root'
})
export class RMCorrelationsService {

  private correlationGraph:MutableUnweightedGraph<GraphNode, never>;

  constructor() {
    this.reset();
  }

  reset() {
    this.correlationGraph = new Graph<GraphNode, never>().noKey().undirected.weighted();
  }

  initializeCorrelations(workOrder:RMWorkOrder) {
    this.reset();
    workOrder.correlations.forEach(correlation => {
      if(correlation.correlationType == CORRELATION_TYPE.OPERATOR) {
        this.addToCorrelationGraph(workOrder,correlation);
      }
    });
  }

  getIndexOfGraphNode(graphNode:GraphNode) {
    let adjacencyMatrix = GraphUtil.toAdjacencyMatrix(this.correlationGraph);
    let index = -1;
    for(let pair of adjacencyMatrix.nodeIndexPairs) {
      if(this.areTwoGraphNodesEqual(pair.node,graphNode)) {
        index = pair.index;
        break;
      }
    }
    return index;
  }

  getGraphNodeByIndex(index) {
    let adjacencyMatrix = GraphUtil.toAdjacencyMatrix(this.correlationGraph);
    let graphNode = adjacencyMatrix.indexToNode[index];
    return graphNode;
  }

  private computeAllReachableNodesFromNode(workOrder,graphNode:GraphNode, sourceNode:GraphNode):GraphNode[] {
    let index = this.getIndexOfGraphNode(graphNode);
    let reachableNodes = [];
    if(index >= 0) {
      let matrix = GraphUtil.toAdjacencyMatrix(this.correlationGraph).matrix[index];
      for(let i=0;i<matrix.length;i++) {
        if(matrix[i] == true && i!= index) {
          let node = this.getGraphNodeByIndex(i);
          if(!this.areTwoGraphNodesEqual(node,sourceNode)) {
            let reachables = this.computeAllReachableNodesFromNode(workOrder,node,graphNode);
            reachables.forEach(tempNode => {
              reachableNodes.push(tempNode);
            });
            reachableNodes.push(node);
          }
        }
      }
    }
    console.log("reachableNodes::",reachableNodes);
    return reachableNodes;
  }  

  getAllReachableNodesFromNode(workOrder,graphNode:GraphNode):GraphNode[] {
    let reachableNodes = this.computeAllReachableNodesFromNode(workOrder,graphNode,graphNode);
    return reachableNodes;
  }

  isValidCorrelation(workOrder:RMWorkOrder,pointOne:RMPlatePoint,pointTwo:RMPlatePoint) {
    console.log("isValidCorrelation Entering::",new Date());
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    let reachableNodes1 = this.getAllReachableNodesFromNode(workOrder,graphNode1);
    let reachableNodes2 = this.getAllReachableNodesFromNode(workOrder,graphNode2);
    for(let node1 of reachableNodes1) {
      for(let node2 of reachableNodes2) {
        if(node1.plateId == node2.plateId) {
          console.log("isValidCorrelation Exiting false::",new Date());
          return false;
        }
      }
    }
    console.log("isValidCorrelation Exiting true::",new Date());
    return true;
  }

  getAllCorrelationsForPoint(workOrder:RMWorkOrder,point:RMPlatePoint) {
    console.log("getAllCorrelationsForPoint Entering::",Date());
    // let correlations = this.getAllCorrelations(workOrder).filter(correlation => {
    //   return (this.areTwoPointsEqual(point,correlation.pointOne) || this.areTwoPointsEqual(point,correlation.pointTwo));
    // });
    let correlations:RMCorrelation[] = [];
    let graphNode1 = new GraphNode(point);
    let outgoingEdges = this.correlationGraph.outgoingEdgesOf(graphNode1);
    outgoingEdges.forEach(pointNode2 => {
      let pointObj2 = pointNode2.target;//GraphNode.parseStringCombination(workOrder,pointNode2);
      let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,point.plateId,point.pointId);
      let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,pointObj2.plateId,pointObj2.pointId);
      let tempCorrelationObj = this.createCorrelationObj(workOrder,pointOne, pointTwo);
      if(tempCorrelationObj != undefined && !this.isAlreadyAddedCorrelationCombination(correlations,pointOne,pointTwo)) {
        correlations.push(tempCorrelationObj);
      }
    })
    console.log("getAllCorrelationsForPoint correlations::",correlations);
    console.log("getAllCorrelationsForPoint Exiting::",Date());
    return correlations;
  }

  // initializeCorrelations(workOrder:RMWorkOrder):Promise<RMWorkOrder> {
  //   return new Promise((resolve,reject) => {
  //     this.reset();
  //     let tempCorrelations:RMCorrelation[] = [];
  //     let promises = [];
  //     workOrder.correlations.forEach(correlation => {
  //       let addPromise = this.addCorrelation(workOrder,correlation)
  //       .then(correlation => { 
  //         tempCorrelations.push(correlation);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //       promises.push(addPromise);
  //     });
  //     Promise.all(promises)
  //     .then(() => {
  //       workOrder.correlations = tempCorrelations;
  //       resolve(workOrder);
  //     })
  //     .catch(err => {
  //       reject(err);
  //     })
  //   })
  // }

  addToCorrelationGraph(workOrder:RMWorkOrder,correlation:RMCorrelation) {
    let plate1Id = correlation.pointOne.plate != undefined ? correlation.pointOne.plate.plateId:correlation.pointOne.plateId;
    let plate2Id = correlation.pointTwo.plate != undefined ? correlation.pointTwo.plate.plateId:correlation.pointTwo.plateId;
    let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate1Id,correlation.pointOne.pointId);
    let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate2Id,correlation.pointTwo.pointId);
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    this.correlationGraph.insert(graphNode1);
    this.correlationGraph.insert(graphNode2);
    if(correlation.correlationType == CORRELATION_TYPE.OPERATOR) {
      this.correlationGraph.connect(graphNode1,graphNode2,1);
    }
  }

  addCorrelation(workOrder:RMWorkOrder,pointOne:RMPlatePoint,pointTwo:RMPlatePoint):Number {
    console.log("addCorrelation Entering::",new Date());
    if(this.getPathBetweenTwoPoints(pointOne, pointTwo) == undefined) {
      if(this.isValidCorrelation(workOrder,pointOne,pointTwo)) {
        let graphNode1 = new GraphNode(pointOne);
        let graphNode2 = new GraphNode(pointTwo);
        this.correlationGraph.insert(graphNode1);
        this.correlationGraph.insert(graphNode2);
        this.correlationGraph.connect(graphNode1,graphNode2,1);
        workOrder.correlations = this.getAllCorrelations(workOrder);
        console.log("addCorrelation Exiting 1::",new Date());
        return 1;
      }
      else {
        console.log("addCorrelation Exiting -1::",new Date());
        return -1;
      }
    }
  }

  removeCorrelation(workOrder:RMWorkOrder,correlation:RMCorrelation) {
    let plate1Id = correlation.pointOne.plate != undefined ? correlation.pointOne.plate.plateId:correlation.pointOne.plateId;
    let plate2Id = correlation.pointTwo.plate != undefined ? correlation.pointTwo.plate.plateId:correlation.pointTwo.plateId;
    let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate1Id,correlation.pointOne.pointId);
    let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate2Id,correlation.pointTwo.pointId);
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    let isRemoved = this.correlationGraph.disconnect(graphNode1,graphNode2);
    // this.correlationGraph.disconnect(graphNode2,graphNode1);
    // let tempGraph = new Graph<string, never>().noKey().undirected.unweighted();
    // this.correlationGraph.nodes().forEach(node => {
    //   tempGraph.insert(node);
    // });
    // this.correlationGraph.edges().forEach(edge => {
    //   let isValidEdge = false;
    //   if(edge.source == graphNode1.getStringCombination() && edge.target == graphNode2.getStringCombination()) {
    //     isValidEdge = false;
    //   }
    //   else if(edge.source == graphNode2.getStringCombination() && edge.target == graphNode1.getStringCombination()) {
    //     isValidEdge = false;
    //   }
    //   else {
    //     isValidEdge = true;
    //   }
    //   if(isValidEdge) {
    //     tempGraph.connect(graphNode1.getStringCombination(),graphNode2.getStringCombination());
    //   }
    // });
    // this.correlationGraph = tempGraph;
    if(isRemoved) {
      // workOrder.correlations = [];
      workOrder.correlations = this.getAllCorrelations(workOrder);
    }
  }

  getPathBetweenTwoPoints(pointOne:RMPlatePoint,pointTwo:RMPlatePoint) {
    // console.log("getPathBetweenTwoPoints pointOne::",pointOne);
    // console.log("getPathBetweenTwoPoints pointTwo::",pointTwo);
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    let pathObj = GraphUtil.findShortestPath(this.correlationGraph,graphNode1,graphNode2);
    // console.log("pathObj::",pathObj);
    if(pathObj.pathSize > -1) {
        return pathObj.path;
    }
    return undefined;
  }

  isPointCorrelated(point:RMPlatePoint) {
    let graphNode = new GraphNode(point);
    return this.correlationGraph.contains(graphNode);
  }

  handleDeletePlate(workOrder:RMWorkOrder, plate:RMPlate) {
    let self = this;
    plate.points.forEach(point => {
      console.log("Deleting point::",point);
      self.handleDeletePoint(workOrder,point);
    });
    // workOrder.correlations = [];
    // workOrder.correlations = this.getAllCorrelations(workOrder);
  }

  handleDeletePoint(workOrder:RMWorkOrder,platePoint:RMPlatePoint) {
    let graphNode1 = new GraphNode(platePoint);
    let numRemoved = this.correlationGraph.remove(graphNode1);
    if(numRemoved >= 1) {
      // let adjacentPoints = this.getAdjacentNodesOfPoint(workOrder,platePoint);
      // adjacentPoints.forEach(point => {
      //   let plateId = point.plate != undefined ? point.plate.plateId:point.plateId;
      //   let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plateId,point.pointId);
      //   let graphNode2 = new GraphNode(pointOne);
      //   this.correlationGraph.disconnect(graphNode1,graphNode2);
      //   this.correlationGraph.disconnect(graphNode2,graphNode1);
      // })
      // workOrder.correlations = [];
      workOrder.correlations = this.getAllCorrelations(workOrder);
      return workOrder.correlations;
      // console.log("handleDeletePoint::",workOrder.correlations);
    }
  }

  getAllCorrelationsForTwoPlates(workOrder:RMWorkOrder,plate1:RMPlate, plate2:RMPlate) {
    let matchedCorrelations = [];
    if(plate1 != undefined && plate2 != undefined) {
      plate1.points.forEach(point1 => {
        let graphNode1:GraphNode = new GraphNode(point1);
        if(this.correlationGraph.contains(graphNode1)) {
          plate2.points.forEach(point2 => {
            let tempCorrelationObj = this.createCorrelationObj(workOrder,point1, point2);
            if(tempCorrelationObj != undefined) {
              matchedCorrelations.push(tempCorrelationObj);
            }
          })
        }
      })
    }
    return matchedCorrelations;
  }

  // getWritableCorrelations(workOrder:RMWorkOrder):RMCorrelation[] {
  //   let correlations:RMCorrelation[] = [];
  //   this.getAllCorrelations(workOrder).forEach(edge => {
  //     let pointObj1:GraphNode = edge.source;
  //     let pointObj2:GraphNode = edge.target;
  //     let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,pointObj1.plateId,pointObj1.pointId);
  //     let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,pointObj2.plateId,pointObj2.pointId);
  //     let tempCorrelationObj = this.createCorrelationObj(pointOne, pointTwo);
  //     if(tempCorrelationObj != undefined && tempCorrelationObj.correlationType == CORRELATION_TYPE.OPERATOR) {
  //       correlations.push(tempCorrelationObj);
  //     }
  //   });
  //   return correlations;
  // }

  getAllCorrelations(workOrder:RMWorkOrder):RMCorrelation[] {
    console.log("getAllCorrelations Entering::",new Date());
    let correlations:RMCorrelation[] = [];
    this.correlationGraph.nodes().forEach(pointNode1 => {
      let pointObj1 = pointNode1;//GraphNode.parseStringCombination(workOrder,pointNode1);
      let tempNodes = this.correlationGraph.outgoingEdgesOf(pointNode1);
      // let tempNodes = this.correlationGraph.nodes().filter(tempGraphNode => {
      //   let tempNode = tempGraphNode;//GraphNode.parseStringCombination(workOrder,tempGraphNode); 
      //   return !(tempNode.pointId == pointObj1.pointId && tempNode.plateId == pointObj1.plateId)
      // })
      tempNodes.forEach(tempGraphNode => {
        let pointObj2 = tempGraphNode.target;//GraphNode.parseStringCombination(workOrder,pointNode2);
        let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,pointObj1.plateId,pointObj1.pointId);
        let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,pointObj2.plateId,pointObj2.pointId);
        if(!this.isAlreadyAddedCorrelationCombination(correlations,pointOne,pointTwo)) {
          let tempCorrelationObj = this.createCorrelationObj(workOrder,pointOne, pointTwo);
          correlations.push(tempCorrelationObj);
        }
      })
    });
    console.log("getAllCorrelations Exiting::",new Date());
    return correlations;
  }

  // getAdjacentNodesOfPoint(workOrder:RMWorkOrder,point:RMPlatePoint) {
  //   let plateId = point.plate != undefined ? point.plate.plateId:point.plateId;
  //   let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plateId,point.pointId);
  //   let graphNode1 = new GraphNode(pointOne);
  //   let adjacentPoints:RMPlatePoint[] = [];
  //   this.correlationGraph.incomingEdgesOf(graphNode1)
  //   .forEach(edge => {
  //     let adjacentNode:GraphNode = undefined;
  //     if(edge.source.pointId == pointOne.pointId && edge.source.plateId == plateId) {
  //       adjacentNode = edge.target;
  //     }
  //     else if(edge.target.pointId == pointOne.pointId && edge.target.plateId == plateId) {
  //       adjacentNode = edge.source;
  //     }
  //     if(adjacentNode != undefined) {
  //       let adjacentIndex = adjacentPoints.findIndex(point => {
  //         return point.pointId == adjacentNode.pointId && point.plateId == adjacentNode.plateId;
  //       })
  //       if(adjacentIndex == -1) {
  //         adjacentPoints.push(this.getPointByPlateAndPointId(workOrder,adjacentNode.plateId,adjacentNode.pointId));
  //       }
  //     }
  //   });
  //   this.correlationGraph.outgoingEdgesOf(graphNode1)
  //   .forEach(edge => {
  //     let adjacentNode:GraphNode = undefined;
  //     if(edge.source.pointId == pointOne.pointId && edge.source.plateId == plateId) {
  //       adjacentNode = edge.target;
  //     }
  //     else if(edge.target.pointId == pointOne.pointId && edge.target.plateId == plateId) {
  //       adjacentNode = edge.source;
  //     }
  //     if(adjacentNode != undefined) {
  //       let adjacentIndex = adjacentPoints.findIndex(point => {
  //         return point.pointId == adjacentNode.pointId && point.plateId == adjacentNode.plateId;
  //       })
  //       if(adjacentIndex == -1) {
  //         adjacentPoints.push(this.getPointByPlateAndPointId(workOrder,adjacentNode.plateId,adjacentNode.pointId));
  //       }
  //     }
  //   });
  //   console.log("adjacentPoints::",adjacentPoints);
  //   return adjacentPoints;
  // }

  isAlreadyAddedCorrelationCombination(correlationCollection,pointOne, pointTwo) {
    for(let correlationObj of correlationCollection) {
      if(correlationObj.pointOneId == pointOne.pointId && correlationObj.pointTwoId == pointTwo.pointId) {
        return true;
      }
      if(correlationObj.pointOneId == pointTwo.pointId && correlationObj.pointTwoId == pointOne.pointId) {
        return true;
      }
    }
    return false;
  }
  
  createCorrelationObj(workOrder:RMWorkOrder,pointOne:RMPlatePoint, pointTwo:RMPlatePoint):RMCorrelation {
    let path = this.getPathBetweenTwoPoints(pointOne,pointTwo);
    let tempCorrelationObj = undefined;
    if(path != undefined) {
      tempCorrelationObj = createNewCorrelation(pointOne,pointTwo);
      let correlationObjExists = false;
    
    // tempCorrelationObj = new RMCorrelation();
    // tempCorrelationObj.pointOne = pointOne;
    // tempCorrelationObj.pointOneId = pointOne.pointId;
    // tempCorrelationObj.pointTwo = pointTwo;
    // tempCorrelationObj.pointTwoId = pointTwo.pointId;
    
      for(let correlation of workOrder.correlations) {
        if(areCorrelationsEqual(correlation,tempCorrelationObj)) {
          correlationObjExists = true;
          tempCorrelationObj = correlation;
          break;
        }
      }
      if(!correlationObjExists) {
        if(path.length > 2){
          tempCorrelationObj.correlationType = CORRELATION_TYPE.SYSTEM;
        }
        else {
          tempCorrelationObj.correlationType = CORRELATION_TYPE.OPERATOR;
        }
      }
    }
    return tempCorrelationObj;
  }

  getPointByPlateAndPointId(workOrder:RMWorkOrder,plateId:string, pointId:string) {
    let point = undefined;
    let plate = workOrder.plates.filter(plate => {
      return plate.plateId == plateId;
    })[0];
    if(plate != undefined) {
      point = plate.points.filter(tempPoint => {
        return tempPoint.pointId == pointId;
      })[0];
    }
    return point;
  }

  areTwoGraphNodesEqual(graphNode1:GraphNode,graphNode2:GraphNode) {
    return (graphNode1.plateId == graphNode2.plateId && graphNode1.pointId == graphNode2.pointId);
  }

  areTwoPointsEqual(point1:RMPlatePoint,point2:RMPlatePoint) {
    let plate1Id = point1.plateId == undefined ? point1.plate.plateId : point1.plateId;
    let plate2Id = point2.plateId == undefined ? point2.plate.plateId : point2.plateId;
    return (plate1Id == plate2Id && point1.pointId == point2.pointId);
  }
}

class GraphNode {
  pointId:string;
  plateId:string;
  // static platePointConnector = "~";

  constructor(platePoint:RMPlatePoint) {
    this.pointId = platePoint.pointId;
    if(platePoint.plate != undefined) {
      this.plateId = platePoint.plate.plateId;
    }
    else {
      this.plateId = platePoint.plateId;
    }
  }

  // getStringCombination() {
  //   return this.plateId + GraphNode.platePointConnector + this.pointId;
  // }

  // static parseStringCombination(workOrder:RMWorkOrder,stringCombination:string) {
  //   let plateId = stringCombination.split(GraphNode.platePointConnector)[0];
  //   let pointId = stringCombination.split(GraphNode.platePointConnector)[1];
  //   return new GraphNode(getPointByPlateAndPointId(workOrder,plateId,pointId));
  // }
}
