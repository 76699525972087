<div class="container-fluid">
    <div class="row mt-1">
        <label for="">Roof Type</label>
        <rm-roof-type (roofTypeSelected)="onRoofTypeChanged($event)"></rm-roof-type>
    </div>
    <div class="row mt-10">
        <!-- <label for="">Take Down Current Roof</label>
        <p-radioButton name="bomTakeDownRoof" value="true" label="Yes" [(ngModel)]="bomTakeDownRoof"></p-radioButton>
        <p-radioButton name="bomTakeDownRoof" value="false" label="No" [(ngModel)]="bomTakeDownRoof"></p-radioButton> -->
    </div>
    <div class="row mt-8">
        <button class="btn btn-primary mt-2 updateButton" (click)="update()" [disabled]="bomRoofTypeName == null">Update</button>
    </div>
</div>
