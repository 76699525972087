import { getIDComponent } from "./rm-resources.model";

export class RMUser {
    public userId:string = "";
    public firstName:string = "";
    public lastName:string = "";
    public emailId:string = "";
    public preferredCurrency:string = "";
    public userName:string = "";
    public password:string = "";
    public countryCode:string = "";
    public phoneNumber:string = "";
    public extensionCode:string = "";
    public role:AUTH_ROLE;
    public isActive:boolean;
    public isInternal:boolean;
    public paymentGatewayUser:any;
    // public discountPercentage:number;
    public color:string;
    public confirmPassword:string;
    public permittedActions:[];
    public userSettings:RMUserSettings;
    public salesUserId:string;

    public fullName:string = "";
}

export class RMUserSettings {
    public userSettingsId:string = "";
    public userId:string = "";
    public numFreeWorkOrders:number
    public numDiscountedWorkOrders:number;
    public discountPercentage:number;
    public lastModifiedDate;
}

export class RMUserRequest {
	public userName:string;
	public password:string;
}

export class RMUserFilterRequest {
	maxRowsPerPage:number;
    currPage:number;
    filterRoles:AUTH_ROLE[];
    filterText:String;
    sortCol:String;
    sortDir:String;
}

export class RMReportDTO {
	  path:string;
    fileDownloadUri:string;
    fileName:string;
}

export function createNewUser(userName,firstName,lastName,emailId,preferredCurrency,password,countryCode,phoneNumber,extensionCode,
    role,discountPercentage,isInternal,color,
    numDiscountedWorkOrders,numFreeWorkOrders) {
    let tempUser = new RMUser();
    tempUser.userId = getUserId();
    tempUser.firstName = firstName;
    tempUser.lastName = lastName;
    tempUser.userName = userName;
    tempUser.emailId = emailId;
    tempUser.preferredCurrency = preferredCurrency;
    tempUser.password = password;
    tempUser.countryCode = countryCode;
    tempUser.phoneNumber = phoneNumber;
    tempUser.extensionCode = extensionCode;
    tempUser.role = role;
    tempUser.confirmPassword = password;
    tempUser.isInternal = isInternal;
    tempUser.color = color;
    let tempUserSettings = createNewUserSettings(tempUser.userId,numDiscountedWorkOrders,numFreeWorkOrders,discountPercentage)
    tempUser.userSettings = tempUserSettings;
    return tempUser;
}

export function createNewUserSettings(userId,numDiscountedWorkOrders,numFreeWorkOrders,discountPercentage) {
    let tempUserSettings = new RMUserSettings();
    tempUserSettings.userSettingsId = getUserSettingsId();
    tempUserSettings.userId = userId;
    tempUserSettings.numDiscountedWorkOrders = numDiscountedWorkOrders;
    tempUserSettings.numFreeWorkOrders = numFreeWorkOrders;
    tempUserSettings.discountPercentage = discountPercentage;
    return tempUserSettings;
}

export enum RMACTION {
    VIEW_WORKORDER = "VIEW_WORKORDER",
    VIEW_WORKORDER_LIST = "VIEW_WORKORDER_LIST",
	CREATE_WORKORDER = "CREATE_WORKORDER",
    OPERATE_WORKORDER = "OPERATE_WORKORDER",
    DELETE_WORKORDER = "DELETE_WORKORDER",
    CANCEL_WORKORDER = "CANCEL_WORKORDER",
    REOPEN_WORKORDER = "REOPEN_WORKORDER",
    MAKE_PAYMENT = "MAKE_PAYMENT",

    VIEW_REPORT = "VIEW_REPORT",
    VIEW_BOM = "VIEW_BOM",
    VIEW_3DMODEL = "VIEW_3DMODEL",
    VIEW_METADATA = "VIEW_METADATA",
    FLAG_WORKORDER = "FLAG_WORKORDER",
    REJECT_WORKORDER = "REJECT_WORKORDER",
    VERIFY_REPORT = "VERIFY_REPORT",
    VERIFY_BOM = "VERIFY_BOM",
    VERIFY_3DMODEL = "VERIFY_3DMODEL",
    REJECT_REPORT = "REJECT_REPORT",
    REJECT_BOM = "REJECT_BOM",
    REJECT_3DMODEL = "REJECT_3DMODEL",

    CREATE_USER = "CREATE_USER",
    MODIFY_USER = "MODIFY_USER",

    SHOW_WORKORDER_LIST_TAB = "SHOW_WORKORDER_LIST_TAB",
    SHOW_ADMINISTRATOR_TAB = "SHOW_ADMINISTRATOR_TAB",
    PAGES_LIST_WORKORDER = "list",
    PAGES_CREATE_WORKORDER = "newWorkOrder",
    PAGES_ADMIN = "admin",

    PAGES_AVAILABILITY = "PAGES_AVAILABILITY",
    PAGES_CONTACTUS = "PAGES_CONTACTUS",
    PAGES_PAYMENT = "PAGES_PAYMENT",

    PAGES_REPORTS = "PAGES_REPORTS",
    PAGES_PRODUCTS = "PAGES_PRODUCTS",
    PAGES_USER_MANAGEMENT = "PAGES_USER_MANAGEMENT",

    UPLOAD_ADDRESS_POOL = "UPLOAD_ADDRESS_POOL",
    PAGES_PROFILE = "PAGES_PROFILE",
    PAGES_ADMIN_REPORTS = "PAGES_ADMIN_REPORTS",
    PAGES_SALES_REPORTS = "PAGES_SALES_REPORTS",
    PAGES_ROOFER_MANAGEMENT = "PAGES_ROOFER_MANAGEMENT",
    PAGES_ROOFER_SALES_LINK = "PAGES_ROOFER_SALES_LINK"
}

export function getUserId() {
    return getIDComponent();
}

export function getUserSettingsId() {
    return getIDComponent();
}

export function getUserScheduleId() {
    return getIDComponent();
}

export enum AUTH_ROLE {
    SUPER_ADMIN = "SUPER_ADMIN",
	ADMINISTRATOR = "ADMINISTRATOR",
	OPERATOR = "OPERATOR",
	ROOFER = "ROOFER",
	ADHOC_USER = "ADHOC_USER",
	SALES = "SALES"
}

export class RMUserRole {
    readonly code:AUTH_ROLE;
    readonly label:string;

    constructor(code,label,) {
        this.code = code;
        this.label = label;
    }
}

export function getRoleObjectFromCode(roleName:string) {
    if(roleName == "") {
        return new RMUserRole(AUTH_ROLE.ROOFER,"Roofer");
    }
    switch (roleName) {
        case AUTH_ROLE.SUPER_ADMIN:
            return new RMUserRole(AUTH_ROLE.SUPER_ADMIN,"Super Admin");

        case AUTH_ROLE.ADMINISTRATOR:
            return new RMUserRole(AUTH_ROLE.ADMINISTRATOR,"Administrator");

        case AUTH_ROLE.OPERATOR:
            return new RMUserRole(AUTH_ROLE.OPERATOR,"Operator");

        case AUTH_ROLE.ROOFER:
            return new RMUserRole(AUTH_ROLE.ROOFER,"Roofer");

        case AUTH_ROLE.ADHOC_USER:
            return new RMUserRole(AUTH_ROLE.ADHOC_USER,"ADHOC_USER");

        case AUTH_ROLE.SALES:
            return new RMUserRole(AUTH_ROLE.SALES,"SALES");

        default:
            return new RMUserRole(AUTH_ROLE.ROOFER,"Roofer");
    }
}

export class RMUserSchedule {
	userScheduleId:string = "";
	userId:string = "";
	createdDate:Date;
	scheduleDate:Date;
	scheduleStartTime:Date;
	scheduleEndTime:Date;
}

export class RMUserScheduleWrapper {
   userSchedule: RMUserSchedule;
   operator: RMUser;
}

export function createNewUserSchedule(userId,scheduleDate,scheduleStartTime,scheduleEndTime) {
    let tempUserSchedule = new RMUserSchedule();
    tempUserSchedule.userScheduleId = getUserScheduleId();
    tempUserSchedule.userId = userId;
    tempUserSchedule.scheduleDate = scheduleDate;
    tempUserSchedule.scheduleStartTime = scheduleStartTime.toISOString();
    tempUserSchedule.scheduleEndTime = scheduleEndTime.toISOString();
    return tempUserSchedule;
}
