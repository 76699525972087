// Library
import { Injectable } from '@angular/core';

import { RMCONSTANTS } from '@roofmath/core/constants/rm-constants';

@Injectable({
    providedIn: 'root'
})
export class RMLogService {

    constructor(private CONSTANTS: RMCONSTANTS) {}

    log(message?: any, ...optionalParams: any[]): void {
        if (console && console.log) {
            console.log(message, ...optionalParams);
        }
    }

    warn(message?: any, ...optionalParams: any[]): void {
        // tslint:disable-next-line:no-console
        if (console && console.warn) {
            // tslint:disable-next-line:no-console
            console.warn(message, ...optionalParams);
        } else {
            this.log(message, ...optionalParams);
        }
    }

    error(message?: any, ...optionalParams: any[]): void {
        // tslint:disable-next-line:no-console
        if (console && console.error) {
            // tslint:disable-next-line:no-console
            console.error(message, ...optionalParams);
        } else {
            this.log(message, ...optionalParams);
        }
    }

    debug(message?: any, ...optionalParams: any[]): void {
        // tslint:disable-next-line:no-console
        if (console && console.debug) {
            // tslint:disable-next-line:no-console
            console.debug(message, ...optionalParams);
        } else {
            this.log(message, ...optionalParams);
        }
    }

    trace(message?: any, ...optionalParams: any[]): void {
        // tslint:disable-next-line:no-console
        if (console && console.trace) {
            // tslint:disable-next-line:no-console
            console.trace(message, ...optionalParams);
        } else {
            this.log(message, ...optionalParams);
        }
    }

}
