import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { RMAbstractService } from './rm-abstract.service';

import { RMRestapi } from '@roofmath/models/restapi/rm-restapi.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RM_RESTAPI_HREF } from '@roofmath/models/rm-resources.model';
import { RMAuthenticationService } from './rm-authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RMRestapiService extends RMAbstractService {

  constructor(private httpClient: HttpClient, private authService:RMAuthenticationService,private sanitizer:DomSanitizer,private router: Router) {
    super();
  }

  private getHttpOptions() {
    return this.authService.getHttpOptions();
  }

  getRestapi(httpParams?: HttpParams): Observable<RMRestapi> {
    if (!httpParams) {
      httpParams = new HttpParams();
    }
    return this.httpClient.get<RMRestapi>(RM_RESTAPI_HREF, { params: httpParams }).pipe(
      catchError(this.handleError)
    );
  }

  handleUnauthorizedRequest() {
    if(this.authService.getSignedInUserId() != undefined) {
      alert("You seemed to be signed out.. Please login again");
      this.authService.signOut();
    }
  }

  makeSecuredPostCall(url:string,body:any):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      this.httpClient.post<any>(url,body as any,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
        else {
          reject(err);
        }
      });
    })
  }

  makeSecuredPostTextCall(url:string,body:any):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      httpOptions["responseType"] = 'text';
      this.httpClient.post<any>(url,body as any,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      });
    })
  }

  makeSecuredPutCall(url:string,body:any):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      this.httpClient.put<any>(url,body as any,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      });
    })
  }

  makeSecuredDeleteCall(url:string):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      this.httpClient.delete<any>(url,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      });
    })
  }

  makeSecuredGetCall(url:string):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      this.httpClient.get<any>(url,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      })
    })
  }

  makeSecuredGetTextCall(url:string):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      httpOptions["responseType"] = 'text';
      this.httpClient.get<any>(url,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      });
    })
  }

  makeSecuredGetImageCall(url:string):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      httpOptions["responseType"] = 'arraybuffer';
      this.httpClient.get<any>(url,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      });
    })
  }

  makeSecuredGetFileCall(url:string):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      let httpOptions = this.getHttpOptions();
      httpOptions["responseType"] = 'blob';
      this.httpClient.get<any>(url,httpOptions as any)
      .subscribe((response:any) => {
        resolve(response);
      },err => {
        if(err.status == HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedRequest();
        }
      });
    })
  }

  makeGetCall(url:string):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      this.httpClient.get<any>(url)
      .subscribe((response:any) => {
        resolve(response);
      });
    })
  }

  makePostCall(url:string,body:any):Promise<any> {
    return new Promise<any>((resolve,reject) =>{
      this.httpClient.post<any>(url,body as any)
      .subscribe((response:any) => {
        resolve(response);
      });
    })
  }

  openPdfURLInNewTab(url:string,name) {
    this.makeSecuredGetFileCall(url)
    .then(data => {
      let blob = data;
      const fileURL = URL.createObjectURL(blob);
      console.log("fileURL::",fileURL);
      var newWindow = window.open(fileURL, "_blank");
      setTimeout(function(){ newWindow.document.title = name; }, 1000);
    })
  }

  openBlobInNewTab(blob:Blob,name) {
    const fileURL = URL.createObjectURL(blob);
    console.log("fileURL::",fileURL);
    var newWindow = window.open(fileURL, "_blank");
    setTimeout(function(){ newWindow.document.title = name; }, 1000);
  }
}
