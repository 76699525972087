import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { RMACTION } from './models/rm-auth.model';
import { RMWorkOrder, RMWorkOrderGroup } from './models/workorders/rm-workorder.model';
import { RMAuthorizationService } from './restapi/rm-authorization.service';
import { RMWorkOrdersService } from './restapi/rm-workorders.service';

@Directive({
  selector: '[rmCanActions]'
})
export class RMCanActions implements OnInit {

  @Input() rmCanActions: RMACTION[] = [];
  @Input() rmWorkOrder:RMWorkOrder;
  @Input() rmWorkOrderGroup:RMWorkOrderGroup;

  constructor(private element: ElementRef,
    private authorizationService:RMAuthorizationService,
    private workOrderService:RMWorkOrdersService) { }

  ngOnInit(): void {
    for(let action of this.rmCanActions) {
      let isActionAllowed = this.authorizationService.canPerformAction(action);
      if(isActionAllowed) {
        if(this.rmWorkOrder != undefined) {
          isActionAllowed = this.workOrderService.canWorkOrderPerformAction(action, this.rmWorkOrder);
        }
        if(this.rmWorkOrderGroup != undefined) {
          isActionAllowed = this.workOrderService.canWorkOrderGroupPerformAction(action, this.rmWorkOrderGroup);
        }
      }
      if(isActionAllowed) {
        // this.element.nativeElement.style.display = "inline-block";
        this.element.nativeElement.style.display = "";
        break;
      }
      if(!isActionAllowed) {
        this.element.nativeElement.style.display = "none";
      }
    }
  }
}