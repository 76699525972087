import { Injectable } from '@angular/core';
import { RMAbstractService } from '@roofmath/restapi/rm-abstract.service';
import { RMUser, RMUserFilterRequest, RMUserRequest, RMUserSchedule,RMUserScheduleWrapper,RMUserSettings } from '@roofmath/models/rm-auth.model';
import { RM_AUTHAPI_HREF, RM_REL_DELETE_USER_SCHEDULE, RM_REL_GET_ALL_FILTERED_USERS, RM_REL_GET_ALL_NON_ADMINS, RM_REL_GET_ALL_OPERATORS, RM_REL_GET_ALL_OPERATOR_SCHEDULES, RM_REL_GET_ALL_USERS, RM_REL_GET_CURRENT_USER_SETTINGS, RM_REL_GET_USER_DETAILS, RM_REL_GET_USER_SCHEDULE,
  RM_REL_GET_USER_SETTINGS,
  RM_REL_LINK_ROOFER_TO_SALES,
  RM_REL_SAVE_USER_SCHEDULE, RM_REL_UPDATE_USER, RM_REL_UPDATE_USER_PASSWORD ,RM_REL_CHECK_FOR_OVERLAPPING_SCHEDULES} from '@roofmath/models/rm-resources.model';
import { RMAuthenticationService } from './rm-authentication.service';
import { RMRestapiService } from './rm-restapi.service';

@Injectable({
  providedIn: 'root'
})
export class RMUserManagementService extends RMAbstractService {

  private currentUser:RMUser;
  private userAuthenticationSubscription;

  constructor(private restApiService: RMRestapiService,private authService:RMAuthenticationService) {
    super();
    this.currentUser = this.authService.getSignedInUser();
    this.userAuthenticationSubscription = this.authService.getUserAuthenticationSubscription();
    this.userAuthenticationSubscription.subscribe(user => {
      this.currentUser = this.authService.getSignedInUser();
    })
  }

  validateUserDetails(user:RMUser) {
    if(user.emailId.trim().length === 0) {
      return 'Must specify username';
    }
    if(user.phoneNumber.trim().length === 0) {
      return 'Must specify phoneNumber';
    }
    if(user.password.trim().length === 0) {
      return 'Must specify password';
    }
    if(user.preferredCurrency == undefined) {
      return 'Please select your preferred Currency';
    }
    if(user.firstName.trim().length === 0) {
      return 'Must specify First Name';
    }
    if(user.lastName.trim().length === 0) {
      return 'Must specify Last Name';
    }
    return null;
  }

  getUserDetails(userId:String): Promise<RMUser> {
    return new Promise<RMUser>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetCall(`${RM_AUTHAPI_HREF}/${RM_REL_GET_USER_DETAILS}?userId=${userId}`)
      // .pipe(
      //   catchError((err):any => {
      //     console.error("Error::",err);
      //     reject(err);
      //   })
      // );
      getRequest.then((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  getCurrentUserDetails(): Promise<RMUser> {
    let userId = this.currentUser.userId;
    return this.getUserDetails(userId);
  }

  getAllUsers(): Promise<RMUser[]> {
    return new Promise<RMUser[]>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetCall(RM_AUTHAPI_HREF + "/" + RM_REL_GET_ALL_USERS)
      // .pipe(
      //   catchError((err):any => {
      //     console.error("Error::",err);
      //     reject(err);
      //   })
      // );
      getRequest.then((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  getAllFilteredUsers(maxRowsPerPage,currPage, filterRoles,filterText,sortCol,sortDir): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let userFilterRequest:RMUserFilterRequest = new RMUserFilterRequest();
      userFilterRequest.maxRowsPerPage = maxRowsPerPage;
      userFilterRequest.currPage = currPage;
      userFilterRequest.filterText = filterText;
      if(filterRoles == undefined) {
        filterRoles = [];
      }
      userFilterRequest.filterRoles = filterRoles;
      userFilterRequest.sortCol = sortCol;
      userFilterRequest.sortDir = sortDir;
      let postRequest = this.restApiService.makeSecuredPostCall(RM_AUTHAPI_HREF + "/" + RM_REL_GET_ALL_FILTERED_USERS ,userFilterRequest)
      postRequest.then((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  getAllNonAdmins(): Promise<RMUser[]> {
    return new Promise<RMUser[]>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetCall(RM_AUTHAPI_HREF + "/" + RM_REL_GET_ALL_NON_ADMINS)
      // .pipe(
      //   catchError((err):any => {
      //     console.error("Error::",err);
      //     reject(err);
      //   })
      // );
      getRequest.then((dataUser: RMUser[]) => {
        if(dataUser == null) {
          dataUser = [];
        }
        dataUser.forEach(user => {
          user.fullName = user.firstName + " " + user.lastName;
        })
        resolve(dataUser);
      });
    })
  }

  updateUser(user:RMUser):Promise<RMUser> {
    return new Promise<RMUser>((resolve,reject) => {
      delete user.paymentGatewayUser;
      delete user.password;
      let postRequest = this.restApiService.makeSecuredPostCall(RM_AUTHAPI_HREF + "/" + RM_REL_UPDATE_USER,user as any)
      // .pipe(
      //   catchError((err):any => {
      //     console.error("Error::",err);
      //     reject(err);
      //   })
      // );
      postRequest.then((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  updateUserPassword(userName:string, password:string):Promise<RMUser> {
    return new Promise<RMUser>((resolve,reject) => {
      let userRequest:RMUserRequest = new RMUserRequest();
      userRequest.userName = userName;
      userRequest.password = password;

      let postRequest = this.restApiService.makeSecuredPostCall(RM_AUTHAPI_HREF + "/" + RM_REL_UPDATE_USER_PASSWORD,userRequest as any)
      // .pipe(
      //   catchError((err):any => {
      //     console.error("Error::",err);
      //     reject(err);
      //   })
      // );
      postRequest.then((dataUser: any) => {
        resolve(dataUser);
      });
    })
  }

  getUserDiscountPercentage() {
    let signedInUser = this.currentUser;
    let discountPercentage = 0;
    // if(signedInUser.discountPercentage != undefined || signedInUser.discountPercentage != null) {
    //   discountPercentage = signedInUser.discountPercentage;
    // }
    return discountPercentage;
  }

  getCurrentUserSettings():Promise<RMUserSettings> {
    return new Promise<RMUserSettings>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_AUTHAPI_HREF}/${RM_REL_GET_CURRENT_USER_SETTINGS}`)
      .then(dataUserSettings => {
        resolve(dataUserSettings);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  getUserSettings(user:RMUser):Promise<RMUserSettings> {
    return new Promise<RMUserSettings>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_AUTHAPI_HREF}/${RM_REL_GET_USER_SETTINGS}?userId=${user.userId}`)
      .then(dataUserSettings => {
        resolve(dataUserSettings);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  saveUserSchedules(userSchedules:RMUserSchedule[]): Promise<RMUserSchedule[]> {
    return this.restApiService.makeSecuredPostCall(`${RM_AUTHAPI_HREF}/${RM_REL_SAVE_USER_SCHEDULE}`,userSchedules);
    // return new Promise<RMUserSchedule>((resolve,reject) => {
    //   let postRequest = this.httpClient.post<RMUserSchedule>(`${RM_AUTHAPI_HREF}/${RM_REL_SAVE_USER_SCHEDULE}`, userSchedule as any)
    //   .pipe(
    //     catchError((err):any => {
    //       console.error("Error::",err);
    //       reject(err);
    //     })
    //   );
    //   postRequest.subscribe((dataUserSchedule: any) => {
    //     resolve(dataUserSchedule);
    //   });
    // })
  }

  deleteUserSchedule(userScheduleId:string): Promise<Boolean> {
    return this.restApiService.makeSecuredDeleteCall(`${RM_AUTHAPI_HREF}/${RM_REL_DELETE_USER_SCHEDULE}?userScheduleId=${userScheduleId}`);
    // return new Promise<RMUserSchedule>((resolve,reject) => {
    //   let postRequest = this.httpClient.post<RMUserSchedule>(`${RM_AUTHAPI_HREF}/${RM_REL_SAVE_USER_SCHEDULE}`, userSchedule as any)
    //   .pipe(
    //     catchError((err):any => {
    //       console.error("Error::",err);
    //       reject(err);
    //     })
    //   );
    //   postRequest.subscribe((dataUserSchedule: any) => {
    //     resolve(dataUserSchedule);
    //   });
    // })
  }

  getUserSchedule(user:RMUser,startDate,endDate): Promise<RMUserSchedule[]> {
    return this.restApiService.makeSecuredGetCall(`${RM_AUTHAPI_HREF}/${RM_REL_GET_USER_SCHEDULE}?userId=${user.userId}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`);
    // return new Promise<RMUserSchedule[]>((resolve,reject) => {
    //   let postRequest = this.httpClient.get<RMUserSchedule[]>(`${RM_AUTHAPI_HREF}/${RM_REL_GET_USER_SCHEDULE}?userId=${user.userId}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`)
    //   .pipe(
    //     catchError((err):any => {
    //       console.error("Error::",err);
    //       reject(err);
    //     })
    //   );
    //   postRequest.subscribe((schedules: any) => {
    //     resolve(schedules);
    //   });
    // })
  }

  getAllOperatorSchedules(user:RMUser,startDate,endDate): Promise<RMUserScheduleWrapper[]> {
    return this.restApiService.makeSecuredGetCall(`${RM_AUTHAPI_HREF}/${RM_REL_GET_ALL_OPERATOR_SCHEDULES}?userId=${user.userId}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`);
    // return new Promise<RMUserSchedule[]>((resolve,reject) => {
    //   let postRequest = this.httpClient.get<RMUserSchedule[]>(`${RM_AUTHAPI_HREF}/${RM_REL_GET_ALL_OPERATOR_SCHEDULES}?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`)
    //   .pipe(
    //     catchError((err):any => {
    //       console.error("Error::",err);
    //       reject(err);
    //     })
    //   );
    //   postRequest.subscribe((schedules: any) => {
    //     resolve(schedules);
    //   });
    // })
  }

  checkForOverlappingSchedules(userSchedule:RMUserSchedule): Promise<boolean> {
      return this.restApiService.makeSecuredPostCall(`${RM_AUTHAPI_HREF}/${RM_REL_CHECK_FOR_OVERLAPPING_SCHEDULES}`,userSchedule);
   }

  getAllOperators(): Promise<RMUser[]> {
    return this.restApiService.makeSecuredGetCall(RM_AUTHAPI_HREF + "/" + RM_REL_GET_ALL_OPERATORS);
    // return new Promise<RMUser[]>((resolve,reject) => {
    //   let getRequest = this.httpClient.get<RMUser[]>(RM_AUTHAPI_HREF + "/" + RM_REL_GET_ALL_OPERATORS)
    //   .pipe(
    //     catchError((err):any => {
    //       console.error("Error::",err);
    //       reject(err);
    //     })
    //   );
    //   getRequest.subscribe((dataUser: any) => {
    //     resolve(dataUser);
    //   });
    // })
  }

  linkRooferToSales(rooferUserId, salesUserId): Promise<boolean> {
    return this.restApiService.makeSecuredPostCall(RM_AUTHAPI_HREF + "/" + RM_REL_LINK_ROOFER_TO_SALES, {
      rooferUserId:rooferUserId,
      salesUserId:salesUserId
    });
  }
}
