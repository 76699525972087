import { Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { RMWorkOrdersService } from '@roofmath/restapi/rm-workorders.service';
import { RMResponsiveService } from '@roofmath/shared/responsive-service';

@Component({
  selector: 'rm-landing',
  templateUrl: './rm-landing.component.html',
  styleUrls: ['./rm-landing.component.scss']
})
export class RMLandingComponent implements OnInit {

  isMobileDevice:boolean = false;

  constructor(private responsiveService:RMResponsiveService,
    private workOrdersService:RMWorkOrdersService) { }

  ngOnInit(): void {
    this.isMobileDevice = this.responsiveService.isSmallScreen();
    this.responsiveService.getLayoutChangesObserver()
    .subscribe(layoutChangeBreakPoint => {
      if(layoutChangeBreakPoint == Breakpoints.Small || layoutChangeBreakPoint == Breakpoints.XSmall) {
        this.isMobileDevice = true;
      }
      else {
        this.isMobileDevice = false;
      }
    })
  }

  onSampleReport() {
    this.workOrdersService.getSampleReportPath()
    .then((pathMap) => {
      window.open(pathMap.path, '_blank');
    })
  }

  onNavigateToSection(id:string) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  }
}