import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { RMAuthenticationService } from './restapi/rm-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private navigationSubscription: Subscription;
  private browserRefresh;
  browserRefreshComplete:Subject<any> = new Subject<any>();

  constructor(private router: Router, private authService:RMAuthenticationService) { 
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log("SessionService constructor::",event);
        this.browserRefresh = !this.router.navigated;
        if(this.browserRefresh) {
          this.handleBrowserRefresh();
        }
      }
    });
  }

  public isBrowserRefresh() {
    return this.browserRefresh;
  }

  private handleBrowserRefresh() {
    console.log("handleBrowserRefresh");
    // let user = this.authService.getSignedInUser();
    // if(user != undefined || user != null) {
    //   this.authService.signIn(user.userName,user.password)
    //   .then(() => {
    //     console.log("then Emitting browserRefreshComplete");
    //     this.browserRefreshComplete.next(true);
    //   })
    //   .catch(err => {
    //     console.log("catch Emitting browserRefreshComplete");
    //     this.browserRefreshComplete.next(true);
    //   });
    // }
  }

  public subscribeToBrowserRefresh() {
    console.log("subscribeToBrowserRefresh");
    return this.browserRefreshComplete.asObservable();
  }

  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }
}
