import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rm-footer',
  templateUrl: './rm-footer.component.html',
  styleUrls: ['./rm-footer.component.scss']
})
export class RMFooterComponent implements OnInit {

  showPrivacyPopup:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  showPrivacy() {
    this.showPrivacyPopup = true;
  }

}
