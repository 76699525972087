import { Injectable } from '@angular/core';
import { EDGE_TYPE as EDGE_TYPE } from '@roofmath/models/rm-resources.model';
import { RMPlate, RMPlatePoint, RMEdge, RMWorkOrder, areEdgesEqual } from '@roofmath/models/workorders/rm-workorder.model';
import { Graph, GraphUtil, MutableUnweightedGraph } from 'graphs-for-js'

@Injectable({
  providedIn: 'root'
})
export class RMEdgesService {

  private edgeGraph:MutableUnweightedGraph<GraphNode, never>;

  constructor() {
    this.reset();
  }

  reset() {
    this.edgeGraph = new Graph<GraphNode, never>().noKey().undirected.unweighted();
  }

  initializeEdges(workOrder:RMWorkOrder) {
    this.reset();
    workOrder.plates.forEach(plate => {
      plate.edges.forEach(edge => {
        this.addEdge(workOrder,plate,edge);
      });
    })
  }

  addEdge(workOrder:RMWorkOrder,plate:RMPlate,edge:RMEdge) {
    let isEdgeCreated = false;
    let plate1Id = edge.pointOne.plate != undefined ? edge.pointOne.plate.plateId:edge.pointOne.plateId;
    let plate2Id = edge.pointTwo.plate != undefined ? edge.pointTwo.plate.plateId:edge.pointTwo.plateId;
    let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate1Id,edge.pointOne.pointId);
    let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate2Id,edge.pointTwo.pointId);
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    let hasEdge = this.edgeGraph.hasEdge(graphNode1,graphNode2);
    if(!hasEdge) {
      this.edgeGraph.insert(graphNode1);
      this.edgeGraph.insert(graphNode2);
      if(edge.edgeType == EDGE_TYPE.OPERATOR || edge.edgeType == EDGE_TYPE.SYSTEM || edge.edgeType == EDGE_TYPE.OVERHANG || edge.edgeType == EDGE_TYPE.PARALLEL) {
        isEdgeCreated = this.edgeGraph.connect(graphNode1,graphNode2);
      }
    }
    return isEdgeCreated;
  }

  // addEdge(workOrder:RMWorkOrder,plate:RMPlate,pointOne:RMPlatePoint,pointTwo:RMPlatePoint):Number {
  //   let graphNode1 = new GraphNode(pointOne);
  //   let graphNode2 = new GraphNode(pointTwo);
  //   this.edgeGraph.insert(graphNode1);
  //   this.edgeGraph.insert(graphNode2);
  //   this.edgeGraph.connect(graphNode1,graphNode2);
  //   // plate.edges = this.getAllEdges(workOrder,plate);
  //   return 1;
  // }

  removeEdge(workOrder:RMWorkOrder,plate:RMPlate,edge:RMEdge) {
    let plate1Id = edge.pointOne.plate != undefined ? edge.pointOne.plate.plateId:edge.pointOne.plateId;
    let plate2Id = edge.pointTwo.plate != undefined ? edge.pointTwo.plate.plateId:edge.pointTwo.plateId;
    let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate1Id,edge.pointOne.pointId);
    let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate2Id,edge.pointTwo.pointId);
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    let isRemoved = this.edgeGraph.disconnect(graphNode1,graphNode2);
    if(isRemoved) {
      // plate.edges = this.getAllEdges(workOrder,plate);
      let i=0;
      for(i=0;i<plate.edges.length;i++) {
        let tempEdge = plate.edges[i];
        if(areEdgesEqual(tempEdge,edge)) {
          break;
        }
      }
      if(i<plate.edges.length) {
        plate.edges.splice(i,1);
      }
    }
    return isRemoved;
  }

  getPathBetweenTwoPoints(pointOne:RMPlatePoint,pointTwo:RMPlatePoint) {
    let graphNode1 = new GraphNode(pointOne);
    let graphNode2 = new GraphNode(pointTwo);
    let pathObj = GraphUtil.findShortestPath(this.edgeGraph,graphNode1,graphNode2);
    if(pathObj.pathSize > -1) {
        return pathObj.path;
    }
    return undefined;
  }

  hasDirectEdge(pointOne:RMPlatePoint,pointTwo:RMPlatePoint) {
    let path = this.getPathBetweenTwoPoints(pointOne,pointTwo);
    if(path != undefined && path.length < 3) { 
      return true;
    }
    return false;
  }

  handleDeletePlate(workOrder:RMWorkOrder,plate:RMPlate) {
    let self = this;
    plate.points.forEach(point => {
      console.log("Deleting point::",point);
      self.handleDeletePoint(workOrder,plate,point);
    });
  }

  handleDeletePoint(workOrder:RMWorkOrder,plate:RMPlate,platePoint:RMPlatePoint) {
    let graphNode1 = new GraphNode(platePoint);
    this.getAllEdgesOfPoint(workOrder,plate,platePoint).forEach(edge => {
      this.removeEdge(workOrder,plate,edge);
    })
    this.edgeGraph.remove(graphNode1);
  }

  // getAllCorrelationsForTwoPlates(plate:RMPlate,plate1:RMPlate, plate2:RMPlate) {
  //   let matchedCorrelations = [];
  //   if(plate1 != undefined && plate2 != undefined) {
  //     plate1.points.forEach(point1 => {
  //       let graphNode1:GraphNode = new GraphNode(point1);
  //       if(this.edgeGraph.contains(graphNode1)) {
  //         plate2.points.forEach(point2 => {
  //           let tempEdgeObj = this.createEdgeObj(point1, point2);
  //           if(tempEdgeObj != undefined) {
  //             matchedCorrelations.push(tempEdgeObj);
  //           }
  //         })
  //       }
  //     })
  //   }
  //   return matchedCorrelations;
  // }

  // getWritableEdges(workOrder:RMWorkOrder,plate:RMPlate):RMEdge[] {
  //   let edges:RMEdge[] = [];
  //   this.getAllEdges(workOrder,plate).forEach(edge => {
  //     let pointObj1:GraphNode = edge.source;
  //     let pointObj2:GraphNode = edge.target;
  //     let plate1Id = pointObj1.plateId;
  //     let plate2Id = pointObj2.plateId;
  //     let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate1Id,edge.pointOne.pointId);
  //     let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate2Id,edge.pointTwo.pointId);
  //     let tempEdgeObj = this.createEdgeObj(pointOne, pointTwo);
  //     edges.push(tempEdgeObj);
  //   });
  //   return edges;
  // }

  getAllEdges(workOrder:RMWorkOrder,plate:RMPlate):RMEdge[] {
    let edges:RMEdge[] = [];
    this.edgeGraph.nodes().forEach(pointNode1 => {
      let pointObj1 = pointNode1;
      let tempNodes = this.edgeGraph.nodes().filter(tempGraphNode => {
        let tempNode = tempGraphNode;
        return !(tempNode.pointId == pointObj1.pointId && tempNode.plateId == pointObj1.plateId)
      })
      tempNodes.forEach(pointNode2 => {
        let pointObj2 = pointNode2;
        let plate1Id = pointObj1.plateId;
        let plate2Id = pointObj2.plateId;
        if(plate1Id == plate.plateId && plate2Id == plate.plateId) {
          let pointOne:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate1Id,pointObj1.pointId);
          let pointTwo:RMPlatePoint = this.getPointByPlateAndPointId(workOrder,plate2Id,pointObj2.pointId);
          let tempEdgeObj = this.createEdgeObj(pointOne, pointTwo);
          if(tempEdgeObj != undefined && !this.isAlreadyAddedEdgeCombination(edges,pointOne,pointTwo)) {
            edges.push(tempEdgeObj);
          }
        }
      })
    });
    return edges;
  }

  getAllEdgesOfPoint(workOrder:RMWorkOrder,plate:RMPlate,point:RMPlatePoint) {
    let graphNode = new GraphNode(point);
    let graphEdges = this.edgeGraph.outgoingEdgesOf(graphNode);
    let edges:RMEdge[] = [];
    graphEdges.forEach(graphEdge => {
      let graphNode1 = graphEdge.source;
      let graphNode2 = graphEdge.target;
      let point1 = this.getPointByPlateAndPointId(workOrder,graphNode1.plateId,graphNode1.pointId);
      let point2 = this.getPointByPlateAndPointId(workOrder,graphNode2.plateId,graphNode2.pointId);
      edges.push(this.createEdgeObj(point1,point2));
    })
    return edges;
  }

  isAlreadyAddedEdgeCombination(edgeCollections,pointOne, pointTwo) {
    for(let edgeObj of edgeCollections) {
      if(edgeObj.pointOneId == pointOne.pointId && edgeObj.pointTwoId == pointTwo.pointId) {
        return true;
      }
      if(edgeObj.pointOneId == pointTwo.pointId && edgeObj.pointTwoId == pointOne.pointId) {
        return true;
      }
    }
    return false;
  }
  
  createEdgeObj(pointOne:RMPlatePoint, pointTwo:RMPlatePoint):RMEdge {
    let tempedgeObj = undefined;
    let path = this.getPathBetweenTwoPoints(pointOne,pointTwo);
    if(path != undefined && path.length < 3) {
      tempedgeObj = new RMEdge();
      tempedgeObj.pointOne = pointOne;
      tempedgeObj.pointOneId = pointOne.pointId;
      tempedgeObj.pointTwo = pointTwo;
      tempedgeObj.pointTwoId = pointTwo.pointId;
      tempedgeObj.edgeType = EDGE_TYPE.OPERATOR;
      tempedgeObj.plateId = pointOne.plateId == undefined ? pointOne.plate.plateId : pointOne.plateId;
    }
    return tempedgeObj;
  }

  getPointByPlateAndPointId(workOrder:RMWorkOrder,plateId:string, pointId:string) {
    let point = undefined;
    let plate = workOrder.plates.filter(plate => {
      return plate.plateId == plateId;
    })[0];
    if(plate != undefined) {
      point = plate.points.filter(tempPoint => {
        return tempPoint.pointId == pointId;
      })[0];
    }
    return point;
  }
}

class GraphNode {
  pointId:string;
  plateId:string;

  constructor(platePoint:RMPlatePoint) {
    this.pointId = platePoint.pointId;
    if(platePoint.plate != undefined) {
      this.plateId = platePoint.plate.plateId;
    }
    else {
      this.plateId = platePoint.plateId;
    }
  }
}