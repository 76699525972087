import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { RMAbstractService } from './rm-abstract.service';

import { getEdgeId,getBoundaryId,RM_RESTAPI_HREF, RM_REL_PLATE, RM_REL_DELETE_WORKORDER_BY_ID,RM_REL_GET_ENTIRE_WORKORDER, RM_REL_SAVE_WORKORDER, CORRELATION_TYPE, getPlatePointId, getPlateAngleDetails, getNegativeInfinity, GENERATE_DUMP_FILE, 
         getMapProviderDetails, MAP_PROVIDER, getObstructionId, getCorrelationId, getPlateId, PLATE_ANGLE, RM_REL_SERVE_3D_MODEL, getStashId, OBSTRUCTION_TYPE, POINT_TYPE, EDGE_TYPE, RM_REL_REJECT_BOM, RM_REL_APPROVE_3D_MODEL, RM_REL_APPROVE_BOM, RM_REL_REJECT_3D_MODEL, RM_REL_GET_ALL_WORKORDERS, 
         RM_REL_GET_ALL_PRODUCTS, getPurchaseId, RM_REL_UPDATE_WORKORDER_ROOFER, RM_REL_UPDATE_WORKORDER_OPERATOR, RM_REL_GET_COUNT_BY_WORKORDER_STATUS, RM_REL_GET_SAMPLE_REPORT_PATH, RM_REL_GET_WORKORDER_METADATA, RM_REL_CREATE_WORKORDER_FLAG, 
         RM_REL_GET_WORKORDER_FLAGS, RM_REL_UPDATE_PRODUCT, RM_REL_GET_WORKORDER_PLATE_IMAGE, RM_REL_UPDATE_WORKORDERS_OPERATOR, RM_REL_UPDATE_FLAG_REWORK_COMMENT, RM_REL_UPDATE_FLAG_FIX_COMMENT, RM_REL_ADD_WORKORDER_PURCHASE, RM_REL_SUBMIT_CONTACT_US, RM_REL_REJECT_WORKORDER, RM_REL_GET_FLAG_TYPES, RM_REL_GET_ALL_FLAGGED_WORKORDERS, RM_REL_GET_ALL_ERRORED_WORKORDERS, RM_REL_GET_EDGES_METADATA, RM_REL_GET_EDGES_METADATA_SCHEMATIC, RM_REL_UPLOAD_ADDRESS_POOL, RM_REL_GET_ADDRESS_POOL_COUNT, RM_REL_POST_VALIDATE_ADDRESS_AMOUNT, RM_REL_CREATE_ADHOC_USER_WORKORDER, RM_REL_GET_ALL_ADHOC_PRODUCTS, RM_REL_POST_VALIDATE_ADDRESS_AMOUNT_ADHOC, RM_REL_GET_ALL_VALID_BOM_ROOF_TYPES, RM_REL_POST_ADD_WORKORDER_ADDITIONAL_INFO, RM_REL_GET_WORKORDER_ADDITIONAL_INFO, RM_REL_UPDATE_WORKORDER_DISCOUNT_PERCENTAGE, RM_REL_POST_SAVE_WORKORDER_GROUP, RM_REL_GET_WORKORDER_GROUP, RM_REL_GET_ALL_WORKORDER_GROUP, RM_REL_UPDATE_WORKORDER_GROUP, RM_REL_ADD_WORKORDERS_TO_WORKORDER_GROUP, RM_REL_ADD_PLATE_TO_WORKORDER_GROUP, RM_REL_GET_WORKORDERGROUP_PLATE_IMAGE, RMLatLng, RM_REL_STREET_VIEW_ADDRESS_API_LINK, RM_REL_LABEL_MAP_IMAGE_API_LINK, RM_REL_POST_SAVE_WORKORDER_LABEL_INFO, RM_REL_GET_WORKORDER_LABEL_INFO, RM_REL_GET_ALL_WORKORDER_FLAG_TYPES, RM_REL_GET_ALL_VALID_WORKORDER_ROOF_TYPES, RM_REL_GET_UNPROCESSED_WORKORDER_GROUP_COUNT, RM_REL_REOPEN_WORKORDER, RM_REL_POST_ADD_WORKORDER_ANGLE_INFO, RM_REL_GET_WORKORDER_ANGLE_INFO, RM_REL_GET_ANGLE_METADATA, RM_REL_GET_ANGLE_METADATA_SCHEMATIC, RM_REL_GET_ALL_DUMP_CONFIGURATIONS, RM_REL_ADD_DUMP_CONFIGURATION, RM_REL_GET_ALL_SUBSCRIPTION_DURATION_TYPES, RM_REL_ADD_SUBSCRIPTION_DURATION_TYPE, RM_REL_GET_SUBSCRIPTION_DURATION_TIME_UNITS } from '@roofmath/models/rm-resources.model';
import { RMWorkOrder, RMPlate, RMPlatePoint, RMEdge, RMBounds, RMBoundary, RMObstruction, RMCorrelation, getPlateIdOfPoint, RMStash, arePointsEqual, RMProduct, RMPurchase, RMWorkOrdersResponse, RMWorkOrdersRequest, RMWorkOrdersResponseUI, RMWorkOrderMetadata, RMWorkOrderFlag, WORKORDER_FLAGS_TYPE, RMWorkOrderFlagType, getFlagTypeObjectFromCode, RMEdgesMetadata, RMCreateWorkOrderResponse, RMBOMRoofType, RMWorkorderAdditionalInfo, RMWorkOrderGroup, RMLabelInfo, RMWorkOrderRoofType, RMWorkorderAngleInfo, RMDumpConfiguration, RMSubscriptionDurationType } from '@roofmath/models/workorders/rm-workorder.model';
import { RMACTION, RMUser, RMUserSettings } from '@roofmath/models/rm-auth.model';
import { RMRestapiService } from './rm-restapi.service';
import { RMLocation } from '@roofmath/models/rm-location.model';
import { getMapSize } from './rm-data.service';

@Injectable({
  providedIn: 'root'
})
export class RMWorkOrdersService extends RMAbstractService {

  private currentUserId:string;

  constructor( 
    private restApiService:RMRestapiService) {
    super();
  }

  getEntireWorkOrder(workOrderId: string): Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      // if(this.currentUserId != null || this.currentUserId != undefined) {
        // let userId:string = this.currentUserId;
        this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ENTIRE_WORKORDER}?workOrderId=${workOrderId}`)
        .then((dataWorkOrder: RMWorkOrder) => {
          this.sanitizeWorkOrder(dataWorkOrder)
          .then((sanitizedWorkOrder) => {
            resolve(sanitizedWorkOrder);
          })
          .catch(err => {
            reject(err);
          });
        })
      // }
    });
  }

  private sanitizePlate(workOrder:RMWorkOrder,plate:RMPlate) {
    plate.workOrderId = workOrder.workOrderId;
    if(plate.boundary != undefined && plate.boundary != null) {
      plate.boundary.pointOne.boundary.plate = plate;
      plate.boundary.pointTwo.boundary.plate = plate;
    }
    if(plate.points != undefined && plate.points.length > 0) {
      plate.points.forEach(point => { 
        this.sanitizePoint(workOrder,plate,point);
      });
    }
    if(plate.edges != undefined && plate.edges.length > 0) {
      plate.edges.forEach(edge => {
        this.sanitizeEdge(workOrder,plate,edge);
      })
    }
    if(plate.obstructions != undefined && plate.obstructions.length > 0) {
      plate.obstructions.forEach(obstruction => {
        this.sanitizeObstruction(workOrder,plate,obstruction);
      })
    }
    if(plate.stashes != undefined && plate.stashes.length > 0) {
      plate.stashes.forEach(stash => {
        this.sanitizeStash(workOrder,plate,stash);
      })
    }
  }

  private sanitizePoint(workOrder:RMWorkOrder,plate:RMPlate, point:RMPlatePoint) {
    if(point != null || point != undefined) {
      point.plateId = plate.plateId;
      point.plate=plate;
      if(point.pointType != POINT_TYPE.OBSTRUCTION && point.pointType != POINT_TYPE.BOUNDARY) {
        point.stashIds = this.getStashIdsForPoint(workOrder,plate,point);
      }
    }
  }

  private sanitizeEdge(workOrder:RMWorkOrder,plate:RMPlate, edge:RMEdge) {
    if(edge != null || edge != undefined) {
      edge.plate = plate;
      edge.plateId = plate.plateId;
      edge.pointOne = this.getPointByPlateAndPointId(workOrder,plate.plateId,edge.pointOneId);
      edge.pointTwo = this.getPointByPlateAndPointId(workOrder,plate.plateId,edge.pointTwoId);
      if(edge.edgeType != EDGE_TYPE.OBSTRUCTION && edge.edgeType != EDGE_TYPE.BOUNDARY) {
        edge.stashIds = this.getStashIdsForEdge(workOrder,plate,edge);
      }
    }
  }

  private sanitizeObstruction(workOrder:RMWorkOrder,plate:RMPlate,obstruction:RMObstruction) {
    if(obstruction != null || obstruction != undefined) {
      obstruction.points.forEach(point => { 
        plate.points.push(point);
        this.sanitizePoint(workOrder,plate,point);
        point.obstruction = obstruction;
      });
      obstruction.edges.forEach(edge => { 
        this.sanitizeEdge(workOrder,plate,edge);
        edge.obstruction = obstruction;
      });
    }
  }

  private sanitizeStash(workOrder:RMWorkOrder,plate:RMPlate,stash:RMStash) {
    if(stash != null || stash != undefined) {
      stash.value = stash.stashId;
      stash.plateId = plate.plateId;
      stash.plate = plate;

      if(stash.points == undefined) {
        stash.points = [];
      }
      stash.pointIds.forEach(pointId => {
        let point = this.getPointByPlateAndPointId(workOrder,plate.plateId,pointId);
        stash.points.push(point);
      })

      if(stash.edges == undefined) {
        stash.edges = [];
      }
      stash.edgeIds.forEach(edgeId => {
        let edge = this.getEdgeByPlateAndEdgeId(workOrder,plate.plateId,edgeId);
        stash.edges.push(edge);
      })
    }
  }

  // private getWorkOrderPlate(workOrder:RMWorkOrder, plateId:string) {
  //   let plate = undefined;
  //   for(let tempPlate of workOrder.plates) {
  //     if(tempPlate.plateId == plateId) {
  //       plate = tempPlate;
  //       break;
  //     }
  //   }
  //   return plate;
  // }

  private sanitizeCorrelation(workOrder:RMWorkOrder, correlation:RMCorrelation) {
    if(correlation != null || correlation != undefined) {
      correlation.pointOneId = correlation.pointOneId;
      correlation.plate1Id = correlation.pointOnePlateId;
      correlation.pointOne = this.getPointByPlateAndPointId(workOrder,correlation.plate1Id,correlation.pointOneId);
      correlation.pointTwoId = correlation.pointTwoId;
      correlation.plate2Id = correlation.pointTwoPlateId;
      correlation.pointTwo = this.getPointByPlateAndPointId(workOrder,correlation.plate2Id,correlation.pointTwoId);
    }
  }

  private sanitizeWorkOrder(workOrder:RMWorkOrder):Promise<RMWorkOrder> {
    console.log("Sanitization started::", new Date());
    return new Promise<RMWorkOrder>((resolve,reject) => {
      let promises = [];
      if(workOrder.plates != undefined && workOrder.plates.length > 0){
        for(let plate of workOrder.plates) {
          this.sanitizePlate(workOrder,plate);
        }
        if(workOrder.correlations != undefined && workOrder.correlations.length > 0) {
          for(let correlation of workOrder.correlations) {
            this.sanitizeCorrelation(workOrder,correlation);
          }
        }
      }
      Promise.all(promises)
        .then(() => {
          console.log("Sanitization resolve::", new Date());
          resolve(workOrder);
        })
        .catch(err => {
          console.log("Sanitization error::", new Date());
          reject(err);
        })
    })
  }

  getPointByPlateAndPointId(workOrder:RMWorkOrder,plateId:string, pointId:string) {
    let point = undefined;
    let plate = workOrder.plates.filter(plate => {
      return plate.plateId == plateId;
    })[0];
    if(plate != undefined) {
      point = plate.points.filter(tempPoint => {
        return tempPoint.pointId == pointId;
      })[0];
    }
    return point;
  }

  getEdgeByPlateAndEdgeId(workOrder:RMWorkOrder,plateId:string, edgeId:string) {
    let edge = undefined;
    let plate = workOrder.plates.filter(plate => {
      return plate.plateId == plateId;
    })[0];
    if(plate != undefined) {
      edge = plate.edges.filter(tempEdge => {
        return tempEdge.edgeId == edgeId;
      })[0];
    }
    return edge;
  }

  private getStashIdsForPoint(workOrder:RMWorkOrder,plate:RMPlate,point:RMPlatePoint) {
    let stashIds = undefined;
    for(let tempPoint of plate.points) {
      if(point.plate != undefined && tempPoint.stashIds != undefined && tempPoint.stashIds != null && tempPoint.stashIds.length > 0) {
        if(arePointsEqual(tempPoint,point)) {
          return tempPoint.stashIds;
        }
      }
    }
    if(plate.stashes != undefined && plate.stashes.length > 0) {
      plate.stashes.forEach(stash => {
        stash.pointIds.forEach(stashPointId => {
          if(stashPointId == point.pointId) {
            if(stashIds == undefined) {
              stashIds = [];
            }
            stashIds.push(stash.stashId);
          }
        })
      })
    }
    return stashIds;
  }
  private getStashIdsForEdge(workOrder:RMWorkOrder,plate:RMPlate,edge:RMEdge) {
    let stashIds = undefined;
    if(plate.stashes != undefined && plate.stashes.length > 0) {
      plate.stashes.forEach(stash => {
        stash.edgeIds.forEach(stashEdgeId => {
          if(stashEdgeId == edge.edgeId) {
            if(stashIds == undefined) {
              stashIds = [];
            }
            stashIds.push(stash.stashId);
          }
        })
      })
    }
    return stashIds;
  }

  // getWorkOrders(restapi: RMRestapi, httpParams?: HttpParams): Promise<RMWorkOrder[]> {
  //   return new Promise<RMWorkOrder[]>((resolve,reject) => {
  //     let workOrders:RMWorkOrder[];
  //     if (!httpParams) {
  //       httpParams = new HttpParams();
  //     }
  //     let size = 50;
  //     this.httpClient.get<RMWorkOrder[]>(getLinkRelHref(restapi, RM_REL_WORKORDERS) + `?size=${size}`, { params: httpParams })
  //     .subscribe((dataWorkOrders: RMWorkOrder[]) => {
  //       workOrders = dataWorkOrders["resources"];
  //       resolve(workOrders);
  //     });
  //   });
  // }

  getAllWorkOrders(maxRowsPerPage,currPage, filterStatus,filterText,sortCol,sortDir,filteredRoofers): Promise<RMWorkOrdersResponse> {
    return new Promise<RMWorkOrdersResponse>((resolve,reject) => {
      let userId = this.currentUserId;
      let workOrdersRequest:RMWorkOrdersRequest = new RMWorkOrdersRequest();
      workOrdersRequest.userId = userId;
      workOrdersRequest.maxRowsPerPage = maxRowsPerPage;
      workOrdersRequest.currPage = currPage;
      workOrdersRequest.filterText = filterText;
      if(filterStatus == undefined) {
        filterStatus = [];
      }
      if(filteredRoofers == undefined) {
        filteredRoofers = [];
      }
      workOrdersRequest.filterStatus = filterStatus;
      workOrdersRequest.filteredRoofers = filteredRoofers;
      workOrdersRequest.sortCol = sortCol;
      workOrdersRequest.sortDir = sortDir;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_WORKORDERS}`,workOrdersRequest as any)
      .then((dataWorkOrders:RMWorkOrdersResponse) => {
        resolve(dataWorkOrders);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  getAllFlaggedWorkOrders(maxRowsPerPage,currPage,filterText,sortCol,sortDir): Promise<RMWorkOrdersResponse> {
    return new Promise<RMWorkOrdersResponse>((resolve,reject) => {
      let userId = this.currentUserId;
      let workOrdersRequest:RMWorkOrdersRequest = new RMWorkOrdersRequest();
      workOrdersRequest.userId = userId;
      workOrdersRequest.maxRowsPerPage = maxRowsPerPage;
      workOrdersRequest.currPage = currPage;
      workOrdersRequest.filterText = filterText;
      workOrdersRequest.sortCol = sortCol;
      workOrdersRequest.sortDir = sortDir;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_FLAGGED_WORKORDERS}`,workOrdersRequest as any)
      .then((dataWorkOrders:RMWorkOrdersResponse) => {
        resolve(dataWorkOrders);
      });
    });
  }

  getAllErroredWorkOrders(maxRowsPerPage,currPage,filterText,sortCol,sortDir): Promise<RMWorkOrdersResponse> {
    return new Promise<RMWorkOrdersResponse>((resolve,reject) => {
      let userId = this.currentUserId;
      let workOrdersRequest:RMWorkOrdersRequest = new RMWorkOrdersRequest();
      workOrdersRequest.userId = userId;
      workOrdersRequest.maxRowsPerPage = maxRowsPerPage;
      workOrdersRequest.currPage = currPage;
      workOrdersRequest.filterText = filterText;
      workOrdersRequest.sortCol = sortCol;
      workOrdersRequest.sortDir = sortDir;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_ERRORED_WORKORDERS}`,workOrdersRequest as any)
      .then((dataWorkOrders:RMWorkOrdersResponse) => {
        resolve(dataWorkOrders);
      });
    });
  }

  getWorkOrderMetadata(workOrderId:String):Promise<RMWorkOrderMetadata> {
    return new Promise<RMWorkOrderMetadata>((resolve,reject) => {
      let url = `${RM_RESTAPI_HREF}/${RM_REL_GET_WORKORDER_METADATA}?workOrderId=${workOrderId}`;
      this.restApiService.makeSecuredGetCall(url)
      .then((metadata: RMWorkOrderMetadata) => {
        resolve(metadata);
      });
    })
  }

  getAllWorkorderFlagTypes():Promise<RMWorkOrderFlagType[]> {
    return new Promise<RMWorkOrderFlagType[]>((resolve,reject) => {
      // let flagTypeList:RMWorkOrderFlagType[] = [];
      // for(let flagType in WORKORDER_FLAGS_TYPE) {
      //   let tempFlagType:RMWorkOrderFlagType = getFlagTypeObjectFromCode(flagType);
      //   flagTypeList.push(tempFlagType);
      // }
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_FLAG_TYPES}`)
      .then(response => {
        resolve(response);
      });
    })
  }

  createWorkOrderFlag(workOrder:RMWorkOrder,flagType,flagComment):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let workOrderFlag:RMWorkOrderFlag = new RMWorkOrderFlag();
      workOrderFlag.comment = flagComment;
      workOrderFlag.flagType = flagType;
      workOrderFlag.workOrderId = workOrder.workOrderId;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_CREATE_WORKORDER_FLAG}`,workOrderFlag as any)
      .then(response => {
        resolve(response);
      });
    })
  }

  getWorkOrderFlags(workOrderId:String):Promise<RMWorkOrderFlag[]> {
    return new Promise<RMWorkOrderFlag[]>((resolve,reject) => {
      let url = `${RM_RESTAPI_HREF}/${RM_REL_GET_WORKORDER_FLAGS}?workOrderId=${workOrderId}`;
      this.restApiService.makeSecuredGetCall(url)
      .then((flags: RMWorkOrderFlag[]) => {
        resolve(flags);
      });
    })
  }

  updateWorkOrderFlagReWorkComment(workOrderFlag): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      delete workOrderFlag.creator;
      delete workOrderFlag.createdDate;
      delete workOrderFlag.reWorkCommentUser;
      delete workOrderFlag.reWorkCommentDate;
      delete workOrderFlag.fixCommentUser;
      delete workOrderFlag.fixCommentDate;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_FLAG_REWORK_COMMENT}`,workOrderFlag as any)
      .then((dataWorkOrderFlag) => {
        resolve(dataWorkOrderFlag);
      });
    });
  }

  updateWorkOrderFlagFixComment(workOrderFlag): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      delete workOrderFlag.creator;
      delete workOrderFlag.createdDate;
      delete workOrderFlag.reWorkCommentUser;
      delete workOrderFlag.reWorkCommentDate;
      delete workOrderFlag.fixCommentUser;
      delete workOrderFlag.fixCommentDate;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_FLAG_FIX_COMMENT}`,workOrderFlag as any)
      .then((dataWorkOrderFlag) => {
        resolve(dataWorkOrderFlag);
      });
    });
  }

  updateWorkOrderRoofer(workOrder:RMWorkOrder,roofer:RMUser): Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      let userId = this.currentUserId;
      this.restApiService.makeSecuredPutCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_WORKORDER_ROOFER}?workOrderId=${workOrder.workOrderId}&rooferId=${roofer.userId}`,{
        userId:userId,
        workOrderId:workOrder.workOrderId,
        rooferId:roofer.userId
      } as any)
      .then((dataWorkOrder: RMWorkOrder) => {
        resolve(dataWorkOrder);
      });
    });
  }

  updateWorkOrderDiscountPercentage(workOrder:RMWorkOrder,discountPercentage): Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      let userId = this.currentUserId;
      this.restApiService.makeSecuredPutCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_WORKORDER_DISCOUNT_PERCENTAGE}?workOrderId=${workOrder.workOrderId}&discountPercentage=${discountPercentage}`,{
        userId:userId,
        workOrderId:workOrder.workOrderId,
        discountPercentage:discountPercentage
      } as any)
      .then((dataWorkOrder: RMWorkOrder) => {
        resolve(dataWorkOrder);
      });
    });
  }

  updateWorkOrderOperator(workOrder:RMWorkOrder,operator:RMUser): Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      let userId = this.currentUserId;
      this.restApiService.makeSecuredPutCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_WORKORDER_OPERATOR}?workOrderId=${workOrder.workOrderId}&operatorId=${operator.userId}`,{
        userId:userId,
        workOrderId:workOrder.workOrderId,
        operatorId:operator.userId
      } as any)
      .then((dataWorkOrder: RMWorkOrder) => {
        resolve(dataWorkOrder);
      });
    });
  }

  updateWorkOrdersOperator(workOrders:RMWorkOrder[]): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      workOrders.forEach(workOrder => {
        if(workOrder.roofer != undefined && workOrder.roofer.userId != undefined) {
          workOrder.roofer = workOrder.roofer.userId;
        }
        if(workOrder.operator != undefined && workOrder.operator.userId != undefined) {
          workOrder.operator = workOrder.operator.userId;
        }
        if(workOrder.creator != undefined && workOrder.creator.userId != undefined) {
          workOrder.creator = workOrder.creator.userId;
        }
        delete workOrder.purchases;
        delete workOrder.purchasedProducts;
        delete workOrder.workOrderMetadata;
        delete workOrder.workOrderStatusLogs;
        delete workOrder.flags;
        delete workOrder.correlations;
        delete workOrder.permittedActions;
      })
      this.restApiService.makeSecuredPutCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_WORKORDERS_OPERATOR}`,workOrders as any)
      .then((dataWorkOrder: any) => {
        resolve(dataWorkOrder);
      });
    });
  }

  saveWorkOrderGroup(workOrderGroup:RMWorkOrderGroup, httpParams?: HttpParams): Promise<RMWorkOrderGroup> {
    cleanWorkOrderGroup(workOrderGroup);
    return new Promise<RMWorkOrderGroup>((resolve,reject) => {
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_REL_POST_SAVE_WORKORDER_GROUP}`, workOrderGroup as any);
      postRequest.then((dataWorkOrderGroup: any) => {
          resolve(dataWorkOrderGroup);
      }),
      (error => {
        reject(error);
      });
    });
  }

  addPlateToWorkOrderGroup(workOrderGroup:RMWorkOrderGroup) {
    cleanWorkOrderGroup(workOrderGroup);
    return new Promise<RMWorkOrderGroup>((resolve,reject) => {
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_REL_GET_WORKORDER_GROUP}/${RM_REL_ADD_PLATE_TO_WORKORDER_GROUP}`, workOrderGroup as any);
      postRequest.then((dataWorkOrderGroup: any) => {
          resolve(dataWorkOrderGroup);
      }),
      (error => {
        reject(error);
      });
    });
  }

  addWorkOrdersToWorkOrderGroup(workOrderGroup:RMWorkOrderGroup, workOrders:RMWorkOrder[], httpParams?: HttpParams): Promise<RMWorkOrderGroup> {
    cleanWorkOrderGroup(workOrderGroup);
    return new Promise<RMWorkOrderGroup>((resolve,reject) => {
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_REL_ADD_WORKORDERS_TO_WORKORDER_GROUP}`, {
        workOrderGroupId:workOrderGroup.workOrderGroupId,
        workOrderList:workOrders
      });
      postRequest.then((dataWorkOrderGroup: any) => {
          resolve(dataWorkOrderGroup);
      }),
      (error => {
        reject(error);
      });
    });
  }

  

  getWorkOrderGroup(workOrderGroupId): Promise<RMWorkOrderGroup> {
    // cleanWorkOrder(workOrder);
    return new Promise<RMWorkOrderGroup>((resolve,reject) => {
      let postRequest = this.restApiService.makeSecuredGetCall(`${RM_REL_GET_WORKORDER_GROUP}?workOrderGroupId=${workOrderGroupId}`);
      postRequest.then((dataWorkOrderGroup: any) => {
          resolve(dataWorkOrderGroup);
      }),
      (error => {
        reject(error);
      });
    });
  }

  getAllWorkOrderGroups(maxRowsPerPage,currPage, filterStatus,filterText,sortCol,sortDir,filteredRoofers): Promise<RMWorkOrderGroup> {
    // cleanWorkOrder(workOrder);
    return new Promise<RMWorkOrderGroup>((resolve,reject) => {
      let userId = this.currentUserId;
      let workOrdersRequest:RMWorkOrdersRequest = new RMWorkOrdersRequest();
      workOrdersRequest.userId = userId;
      workOrdersRequest.maxRowsPerPage = maxRowsPerPage;
      workOrdersRequest.currPage = currPage;
      workOrdersRequest.filterText = filterText;
      if(filterStatus == undefined) {
        filterStatus = [];
      }
      if(filteredRoofers == undefined) {
        filteredRoofers = [];
      }
      workOrdersRequest.filterStatus = filterStatus;
      workOrdersRequest.filteredRoofers = filteredRoofers;
      workOrdersRequest.sortCol = sortCol;
      workOrdersRequest.sortDir = sortDir;
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_REL_GET_ALL_WORKORDER_GROUP}`, workOrdersRequest as any);
      postRequest.then((dataWorkOrderGroup: any) => {
          resolve(dataWorkOrderGroup);
      }),
      (error => {
        reject(error);
      });
    });
  }

  updateWorkOrderGroup(workOrderGroupId,amount, rooferId): Promise<RMWorkOrderGroup> {
    return new Promise<RMWorkOrderGroup>((resolve,reject) => {
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_REL_UPDATE_WORKORDER_GROUP}`,{
        workOrderGroupId:workOrderGroupId,
        amountProposed:amount,
        rooferId:rooferId
      });
      postRequest.then((dataWorkOrderGroup: any) => {
          resolve(dataWorkOrderGroup);
      }),
      (error => {
        reject(error);
      });
    });
  }

  saveWorkOrder(workOrder:RMWorkOrder, httpParams?: HttpParams): Promise<RMWorkOrder> {
    cleanWorkOrder(workOrder);
    return new Promise<RMWorkOrder>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      let postRequest = this.restApiService.makeSecuredPostCall(RM_RESTAPI_HREF + "/" + RM_REL_SAVE_WORKORDER, workOrder as any)
      // .pipe(
      //   catchError((err):any => {
      //     console.error("Error::",err);
      //     reject(err);
      //   })
      // );
      postRequest.then((dataWorkOrder: any) => {
        this.sanitizeWorkOrder(dataWorkOrder)
        .then((sanitizedWorkOrder) => {
          workOrder = sanitizedWorkOrder;
          resolve(sanitizedWorkOrder);
        }),
        (error => {
          reject(error);
        });
      });
    })
  }

  reopenWorkOrder(workOrder:RMWorkOrder, httpParams?: HttpParams): Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_REOPEN_WORKORDER}?workOrderId=${workOrder.workOrderId}`,{})
      postRequest.then((dataWorkOrder: any) => {
        this.sanitizeWorkOrder(dataWorkOrder)
        .then((sanitizedWorkOrder) => {
          workOrder = sanitizedWorkOrder;
          resolve(sanitizedWorkOrder);
        }),
        (error => {
          reject(error);
        });
      });
    })
  }

  createAdhocUserWorkOrder(workOrder:RMWorkOrder, userEmailId:string, httpParams?: HttpParams): Promise<string> {
    cleanWorkOrder(workOrder);
    return new Promise<string>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      let postRequest = this.restApiService.makeSecuredPostTextCall(RM_RESTAPI_HREF + "/" + RM_REL_CREATE_ADHOC_USER_WORKORDER, {
        "workOrder": workOrder,
        "userEmailId":userEmailId
      } as any);
      postRequest.then((data: any) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      })
    });
  }

  addNewWorkOrderProductPurchase(workOrder:RMWorkOrder,purchase:RMPurchase, httpParams?: HttpParams): Promise<RMPurchase[]> {
    cleanWorkOrder(workOrder);
    return new Promise<RMPurchase[]>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      let postRequest = this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_ADD_WORKORDER_PURCHASE}?workOrderId=${workOrder.workOrderId}`, purchase as any)
      postRequest.then((purchases: any) => {
        resolve(purchases);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  get3DModelLink(workOrder:RMWorkOrder) {
    let url = RM_REL_SERVE_3D_MODEL + "/"  + workOrder.workOrderId + "/pythonoutput/X3D/model";
    return url;
  }

  getThreeJSLink(workOrder:RMWorkOrder) {
    let url = RM_REL_SERVE_3D_MODEL + "/"  + workOrder.workOrderId + "/pythonoutput/THREEJS/model";
    return url;
  }

  getBOMLink(workOrder:RMWorkOrder) {
    let url = `serveBom/${workOrder.workOrderId}`;
    return url;
  }

  getReportLink(workOrder:RMWorkOrder) {
    let url = `serveReport/${workOrder.workOrderId}`;
    return url;
  }

  get3DModelLinkWorkOrderGroup(workOrderGroup:RMWorkOrderGroup) {
    let url = RM_REL_SERVE_3D_MODEL + "/"  + workOrderGroup.workOrderGroupId + "/pythonoutput/X3D/model";
    return url;
  }

  getThreeJSLinkWorkOrderGroup(workOrderGroup:RMWorkOrderGroup) {
    let url = RM_REL_SERVE_3D_MODEL + "/"  + workOrderGroup.workOrderGroupId + "/pythonoutput/THREEJS/model";
    return url;
  }

  getBOMLinkWorkOrderGroup(workOrderGroup:RMWorkOrderGroup) {
    let url = `serveBom/${workOrderGroup.workOrderGroupId}`;
    return url;
  }

  getReportLinkWorkOrderGroup(workOrderGroup:RMWorkOrderGroup) {
    let url = `serveReport/${workOrderGroup.workOrderGroupId}`;
    return url;
  }

  getEdgesMetadata(workOrderId) {
    return new Promise<RMEdgesMetadata>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_EDGES_METADATA}?workOrderId=${workOrderId}`)
      getRequest.then((metadata: any) => {
        resolve(metadata);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  getEdgesTypeSchematic(workOrderId) {
    return new Promise<any>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetImageCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_EDGES_METADATA_SCHEMATIC}?workOrderId=${workOrderId}`)
      getRequest.then((schematic: any) => {
        resolve(schematic);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  getAngleMetadata(workOrderId) {
    return new Promise<any>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ANGLE_METADATA}?workOrderId=${workOrderId}`)
      getRequest.then((metadata: any) => {
        resolve(metadata);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  getAngleSchematic(workOrderId) {
    return new Promise<any>((resolve,reject) => {
      let getRequest = this.restApiService.makeSecuredGetImageCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ANGLE_METADATA_SCHEMATIC}?workOrderId=${workOrderId}`)
      getRequest.then((schematic: any) => {
        resolve(schematic);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  getWorkOrderPlateResource(plate:RMPlate, resourceName:string):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let httpParams = new HttpParams();
      httpParams.append(RM_REL_PLATE,JSON.stringify(plate));
      httpParams.append("resourceName",resourceName);
      this.restApiService.makeSecuredGetCall("getPlateResourceFile")
      .then((dataResource: any) => {
        resolve(dataResource);
      });
    });
  }

  getCountByWorkOrderStatus():Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_COUNT_BY_WORKORDER_STATUS}`)
      .then((statusMap: any) => {
          resolve(statusMap);
      });
      // this.httpClient.get<any>(`${RM_RESTAPI_HREF}/${RM_REL_GET_COUNT_BY_WORKORDER_STATUS}?userId=${userId}`)
      // .subscribe((statusMap: any) => {
      //   resolve(statusMap);
      // });
    });
  }

  getWorkOrderPlateResourceUrl(plate:RMPlate, workOrderId:string, resourceName:string):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(RM_RESTAPI_HREF + "/" + "getPlateResourceUrl?provider=" + plate.provider + "&workOrderId=" + workOrderId + "&resourceName=" + resourceName)
      .then((dataResource: any) => {
        resolve(dataResource);
      });
    });
  }

  getWorkOrderPlateResourceBlob(plate:RMPlate, workOrderId:string, resourceName:string):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/getPlateResourceUrl?provider=${plate.provider}&workOrderId=${workOrderId}&resourceName=${resourceName}`)
      .then((dataResource: any) => {
        this.restApiService.makeSecuredGetCall(dataResource)
        .then(blobData => {
          resolve(blobData);
        })
      });
    });
  }

  getWorkOrderPlateImage(plate:RMPlate):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetImageCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_WORKORDER_PLATE_IMAGE}?plateId=${plate.plateId}`)
      .then((dataResource: any) => {
        resolve(dataResource);
      });
    });
  }

  getOperateByImage(plate):Promise<any> {
    // let plate = createNewPlate(0,0, MAP_PROVIDER.GOOGLE, PLATE_ANGLE.ORTHOGONAL, 21);
    // plate.plateId = "street";
    return this.getWorkOrderPlateImage(plate);
  }

  getStreetViewImage(latLng:RMLatLng, height, width, zoom = 1, pitch = 0, heading = 270, id):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = `${RM_RESTAPI_HREF}/${RM_REL_STREET_VIEW_ADDRESS_API_LINK}?width=${width}&height=${height}&latitude=${latLng.getLatitude()}&longitude=${latLng.getLongitude()}&zoom=${zoom}&heading=${heading}&pitch=${pitch}&id=${id}`;
      this.restApiService.makeSecuredGetImageCall(link)
      .then(data => {
        console.log("getStreetViewImage::",data);
        resolve(data);
      })
    })    
  }

  getLabelMapImage(height, width, id):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = `${RM_RESTAPI_HREF}/${RM_REL_LABEL_MAP_IMAGE_API_LINK}?width=${width}&height=${height}&id=${id}`;
      this.restApiService.makeSecuredGetImageCall(link)
      .then(data => {
        resolve(data);
      })
    })    
  }

  getLabelMapImageWorkOrderGroup(height, width, id):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = `${RM_REL_GET_WORKORDER_GROUP}/${RM_REL_LABEL_MAP_IMAGE_API_LINK}?width=${width}&height=${height}&id=${id}`;
      this.restApiService.makeSecuredGetImageCall(link)
      .then(data => {
        resolve(data);
      })
    })    
  }

  getWorkOrderGroupPlateImage(workOrderGroupId):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetImageCall(`${RM_REL_GET_WORKORDER_GROUP}/${RM_REL_GET_WORKORDERGROUP_PLATE_IMAGE}?workOrderGroupId=${workOrderGroupId}`)
      .then((dataResource: any) => {
        resolve(dataResource);
      });
    });
  }

  getWorkOrderDefaultImage(workOrder:RMWorkOrder):Promise<any> {
    let orthoPlate:RMPlate = workOrder.plates.filter(plate => {
      return plate.plateAngle == PLATE_ANGLE.ORTHOGONAL && plate.provider == MAP_PROVIDER.GOOGLE;
    })[0];

    return this.getWorkOrderPlateImage(orthoPlate);
  }

  getWorkOrderGroupDefaultImage(workOrderGroup:RMWorkOrderGroup):Promise<any> {
    return this.getWorkOrderGroupPlateImage(workOrderGroup.workOrderGroupId);
  }

  getAllProducts():Promise<RMProduct[]> {
    return new Promise<RMProduct[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_PRODUCTS}`)
      .then((dataProducts: RMProduct[]) => {
        // this.availableProducts = dataProducts;
        resolve(dataProducts);
      });
    });
  }

  getAllAdhocProducts():Promise<RMProduct[]> {
    return new Promise<RMProduct[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_ADHOC_PRODUCTS}`)
      .then((dataProducts: RMProduct[]) => {
        // this.availableProducts = dataProducts;
        resolve(dataProducts);
      });
    });
  }

  updateProduct(product:RMProduct):Promise<RMProduct> {
    return new Promise<RMProduct>((resolve,reject) => {
      let userId = this.currentUserId;
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_UPDATE_PRODUCT}?userId=${userId}`,product as any)
      .then((dataProduct: RMProduct) => {
        resolve(dataProduct);
      });
    });
  }

  getAllDumpConfigurations():Promise<RMDumpConfiguration[]> {
    return new Promise<RMDumpConfiguration[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_REL_GET_ALL_DUMP_CONFIGURATIONS}`)
      .then((dataDumpConfigurations: RMDumpConfiguration[]) => {
        resolve(dataDumpConfigurations);
      });
    });
  }

  addDumpConfiguration(dumpConfiguration:RMDumpConfiguration):Promise<RMDumpConfiguration> {
    return new Promise<RMDumpConfiguration>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_REL_ADD_DUMP_CONFIGURATION}`,dumpConfiguration as any)
      .then((dataDumpConfiguration: RMDumpConfiguration) => {
        resolve(dataDumpConfiguration);
      });
    });
  }

  uploadPlateMetaData(plate: RMPlate, workOrder:RMWorkOrder, imageBlob:Blob, httpParams?: HttpParams):Promise<RMPlate> {
    cleanWorkOrder(workOrder);
    cleanPlate(plate);
    let suffix = "";
    if(plate.isLabelPlate) {
      suffix = "_label";
    }
    let imageFileName = plate.label.toLowerCase() + suffix + '.png';
    let metaFileName = plate.label.toLowerCase() + suffix + '.json';
    plate.imageUrl = imageFileName;
    plate.metaUrl = metaFileName;
    if (!httpParams) {
      httpParams = new HttpParams();
    }
    return new Promise<RMPlate>((resolve,reject) => { 
      const fd = new FormData();
      fd.append('workorder', new Blob([JSON.stringify(workOrder)], {
          type: 'application/json'
      }));
      let imageFile = new File([imageBlob],imageFileName);
      fd.append('files', imageFile);
      fd.append('dirName', workOrder.workOrderId + "/" + plate.provider );
      this.restApiService.makeSecuredPostCall('/uploadMultipleFilesWithMetadata', fd)
      .then((response) => {
        let metaDataFile = new File([JSON.stringify(plate, null, '  ')],metaFileName);
        let fd = new FormData();
        fd.append('file', metaDataFile);
        fd.append('dirName', workOrder.workOrderId + "/" + plate.provider);
        this.restApiService.makeSecuredPostCall('/uploadFile', fd)
        .then((response) => {
          resolve(plate);
        },
        (error) => {
          console.error(error);
        });
      },
      (error) => {
        reject(error);
      });
    });
  }

  uploadWorkOrderGroupPlateMetaData(plate: RMPlate, workOrderGroup:RMWorkOrderGroup, imageBlob:Blob, httpParams?: HttpParams):Promise<RMPlate> {
    cleanWorkOrderGroup(workOrderGroup);
    cleanPlate(plate);
    let imageFileName = plate.label.toLowerCase() + '.png';
    let metaFileName = plate.label.toLowerCase() + '.json';
    plate.imageUrl = imageFileName;
    plate.metaUrl = metaFileName;
    if (!httpParams) {
      httpParams = new HttpParams();
    }
    return new Promise<RMPlate>((resolve,reject) => { 
      const fd = new FormData();
      fd.append('workorderGroup', new Blob([JSON.stringify(workOrderGroup)], {
          type: 'application/json'
      }));
      let imageFile = new File([imageBlob],imageFileName);
      fd.append('files', imageFile);
      fd.append('dirName', workOrderGroup.workOrderGroupId + "/" + plate.provider );
      this.restApiService.makeSecuredPostCall('/uploadMultipleFilesGroupWithMetadata', fd)
      .then((response) => {
        let metaDataFile = new File([JSON.stringify(plate, null, '  ')],metaFileName);
        let fd = new FormData();
        fd.append('file', metaDataFile);
        fd.append('dirName', workOrderGroup.workOrderGroupId + "/" + plate.provider);
        this.restApiService.makeSecuredPostCall('/uploadFile', fd)
        .then((response) => {
          resolve(plate);
        },
        (error) => {
          console.error(error);
        });
      },
      (error) => {
        reject(error);
      });
    });
  }

  deleteWorkOrder(workOrder:RMWorkOrder, httpParams?: HttpParams):Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      this.restApiService.makeSecuredDeleteCall(`${RM_RESTAPI_HREF}/${RM_REL_DELETE_WORKORDER_BY_ID}?workOrderId=${workOrder.workOrderId}`)
      .then(() => {
        resolve(workOrder);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  rejectWorkOrder(workOrder:RMWorkOrder,rejectText, httpParams?: HttpParams):Promise<RMWorkOrder> {
    return new Promise<RMWorkOrder>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_REJECT_WORKORDER}`,{
        workOrderId:workOrder.workOrderId,
        rejectText:rejectText
      } as any)
      .then(() => {
        resolve(workOrder);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  deleteObstruction(obstructionId, httpParams?: HttpParams):Promise<RMWorkOrder> {
    return new Promise<any>((resolve,reject) => {
      if (!httpParams) {
        httpParams = new HttpParams();
      }
      this.restApiService.makeSecuredPutCall(`${RM_RESTAPI_HREF}/deleteObsturction/${obstructionId}`,{} as any)
      .then(() => {
        resolve("Done");
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  generateWorkOrderDump(workOrderId:string, edgesMetadata:RMEdgesMetadata, anglesMetadata:any, dumpConfigurations:string[]):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredPostTextCall(`${RM_RESTAPI_HREF}/${GENERATE_DUMP_FILE}?workOrderId=${workOrderId}`, {
        edgesMetadata:edgesMetadata,
        anglesMetadata:{angles:anglesMetadata},
        dumpConfigurations:dumpConfigurations
      })
      .then(() => {
        resolve(workOrderId);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  saveWorkOrderLabelInfo(workOrderId, labelMapInfo:RMLabelInfo) {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_POST_SAVE_WORKORDER_LABEL_INFO}?workOrderId=${workOrderId}`, labelMapInfo)
      .then((data) => {
        resolve(data);
      })
      ,(error) => {
        reject(error);
      }
    });
  }

  getWorkOrderLabelInfo(workOrderId) {
    return new Promise<RMLabelInfo>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_WORKORDER_LABEL_INFO}?workOrderId=${workOrderId}`)
      .then((data) => {
        resolve(data);
      })
      ,(error) => {
        reject(error);
      }
    });
  }
  

  setBOMApproval(workOrder:RMWorkOrder, isBOMApproved:boolean): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = RM_REL_REJECT_BOM;
      if(isBOMApproved) {
        link = RM_REL_APPROVE_BOM;
      }
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${link}?workOrderId=${workOrder.workOrderId}`,{})
      .then((data: any) => {
        resolve(data);
      })
      ,(error) => {
        reject(error);
      };
    });
  }


  set3DModelApproval(workOrder:RMWorkOrder, is3DModelApproved:boolean): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = RM_REL_REJECT_3D_MODEL;
      if(is3DModelApproved) {
        link = RM_REL_APPROVE_3D_MODEL;
      }
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${link}?workOrderId=${workOrder.workOrderId}`,{})
      .then((data: any) => {
        resolve(data);
      })
      ,(error) => {
        reject(error);
      };
    });
  }

  setBOMApprovalWorkOrderGroup(workOrderGroup:RMWorkOrderGroup, isBOMApproved:boolean): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = RM_REL_REJECT_BOM;
      if(isBOMApproved) {
        link = RM_REL_APPROVE_BOM;
      }
      this.restApiService.makeSecuredPostCall(`${RM_REL_GET_WORKORDER_GROUP}/${link}?workOrderGroupId=${workOrderGroup.workOrderGroupId}`,{})
      .then((data: any) => {
        resolve(data);
      })
      ,(error) => {
        reject(error);
      };
    });
  }


  set3DModelApprovalWorkOrderGroup(workOrderGroup:RMWorkOrderGroup, is3DModelApproved:boolean): Promise<any> {
    return new Promise<any>((resolve,reject) => {
      let link = RM_REL_REJECT_3D_MODEL;
      if(is3DModelApproved) {
        link = RM_REL_APPROVE_3D_MODEL;
      }
      this.restApiService.makeSecuredPostCall(`${RM_REL_GET_WORKORDER_GROUP}/${link}?workOrderGroupId=${workOrderGroup.workOrderGroupId}`,{})
      .then((data: any) => {
        resolve(data);
      })
      ,(error) => {
        reject(error);
      };
    });
  }

  canWorkOrderPerformAction(actionName:RMACTION, rmWorkOrder:RMWorkOrder): boolean {
    if(!!rmWorkOrder.permittedActions) {
      return rmWorkOrder.permittedActions.includes(actionName);
    }
    return false;
  }

  canWorkOrderPerformActions(actions:RMACTION[], rmWorkOrder:RMWorkOrder) {
    let canAction = false;
    for(let action of actions) {
      canAction = this.canWorkOrderPerformAction(action, rmWorkOrder);
      if(canAction) {
        break;
      }
    }
    return canAction;
  }

  canWorkOrderGroupPerformAction(actionName:RMACTION, rmWorkOrderGroup:RMWorkOrderGroup): boolean {
    if(!!rmWorkOrderGroup.permittedActions) {
      return rmWorkOrderGroup.permittedActions.includes(actionName);
    }
    return false;
  }

  canWorkOrderGroupPerformActions(actions:RMACTION[], rmWorkOrderGroup:RMWorkOrderGroup) {
    let canAction = false;
    for(let action of actions) {
      canAction = this.canWorkOrderGroupPerformAction(action, rmWorkOrderGroup);
      if(canAction) {
        break;
      }
    }
    return canAction;
  }

  getSampleReportPath():Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeGetCall(`${RM_REL_GET_SAMPLE_REPORT_PATH}`)
      .then((pathMap) =>{
        resolve(pathMap);
      })
    })
  }


  submitContactUs(contactUsText):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_SUBMIT_CONTACT_US}`,{
        contactUsText
      } as any)
      .then((submitted) =>{
        resolve(submitted);
      })
    })
  }

  uploadAddressPool(file) {
    return new Promise<any>((resolve,reject) => {
      let formData = new FormData();
      formData.append('addressPoolFile',file)
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_UPLOAD_ADDRESS_POOL}`,formData)
      .then((submitted) =>{
        resolve(submitted);
      })
    })
  }

  getAddressPoolCount() {
    return new Promise<any>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ADDRESS_POOL_COUNT}`)
      .then((count) =>{
        resolve(count);
      })
    })
  }

  validateAndCalculateAddressAmount(address:String,latitude,longitude,userSettings:RMUserSettings) {
    return new Promise<RMCreateWorkOrderResponse>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_POST_VALIDATE_ADDRESS_AMOUNT}`,{
        "addressLine": address,
        "regionCode": "US",
        "userSettings":userSettings,
        latitude:latitude,
        longitude:longitude
      })
      .then(data => {
        console.log(`validateAddress ${address} data::`,data);
        if(data != null) {
          let location:RMLocation = {
            lat:data.geoLocation.latitude,
            lng:data.geoLocation.longitude,
            normalizedAddress:data.geoLocation.normalizedAddress
          }
          let response:RMCreateWorkOrderResponse = {
            lat:data.geoLocation.latitude,
            lng:data.geoLocation.longitude,
            normalizedAddress:data.geoLocation.normalizedAddress,
            discountPercentage:data.discountPercentage,
            userSettings:data.userSettings,
            isGroupAddress:data.geoLocation.groupAddress
          }
          resolve(response);
        }
        else {
          console.log(`validateAddress ${address} is invalid!!!`);
          reject("Address is invalid!!!");
        }
      })
    });
  }

  validateAndCalculateAddressAmountAdhoc(address:string,userSettings:RMUserSettings) {
    return new Promise<RMCreateWorkOrderResponse>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_POST_VALIDATE_ADDRESS_AMOUNT_ADHOC}`,{
        "addressLine": address,
        "regionCode": "US",
        "userSettings":userSettings
      })
      .then(data => {
        console.log(`validateAddress ${address} data::`,data);
        if(data != null) {
          let location:RMLocation = {
            lat:data.geoLocation.latitude,
            lng:data.geoLocation.longitude,
            normalizedAddress:data.geoLocation.normalizedAddress
          }
          let response:RMCreateWorkOrderResponse = {
            lat:data.geoLocation.latitude,
            lng:data.geoLocation.longitude,
            normalizedAddress:data.geoLocation.normalizedAddress,
            discountPercentage:data.discountPercentage,
            userSettings:data.userSettings,
            isGroupAddress:data.geoLocation.groupAddress
          }
          resolve(response);
        }
        else {
          console.log(`validateAddress ${address} is invalid!!!`);
          reject("Address is invalid!!!");
        }
      })
    });
  }

  getAllValidBOMRooftypes() {
    return new Promise<RMBOMRoofType[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_VALID_BOM_ROOF_TYPES}`)
      .then(data => {
        let bomRoofTypes:RMBOMRoofType[] = [];
        if(data != null) {
          for(let i=0;i<data.length;i++) {
            bomRoofTypes.push(data[i]);
          }
          resolve(bomRoofTypes);
        }
        else {
          reject("Error fetching BOM Roof Types!!!");
        }
      })
    });
  }

  getAllValidWorkOrderRooftypes() {
    return new Promise<RMWorkOrderRoofType[]>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_ALL_VALID_WORKORDER_ROOF_TYPES}`)
      .then(data => {
        let workOrderRoofTypes:RMWorkOrderRoofType[] = [];
        if(data != null) {
          for(let i=0;i<data.length;i++) {
            workOrderRoofTypes.push(data[i]);
          }
          resolve(workOrderRoofTypes);
        }
        else {
          reject("Error fetching WorkOrder Roof Types!!!");
        }
      })
    });
  }

  addWorkOrderAdditionalInfo(bomRoofTypeName:string,bomTakeDownRoof:boolean,workOrderId:string) {
    return new Promise<RMWorkorderAdditionalInfo>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_POST_ADD_WORKORDER_ADDITIONAL_INFO}`,{
        "bomRoofTypeName": bomRoofTypeName,
        "bomTakeDownRoof":bomTakeDownRoof,
        "workOrderId":workOrderId
      })
      .then(data => {
        if(data != null) {
          resolve(data);
        }
        else {
          reject("Error adding Workorder Additional Info!!!");
        }
      })
    });
  }

  getWorkOrderAdditionalInfo(workOrderId:number) {
    return new Promise<RMWorkorderAdditionalInfo>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_WORKORDER_ADDITIONAL_INFO}`)
      .then(data => {
        if(data != null) {
          resolve(data);
        }
        else {
          reject("Error fetching Workorder Additional Info!!!");
        }
      })
    });
  }

  addWorkOrderAngleInfo(angle:number,workOrderId:string) {
    return new Promise<RMWorkorderAngleInfo>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(`${RM_RESTAPI_HREF}/${RM_REL_POST_ADD_WORKORDER_ANGLE_INFO}`,{
        "angle": angle,
        "workOrderId":workOrderId
      })
      .then(data => {
        if(data != null) {
          resolve(data);
        }
        else {
          reject("Error adding Workorder Additional Info!!!");
        }
      })
    });
  }

  getWorkOrderAngleInfo(workOrderId:number) {
    return new Promise<RMWorkorderAngleInfo>((resolve,reject) => {
      this.restApiService.makeSecuredGetCall(`${RM_RESTAPI_HREF}/${RM_REL_GET_WORKORDER_ANGLE_INFO}`)
      .then(data => {
        if(data != null) {
          resolve(data);
        }
        else {
          reject("Error fetching Workorder Angle Info!!!");
        }
      })
    });
  }
}


function getNewPointSerialNumber(plate:RMPlate) {
  let maxNum = 0;
  if(plate.points == undefined || plate.points.length == 0) {
    return 1;
  }
  else {
    plate.points.forEach(point => {
      let label = point.label;
      let numPartStr = label.match(/(\d+)/)[0];
      if(numPartStr != undefined || numPartStr != null || Number.isNaN(Number.parseInt(numPartStr))) {
        let numPart = Number.parseInt(numPartStr);
        if(numPart > maxNum) {
          maxNum = numPart;
        }
      }
    });
    return (maxNum + 1);
  }
}

export function createNewPlate(latitude:number,longitude:number,provider:MAP_PROVIDER,plateAngle:PLATE_ANGLE,zoom:number):RMPlate {
  let plate:RMPlate = new RMPlate();
  plate.plateId = getPlateId();
  plate.latitude = latitude;
  plate.longitude = longitude;
  plate.provider = provider;
  plate.plateAngle = plateAngle;
  plate.label = getPlateAngleDetails(plateAngle).longCode;
  plate.points = [];
  plate.edges = [];
  let heading = -1;
  let tilt = false;
  if(plateAngle == PLATE_ANGLE.NORTH) {
    heading = 0;
    tilt = true;
  }
  if(plateAngle == PLATE_ANGLE.EAST) {
    heading = 90;
    tilt = true;
  }
  if(plateAngle == PLATE_ANGLE.WEST) {
    heading = 270;
    tilt = true;
  }
  if(plateAngle == PLATE_ANGLE.SOUTH) {
    heading = 180;
    tilt = true;
  }
  plate.heading = heading;
  plate.tilt = tilt;
  plate.zoom = zoom;
  return plate;
}

export function getDefaultStashForPlate(plate:RMPlate):RMStash {
  let defaultStash:RMStash = undefined;
  if(plate.stashes != undefined && plate.stashes.length > 0) {
    plate.stashes.forEach(stash => {
      if(stash.label == 'Default') {
        defaultStash = stash;
        return;
      }
    })
  }
  return defaultStash;
}

export function createNewPlatePoint(latitude:number,longitude:number,plate:RMPlate,x?:number,y?:number):RMPlatePoint {
  let platePoint:RMPlatePoint = new RMPlatePoint();
  platePoint.pointId = getPlatePointId();
  platePoint.label = getMapProviderDetails(plate.provider).shortCode + getPlateAngleDetails(plate.plateAngle).shortCode + getNewPointSerialNumber(plate);
  platePoint.plateId = plate.plateId;
  platePoint.latitude = latitude;
  platePoint.longitude = longitude;
  platePoint.x = (x == undefined) ? getNegativeInfinity() : x;
  platePoint.y = (y == undefined) ? getNegativeInfinity() : y;
  platePoint.isVisible= true;
  platePoint.highlight=false;
  platePoint.selected=false;
  platePoint.disabled=false;
  platePoint.isOpenInfoWindow=false;
  let mapSize = getMapSize();
  platePoint.sourceWidth = mapSize.mapWidth;
  platePoint.sourceHeight = mapSize.mapHeight;
  return platePoint;
}

export function createNewEdge(pointOne:RMPlatePoint,pointTwo:RMPlatePoint):RMEdge {
  let edge:RMEdge = new RMEdge();
  edge.edgeId = getEdgeId();
  edge.pointOne = pointOne;
  edge.pointTwo = pointTwo;
  edge.visible = true;
  edge.plateId = getPlateIdOfPoint(pointOne);
  edge.stashIds = [];
  return edge;
}

export function createNewCorrelation(pointOne:RMPlatePoint,pointTwo:RMPlatePoint):RMCorrelation {
  let correlation:RMCorrelation = new RMCorrelation();
  correlation.correlationId = getCorrelationId();
  correlation.pointOne = pointOne;
  correlation.pointTwo = pointTwo;
  correlation.correlationType = CORRELATION_TYPE.OPERATOR;
  correlation.pointOneId = pointOne.pointId;
  correlation.pointTwoId = pointTwo.pointId;
  return correlation;
}

export function createNewBoundary(plate:RMPlate,pointOne:RMPlatePoint,pointTwo:RMPlatePoint):RMBoundary {
  let boundary:RMBoundary = new RMBoundary();
  boundary.boundaryId = getBoundaryId();
  boundary.pointOne = pointOne;
  boundary.pointTwo = pointTwo;
  boundary.visible = true;
  boundary.plateId = getPlateIdOfPoint(pointOne);
  return boundary;
}

export function createNewObstruction(obstructionType:OBSTRUCTION_TYPE,plate:RMPlate,points:RMPlatePoint[],edges:RMEdge[]):RMObstruction {
  let obstruction:RMObstruction = new RMObstruction();
  obstruction.obstructionId = getObstructionId();
  obstruction.obstructionType=obstructionType;
  obstruction.points= points;
  if(edges == undefined) {
    edges = [];
  }
  obstruction.edges = edges;
  obstruction.visible = true;
  obstruction.plateId = plate.plateId;
  return obstruction;
}

export function createNewStash(plate:RMPlate,label:string):RMStash {
  let stash:RMStash = new RMStash();
  stash.stashId = getStashId();
  stash.label = label;
  stash.value = stash.stashId;
  stash.plateId = plate.plateId;
  stash.points = [];
  stash.edges = [];
  return stash;
}

export function createNewPurchase(workOrder:RMWorkOrder):RMPurchase {
  let purchase:RMPurchase = new RMPurchase();
  purchase.purchaseId = getPurchaseId();
  purchase.purchasedProducts = [];
  workOrder.selectedProducts.forEach(product => {
    purchase.purchasedProducts.push(product);
  })
  return purchase;
}

export function createNewPurchaseWorkOrderGroup(workOrderGroup:RMWorkOrderGroup):RMPurchase {
  let purchase:RMPurchase = new RMPurchase();
  purchase.purchaseId = getPurchaseId();
  purchase.purchasedProducts = [];
  workOrderGroup.selectedProducts.forEach(product => {
    purchase.purchasedProducts.push(product);
  })
  return purchase;
}

export function createBoundaryBounds(northEastPoint:RMPlatePoint,southWestPoint:RMPlatePoint):RMBounds {
    let boundaryObj:RMBounds = new RMBounds();
    boundaryObj.north = northEastPoint.latitude;
    boundaryObj.east = northEastPoint.longitude;
    boundaryObj.west = southWestPoint.longitude;
    boundaryObj.south = southWestPoint.latitude;
    return boundaryObj;
}

export function cleanWorkOrderGroup(workOrderGroup:RMWorkOrderGroup) {
  if(workOrderGroup.plate != null) {
    cleanPlate(workOrderGroup.plate);
  }
  delete workOrderGroup.creator;
  delete workOrderGroup.roofer;
  if(workOrderGroup.workOrders != undefined && workOrderGroup.workOrders.length > 0) {
    workOrderGroup.workOrders.forEach(workOrder => {
      cleanWorkOrder(workOrder);
    });
  }
}

export function cleanWorkOrder(writeWorkOrder:RMWorkOrder) {
  delete writeWorkOrder.operator;
  delete writeWorkOrder.roofer;
  delete writeWorkOrder.creator;
  delete writeWorkOrder.workOrderStatusLogs;
  delete writeWorkOrder.additionalInfo;
  delete writeWorkOrder.polygon;
  delete writeWorkOrder.workOrderGroup;

  // if(writeWorkOrder.workOrderGroup != undefined) {
  //   cleanWorkOrderGroup(writeWorkOrder.workOrderGroup);
  // }

  if(writeWorkOrder.plates != undefined && writeWorkOrder.plates.length > 0) {
    writeWorkOrder.plates.forEach(plate => {
      delete writeWorkOrder.plateSubscriber;
      cleanPlate(plate);
    });
  }
  if(writeWorkOrder.correlations != undefined && writeWorkOrder.correlations.length > 0) {
    writeWorkOrder.correlations.forEach(correlation => {
      delete correlation.plate1Id;
      delete correlation.plate2Id;
      delete correlation.pointOnePlateId;
      delete correlation.pointTwoPlateId;
      cleanPoint(correlation.pointOne);
      cleanPoint(correlation.pointTwo);
    })
  }
  if(writeWorkOrder.selectedProducts != undefined && writeWorkOrder.selectedProducts.length > 0) {
    delete writeWorkOrder.selectedProducts;
    writeWorkOrder.purchases.forEach(purchase => {
      if(purchase.payment == undefined) {
        purchase.payment = null;
      }
    });
  }
}

export function cleanPlate(plate:RMPlate) {
  delete plate.plateSubscriber;
  delete plate.uiOperationsStack;
  delete plate.map;
  delete plate.unscaledMap;
  delete plate.mapContainer;
  delete plate.isOverhangMode;
  delete plate.isObstructionMode;
  delete plate.selectedObstruction;
  delete plate.selectedOverhang;

  if(plate.points != undefined && plate.points.length > 0) {
    plate.points.forEach(point => {
      cleanPoint(point);
    })
  }
  if(plate.edges != undefined && plate.edges.length > 0) {
    plate.edges.forEach(edge => {
      cleanEdge(edge);
    })
  }
  if(plate.obstructions != undefined && plate.obstructions.length > 0) {
    plate.obstructions.forEach(obstruction => {
      cleanObstruction(plate,obstruction);
    })
  }
  if(plate.boundary != undefined) {
    cleanBoundary(plate.boundary);
  }
  if(plate.stashes != undefined && plate.stashes.length > 0) {
    plate.stashes.forEach(stash => {
      cleanStash(stash);
    })
  }
  delete plate.boundaryEdges;
}

export function cleanPoint(point:RMPlatePoint) {
  delete point.plate;
  delete point.marker;
  delete point.obstruction;
  delete point.crossHairVerticalEdge;
  delete point.crossHairHorizontalEdge;
}

export function cleanEdge(edge:RMEdge) {
  delete edge.plate;
  delete edge.polyline;
  delete edge.bingMapLayer;
  delete edge.obstruction;
  cleanPoint(edge.pointOne);
  cleanPoint(edge.pointTwo);
}

export function cleanStash(stash:RMStash) {
  delete stash.plate;
  if(stash.points != undefined) {
    stash.points.forEach(point => {
      cleanPoint(point);
    })
  }
  if(stash.edges != undefined) {
    stash.edges.forEach(edge => {
      cleanEdge(edge);
    })
  }
}

export function cleanBoundary(boundary:RMBoundary) {
  cleanPoint(boundary.pointOne);
  cleanPoint(boundary.pointTwo);
  delete boundary.pointOne.boundary;
  delete boundary.pointTwo.boundary;
  delete boundary.plate;
}

export function cleanObstruction(plate:RMPlate,obstruction:RMObstruction) {
  obstruction.points.forEach(point => {
    cleanPoint(point);
    delete point.obstruction;

    let index = plate.points.findIndex(tempPoint => {
      return tempPoint.pointId == point.pointId;
    });
    if(index >= 0) {
      plate.points.splice(index,1);
    }
  });
  obstruction.edges.forEach(edge => {
    cleanEdge(edge);
    delete edge.obstruction;
  });
  delete obstruction.plate;
}