// Library
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';

import { MapsAPILoader } from '@agm/core';

import { RMLocation } from '@roofmath/models/rm-location.model';
import { RMRestapiService } from '@roofmath/restapi/rm-restapi.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {
  private geocoder: any;
  private maxZoomService: any;

  private VALIDATE_ADDRESS_API_LINK:string = `restapi/validateUserAddress`;

  constructor(private mapLoader: MapsAPILoader, private restApiService:RMRestapiService) {
  }

  private initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
    this.maxZoomService = new google.maps.MaxZoomService();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load()).pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  // validateAddress(address: string): Promise<RMLocation> {
  //   let validGranularityLevels = ["PREMISE","SUB_PREMISE","BLOCK"];
  //   return new Promise<RMLocation>((resolve,reject) => {
  //     this.restApiService.makePostCall(this.VALIDATE_ADDRESS_API_LINK,{
  //       "address": {
  //         "addressLines": [address],
  //         "regionCode": "US"
  //       }
  //     })
  //     .then(data => {
  //       console.log(`validateAddress ${address} data::`,data);
  //       let result = data.result;
  //       if(validGranularityLevels.includes(result.verdict.validationGranularity)) {
  //         let geocode = result.geocode;
  //         let location = {
  //           normalizedAddress: result.address.formattedAddress,
  //           lat: geocode.location.latitude,
  //           lng: geocode.location.longitude
  //         }
  //         console.log(`validateAddress ${address} location::`,location);
  //         resolve(location);
  //       }
  //       else {
  //         console.log(`validateAddress ${address} is invalid!!!`);
  //         reject("Address is invalid!!!");
  //       }
  //     })
  //   });
  // }

  validateAddress(address: string): Promise<RMLocation> {
    return new Promise<RMLocation>((resolve,reject) => {
      this.restApiService.makeSecuredPostCall(this.VALIDATE_ADDRESS_API_LINK,{
        "addressLine": address,
        "regionCode": "US"
      })
      .then(data => {
        console.log(`validateAddress ${address} data::`,data);
        if(data != null) {
          let location:RMLocation = {
            lat:data.latitude,
            lng:data.longitude,
            normalizedAddress:data.normalizedAddress
          }
            resolve(location);
        }
        else {
          console.log(`validateAddress ${address} is invalid!!!`);
          reject("Address is invalid!!!");
        }
      })
    });
  }

  geocodeAddress(location: string): Observable<RMLocation> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable<RMLocation>(observer => {
          this.geocoder.geocode({ address: location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next({
                normalizedAddress: results[0].formatted_address,
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
            } else {
              console.log("geocodeAddress error::",status);
              observer.error({ normalizedAddress: 'Invalid address', lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }

  getGeoLocationOfAddress(address:string):Promise<RMLocation> {
    return new Promise<RMLocation>((resolve,reject) => {
      // this.geocodeAddress(address).toPromise()
      this.validateAddress(address)
      .then((location) => {
        resolve(location);
      })
      .catch(err=> {
        reject(err);
      })
    })
  }

  geocodeLatLng(coordinates): Observable<RMLocation> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable<RMLocation>(observer => {
          this.geocoder.geocode({ location: coordinates }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              observer.next({
                normalizedAddress: results[0].formatted_address,
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
            } else {
              observer.error({ normalizedAddress: 'Invalid address', lat: 0, lng: 0 });
            }
            observer.complete();
          });
        });
      })
    );
  }

  getMaxZoomLevel(coordinates):Promise<any> {
    return new Promise<any>((resolve,reject) => {
      this.mapLoader.load()
      .then(() => {
        this.initGeocoder();
        if(coordinates != undefined) {
          this.maxZoomService.getMaxZoomAtLatLng(
            coordinates,
            (result) => {
              if (result.status !== "OK") {
                reject("Not ok");
              } else {
                resolve(result.zoom);
              }
            }
          );
        }
      })
    })
  }
}
