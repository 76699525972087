import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewChecked, ElementRef } from '@angular/core';
import { GeocodeService } from '@roofmath/core/services/maps/geocode.service';
import { RMCONSTANTS } from '@roofmath/core/constants/rm-constants';
import { RMWorkOrder, RMPlate, RMProduct } from '@roofmath/models/workorders/rm-workorder.model';
import { createNewPlate, createNewPurchase, RMWorkOrdersService } from '@roofmath/restapi/rm-workorders.service';
import { Router } from '@angular/router';
import { getMapProviderDetails, getWorkOrderId, MAP_PROVIDER, PLATE_ANGLE, RMLatLng } from '@roofmath/models/rm-resources.model';
import { RMDataService } from '@roofmath/restapi/rm-data.service';
import { Table } from 'primeng/table';
import { RMAuthenticationService } from '@roofmath/restapi/rm-authentication.service';
import { RMACTION, RMUser, RMUserSettings } from '@roofmath/models/rm-auth.model';
import { RMUserManagementService } from '@roofmath/restapi/rm-user-management.service';
import { RMResponsiveService } from '@roofmath/shared/responsive-service';

@Component({
  selector: 'rm-adhoc-user-work-order',
  templateUrl: './rm-adhoc-user-work-order.component.html',
  styleUrls: ['./rm-adhoc-user-work-order.component.scss']
})
export class RMAdhocUserWorkOrderComponent implements OnInit {

  addressMaxZoomLevel = 20;
  newWorkOrders = [];
  validWorkOrders = [];
  erroredWorkOrders = [];
  selectedNewWorkOrders = [];
  initialEmptyWorkordersCount = 1;
  previewWorkOrder:RMWorkOrder = undefined;
  previewWorkOrderPlateGoogle:RMPlate = undefined;
  previewWorkOrderPlateBing:RMPlate = undefined;
  totalBeforeDiscount:number;
  discountPercentage:number;
  subTotal:number = 0;
  isSaving = false;
  isErrored = false;

  previewMapLoaded = false;
  imageHeight;
  imageWidth;
  imageHeightStr;
  imageWidthStr;
  currency = "$";
  workordersCount = 0;
  scrollHeight = undefined;

  availableProducts:RMProduct[] = [];

  signedInUser:RMUser;

  showModal = false;
  modalMessage = '';
  modalHeader = '';

  ACTIONS = RMACTION;

  uploadAddressPoolLabel = "Upload Address Pool";
  uploadingAddressPool = false;

  isMobileDevice:boolean = false;
  workOrderMobile:RMWorkOrder = new RMWorkOrder();

  userSettings:RMUserSettings;

  userEmailId:string = "";

  showAdditionalInfoDialog:boolean = false;
  additionalInfoWorkOrderId;
  bomRoofTypeName;
  bomTakeDownRoof;
  additionalInfoMap = {};
  
  @ViewChild('workOrdersTable') workOrdersTable:Table;
  @ViewChild('tableContainer') tableContainer;
  @ViewChild('fileUploadButton') fileUploadButton:ElementRef;

  constructor(
    private router:Router,
    private workOrdersService: RMWorkOrdersService,
    private geocodeService: GeocodeService,
    public CONSTANTS: RMCONSTANTS,
    private chageDetectorRef: ChangeDetectorRef,
    private responsiveService:RMResponsiveService,
    private dataService:RMDataService,
    private authService:RMAuthenticationService,
    private userManagementService:RMUserManagementService) { 
      this.imageHeight = this.dataService.getMapHeight();
      this.imageHeightStr = this.imageHeight + "px";
      this.imageWidth = this.dataService.getMapWidth();
      this.imageWidthStr = this.imageWidth + "px";
  }
  
  ngAfterViewChecked(): void {
    if(this.workOrdersTable != undefined && this.scrollHeight == undefined) {
      this.scrollHeight = this.dataService.getMapHeight();
      this.workOrdersTable.scrollHeight = this.scrollHeight - 225 + "px";
    }
  }

  populateAddressPoolCount() {
    this.workOrdersService.getAddressPoolCount()
    .then(count => {
      this.uploadAddressPoolLabel = `Upload Address Pool(${count})`;
    })
  }

  ngOnInit(): void {
    // this.userManagementService.getCurrentUserSettings()
    // .then(dataUserSettings => {
    //   this.userSettings = dataUserSettings;
    // })
    this.isMobileDevice = this.responsiveService.isSmallScreen();
    if(this.isMobileDevice) {
      this.initialEmptyWorkordersCount = 1;
    }
    // this.populateAddressPoolCount();
    // this.discountPercentage = this.authService.getSignedInUserDiscountPercentage();
    this.availableProducts = [];
    this.workOrdersService.getAllAdhocProducts()
    .then(products => {
      products.forEach(product => {
        if(product.isValid) {
          this.availableProducts.push(product);
        }
      })
      for(let i=0;i<this.initialEmptyWorkordersCount;i++) {
        this.addNewWorkOrderRow('');
      }
      this.calculateSubtotal();
    })
  }

  validateWorkOrder(workOrder:RMWorkOrder):Promise<RMLatLng> {
    return new Promise<RMLatLng>((resolve,reject) => {
      workOrder.isValidating = true;
      if(workOrder.latitude != undefined && workOrder.longitude != undefined) {
        resolve(new RMLatLng(workOrder.latitude,workOrder.longitude));
        return;
      }
      if(workOrder.isValidAddress == false) {
        reject("Invalid address");
        return;
      }
      if(workOrder.address != '' && workOrder.isValidAddress == undefined) {
        this.workOrdersService.validateAndCalculateAddressAmountAdhoc(workOrder.address,this.userSettings)
        .then(response => {
          workOrder.isValidating = false;
          workOrder.isValidAddress = true;
          workOrder.normalizedAddress = response.normalizedAddress;
          workOrder.latitude = response.lat;
          workOrder.longitude = response.lng;
          workOrder.discountPercentage = response.discountPercentage;
          this.userSettings = response.userSettings;
          resolve(new RMLatLng(workOrder.latitude,workOrder.longitude));
          return;
        })
        .catch(err => {
          workOrder.isValidating = false;
          workOrder.isValidAddress = false;
          console.error("validating error::",err);
          reject(err);
          return;
        })
      }
    })
  }

  calculateWorkOrderAmount(workOrder:RMWorkOrder) {
    let amount = 0;
    workOrder.hasReportSelected = true;
    if(workOrder.address != undefined && workOrder.address != '' && workOrder.isValidAddress) {
      workOrder.selectedProducts.forEach(product => {
        amount = amount + product.cost;
      })
      if(amount < 0) {
        amount = 0;
      }
    }
    workOrder.amount = amount;
    workOrder.subTotal = amount - (amount * workOrder.discountPercentage) / 100;
  }

  calculateSubtotal() {
    this.totalBeforeDiscount = 0;
    this.subTotal = 0;
    this.workordersCount = 0;
    this.newWorkOrders.forEach(workOrder => {
      if(workOrder.address != '' && workOrder.isValidAddress) {
          this.workordersCount++;
          this.calculateWorkOrderAmount(workOrder);
          this.subTotal = this.subTotal + workOrder.subTotal;
      }
    })
  }

  onProductSelectionChanged(workOrder:RMWorkOrder, product:RMProduct) {
    let index = workOrder.selectedProducts.findIndex(product => {
      return product.name == 'BOM';
    })
    if(index >= 0) {
      this.additionalInfoWorkOrderId = workOrder.workOrderId;
      this.showAdditionalInfoDialog = true;
      workOrder.additionalInfo = {
        "workOrderId" : workOrder.workOrderId,
        "bomRoofTypeName" : this.bomRoofTypeName,
        "bomTakeDownRoof": this.bomTakeDownRoof
      }
    }
    else if(product.name == 'BOM') {
      workOrder.additionalInfo = null;
      this.additionalInfoMap[workOrder.workOrderId] = null;
    }
    this.calculateSubtotal();
  }

  onAddressChanged(workOrder) {
    this.resetAddressValidation(workOrder);
  }

  resetAddressValidation(workOrder) {
    workOrder.isValidAddress = undefined;
    workOrder.latitude = undefined;
    workOrder.longitude = undefined;
    workOrder.discountPercentage = undefined;
  }

  onAddressChangeComplete(workOrder) {
    if(workOrder.address != '' && workOrder.isValidAddress == undefined) {
      this.validateWorkOrder(workOrder)
      .then(() => {
        this.calculateSubtotal();
      })
      .catch(() => {
        this.calculateSubtotal();
      })
    }
    else {
      this.calculateSubtotal();
    }
  }

  getAddressIsInvalid(workOrder) {
    return workOrder.address != '' && workOrder.isValidAddress != undefined && !workOrder.isValidAddress;
  }

  onPagination(event) {
    // console.log("onPagination::",event);
  }

  createPreviewPlate(workOrder:RMWorkOrder) {
    this.geocodeService.getMaxZoomLevel({lat: workOrder.latitude, lng: workOrder.longitude})
    .then((zoom) => {
      this.previewWorkOrderPlateGoogle = createNewPlate(workOrder.latitude,workOrder.longitude,getMapProviderDetails(MAP_PROVIDER.GOOGLE).provider,PLATE_ANGLE.ORTHOGONAL,zoom);
      this.previewWorkOrderPlateGoogle.zoom = zoom;
      this.previewWorkOrderPlateBing = createNewPlate(workOrder.latitude,workOrder.longitude,getMapProviderDetails(MAP_PROVIDER.BING).provider,PLATE_ANGLE.ORTHOGONAL,this.addressMaxZoomLevel);
      this.previewWorkOrderPlateBing.zoom = this.addressMaxZoomLevel;
    })
    this.chageDetectorRef.detectChanges();
  }

  showPreviewWorkOrder(workOrder) {
    this.previewMapLoaded = false;
    this.previewWorkOrder = workOrder;
    if(workOrder.isValidAddress != undefined && workOrder.isValidAddress) {
      this.createPreviewPlate(workOrder);
    }
    else {
      this.validateWorkOrder(workOrder)
      .then(() => {
        this.createPreviewPlate(workOrder);
      })
    }
  }

  onHidePreviewWorkOrder() {
    this.previewWorkOrder = undefined;
    this.previewWorkOrderPlateGoogle = undefined;
    this.previewWorkOrderPlateBing = undefined;
  }

  hydrateUserSettings(workOrder:RMWorkOrder) {
    if(workOrder.discountPercentage == 100) {
      this.userSettings.numFreeWorkOrders++;
    }
    else if(workOrder.discountPercentage > 0) {
      this.userSettings.numDiscountedWorkOrders++;
    }
  }

  removeSelectedWorkOrder(workOrder:RMWorkOrder) {
    let i = -1;
    for(let tempWorkOrder of this.newWorkOrders) {
      ++i;
      if(tempWorkOrder.workOrderId == workOrder.workOrderId && tempWorkOrder.address != '') {
        this.hydrateUserSettings(tempWorkOrder);
        break;
      }
    }
    if(i > -1 && i < this.newWorkOrders.length) {
      this.newWorkOrders.splice(i,1);
    }
  }

  removeSelectedWorkOrders() {
    this.selectedNewWorkOrders.forEach(workOrder => {
      this.removeSelectedWorkOrder(workOrder);
    })
  }
  
  saveAllWorkOrders() {
    this.isErrored = false;
    this.isSaving = true;
    let promises = [];
    let validatePromises = [];
    this.validWorkOrders = [];
    this.erroredWorkOrders = [];
    let allWorkOrders = [...this.newWorkOrders];
    allWorkOrders.filter(tempWorkOrder => {
      return tempWorkOrder.address !='';
    }).forEach(workOrder => {
      if(workOrder.address != '') {
        workOrder.creator = this.signedInUser;
        workOrder.roofer = this.signedInUser;
        let purchase = createNewPurchase(workOrder);
        workOrder.purchases.push(purchase);
        console.log("Validating::",workOrder.workOrderId);
        let validatePromise = this.validateWorkOrder(workOrder);
        validatePromises.push(validatePromise);
        validatePromise.then((location: RMLatLng) => {
          console.log("Validated::", workOrder.workOrderId);
          workOrder.isValidating = false;
          workOrder.isValidAddress = true;
          this.validWorkOrders.push(workOrder);
        })
        .catch(err => {
          console.log("Validating error::", workOrder.workOrderId);
          this.erroredWorkOrders.push(workOrder);
          workOrder.isValidating = false;
          workOrder.isValidAddress = false;
          workOrder.addressValidationError = err;
          this.isErrored = true;
        })
      }
    })
    console.log("validatePromises::",validatePromises.length);
    Promise.allSettled(validatePromises)
    .then(() => {
      console.log("Saving workorders now::",this.validWorkOrders.length);
      this.validWorkOrders.forEach(workOrder => {
        console.log("Saving::",workOrder.workOrderId);
        let savePromise = this.workOrdersService.createAdhocUserWorkOrder(workOrder,this.userEmailId);
        promises.push(savePromise);
        savePromise.then((workOrderId) => {
          console.log("Saved::",workOrder.workOrderId);
          let additionalInfo = this.additionalInfoMap[workOrder.workOrderId];
          if(additionalInfo != undefined && additionalInfo != null) {
            let additionalInfoPromise = this.workOrdersService.addWorkOrderAdditionalInfo(additionalInfo.bomRoofTypeName,additionalInfo.bomTakeDownRoof,workOrderId);
            promises.push(additionalInfoPromise);
            delete this.additionalInfoMap[workOrder.workOrderId];
          }
        })
        .catch(err => {
          this.erroredWorkOrders.push(workOrder);
        })
      })
      Promise.allSettled(promises)
      .then(() => {
        this.isSaving = false;
        this.implementPostSave(allWorkOrders);
      })
      .catch(err => {
        this.isSaving = false;
        this.implementPostSave(allWorkOrders);
      })
    })
  }

  implementPostSave(allWorkOrders) {
    console.log("Checking isErrored::",this.isErrored);
    if(this.erroredWorkOrders.length == 0) {
      this.showModal = true;
      this.modalMessage = "Saved";
      this.modalHeader = "Saved";
      // this.router.navigateByUrl('/');
    }
    if(this.erroredWorkOrders.length > 0) {
      this.validWorkOrders.forEach(workorder => {
        this.removeSelectedWorkOrder(workorder);
      })
      this.showModal = true;
      this.modalHeader = "Error";
      let totalCount = this.validWorkOrders.length + this.erroredWorkOrders.length;
      this.modalMessage = `Error saving ${this.erroredWorkOrders.length} out of ${totalCount}`;
    }
  }

  addNewWorkOrderRow(address:string) {
    if(this.newWorkOrders == undefined) {
      this.newWorkOrders = [];
    }
    let tempWorkOrder = new RMWorkOrder();

    tempWorkOrder.workOrderId = getWorkOrderId();
    tempWorkOrder.address = address;
    tempWorkOrder.purchases = [];
    tempWorkOrder.selectedProducts = [];
    tempWorkOrder.perReportAmount = 10;
    tempWorkOrder.perBomAmount = 10;
    tempWorkOrder.perTableOfSurfacesAmount = 10;
    tempWorkOrder.per3DModelAmount = 10;
    tempWorkOrder.isValidAddress = undefined;
    tempWorkOrder.amount = 0;
    tempWorkOrder.discountPercentage = 0;
    tempWorkOrder.subTotal = 0;
    this.availableProducts.forEach(product => {
      if(product.isMandatory) {
        tempWorkOrder.selectedProducts.push(product);
      }
    });

    this.calculateWorkOrderAmount(tempWorkOrder);
    if(tempWorkOrder.address != '') {
      this.validateWorkOrder(tempWorkOrder);
    }
    this.newWorkOrders.push(tempWorkOrder);
  }

  onPreviewMapLoadComplete() {
    this.previewMapLoaded = true;
  }

  modalAcknowledged() {
    this.showModal = false;
    this.modalMessage = '';
    this.modalHeader = '';
    if(this.erroredWorkOrders.length == 0) {
      this.router.navigateByUrl('/');
    }
  }

  onAdditionalInfoSelected(additionalInfoObj) {
    this.bomRoofTypeName = additionalInfoObj.bomRoofTypeName;
    this.bomTakeDownRoof = additionalInfoObj.bomTakeDownRoof;
    this.additionalInfoMap[this.additionalInfoWorkOrderId] = {
      "bomRoofTypeName" : this.bomRoofTypeName,
      "bomTakeDownRoof": this.bomTakeDownRoof
    }
    this.bomRoofTypeName = false;
    this.bomTakeDownRoof = false;
    this.showAdditionalInfoDialog = false;
  }
}
