

<div class="rm-splash">
  <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
    <div class="p-col p-d-flex p-ai-center p-jc-center adhocWorkOrderContainer">
      <div class="rm-adhoc-logo text-center" [ngClass]="{'rm-signin-logo-mobile':isMobileDevice}">
        <img src="assets/images/roofmath-logo.png" height="64" alt="Roof Math Logo" routerLinkActive="router-link-active" [routerLink]="['/']" >
      </div>
      <div *ngIf="newWorkOrders != undefined && newWorkOrders.length > 0">
        <div class="row newWorkOrderMobileContainer">
          <input type="textbox" [(ngModel)]="userEmailId" placeholder="Email Id" class="addressInput emailInput"/>
        </div>
        <div class="row newWorkOrderMobileContainer" *ngFor="let workOrder of newWorkOrders">
          <span class="p-input-icon-right addressSpanMobile">
            <i class="pi pi-exclamation-circle" *ngIf="getAddressIsInvalid(workOrder)" [ngClass]="{'rm-danger-symbol':getAddressIsInvalid(workOrder)}"  [pTooltip]="workOrder.addressValidationError"></i>
            <i class="pi pi-check-circle" *ngIf="workOrder.isValidAddress != undefined && !getAddressIsInvalid(workOrder)" [ngClass]="{'rm-valid-symbol':!getAddressIsInvalid(workOrder)}"></i>
            <i class="pi pi-spin pi-spinner" *ngIf="workOrder?.isValidating"></i>
            <input type="textbox" [(ngModel)]="workOrder.address" placeholder="Enter Address" class="addressInput" (change)="onAddressChanged(workOrder)"  (blur)="onAddressChangeComplete(workOrder)" [ngClass]="{'rm-danger-textbox':getAddressIsInvalid(workOrder),'rm-valid-textbox':workOrder.isValidAddress != undefined && !getAddressIsInvalid(workOrder)}"/>
          </span>
          <div class="row productsContainerMobile" *ngFor="let product of availableProducts">
            <div class="col-10">
              <p-checkbox name="products" (onChange)="onProductSelectionChanged(workOrder, product)" [disabled]="product.isMandatory" [value]="product" [label]="product.displayValue" [(ngModel)]="workOrder.selectedProducts"></p-checkbox>
            </div>
            <div class="col-2">
              <label>{{ product.cost }}</label>
            </div>
          </div>
        </div>
        <div class="row priceSummaryRow col-12">
          <div class="subTotalLabel col-10">Amount payable</div>
          <div class="subTotalAmount col-2">{{currency}} {{subTotal}}</div>
        </div>
        <button pButton type="button" class="ui-button-raised ui-button-rounded submitButton" label="Submit" (click)="saveAllWorkOrders()" *ngIf="!isSaving" [disabled]="!(workordersCount > 0 && userEmailId.length > 0)"></button>
        <button class="btn btn-primary submitButton" type="button" disabled *ngIf="isSaving">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Saving...
        </button>
      </div>
    </div>
  </div>
</div>

<p-dialog [draggable]="false" [modal]="true" [resizable]="false" [closeOnEscape]="false" header="Additional Info" [closable]="false" [(visible)]="showAdditionalInfoDialog" class="additionalInfoDialog">
  <rm-workorder-additional-info [workOrderId]="additionalInfoWorkOrderId" (additionalInfoSelected)="onAdditionalInfoSelected($event)"></rm-workorder-additional-info>
</p-dialog>
<rm-roofmath-modal *ngIf="showModal" [modalHeader]="modalHeader" [modalMessage]="modalMessage" (okButtonClick)="modalAcknowledged()"></rm-roofmath-modal>