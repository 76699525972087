import { RMStorageProxy } from '@roofmath/core/services/storage/rm-storage-proxy';

export class RMAbstractStorageService {
    private storage: RMStorageProxy;

    constructor(storage: Storage, prefix?: string) {
        this.storage = new RMStorageProxy(storage, prefix);
    }

    getItem(key: string) {
        return this.storage.get(key);
    }

    setItem(key: string, value: any) {
        this.storage.set(key, value);
    }

    removeItem(key: string) {
        this.storage.remove(key);
    }

    clear() {
        this.storage.clear();
    }
}
