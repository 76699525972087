import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'rm-sign-up-otp',
  templateUrl: './rm-sign-up-otp.component.html',
  styleUrls: ['./rm-sign-up-otp.component.scss']
})
export class RMSignUpOtpComponent implements OnInit {

  @Input() otpLength;
  @Input() countDownTime;
  @Input() retryAttempts;
  countDownTimeStr;
  attempts = 0;
  otpInterval;
  resendDisabled = true;

  @ViewChild('ngOtpInput') ngOtpInputRef:any;

  @Output() otpInputComplete:EventEmitter<any> = new EventEmitter<any>();
  @Output() resendOTPClicked:EventEmitter<any> = new EventEmitter<any>();
  @Output() retriesExceeded:EventEmitter<any> = new EventEmitter<any>();
  @Output() timeExpired:EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.attempts = 0;
    if(this.otpLength == undefined) {
      this.otpLength = 6;
    }
    if(this.countDownTime == undefined) {
      this.countDownTime = 180;
      this.setupCountDownTimer();
    }
    if(this.retryAttempts == undefined) {
      this.retryAttempts = 3;
    }
  }

  onOtpChange(otpInput) {
    if(otpInput.length == this.otpLength) {
      this.otpInputComplete.emit(otpInput);
      this.ngOtpInputRef.setValue("");
      this.attempts++;
      if(this.attempts == this.retryAttempts) {
        this.retriesExceeded.emit();
      }
    }
  }

  resendOTP() {
    this.attempts = 0;
    this.resendDisabled = true;
    this.resendOTPClicked.emit();
    this.setupCountDownTimer();
  }

  setupCountDownTimer() {
    let self = this;
    let currentTimer = this.countDownTime;
    this.otpInterval = setInterval(function() {
      currentTimer--;
      let minutes = Math.floor(currentTimer / 60);
      let seconds = Math.floor(currentTimer % 60);
      self.countDownTimeStr = minutes.toString().padStart(2,'0') + ":" + seconds.toString().padStart(2,'0');
      if (currentTimer == 0) {
        clearInterval(self.otpInterval);
        self.timeExpired.emit();
        self.resendDisabled = false;
      }
    }, 1000);
  }
}